import React, { useEffect, useRef, useState } from 'react'
import BaseScreen from '../../BaseScreen/BaseScreen'
import axios from 'axios'
import ArrowBackIosNewSharp from '@mui/icons-material/ArrowBackIosNewSharp'
import ArrowForwardIosSharp from '@mui/icons-material/ArrowForwardIosSharp'
import CallMadeIcon from '@mui/icons-material/CallMade'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import { Circles } from 'react-loader-spinner'
import CompanyNewsV2 from '../../../components/CompanyScreen/CompanyNewsV2'
import Description from './Description'
import Overview from './Overview'
import html2pdf from 'html2pdf.js'
import { getCookie } from '../../../utils'
import PreMeetingBriefModal from '../../NewsFeedScreen/components/PreMeetingBriefModal'
import { getYMD } from '../../../utils/dateHelpers'
import CustomLoader from '../../NewsFeedScreen/components/CustomLoader'
import ConversationStarters from './ConversationStarters'
import { Button } from '@mui/material'
import ConversationModal from './ConversationModal'
import QuickAdvanceFilters from '../../NewsFeedScreen/components/QuickAdvanceFilters'
import { IoIosClose } from 'react-icons/io'
import FilterListIcon from '@mui/icons-material/FilterList'
import NewPreMeetingBriefModal from '../../NewsFeedScreen/components/NewPreMeetingBriefModal'
import { NavLink } from 'react-router-dom'
import DetailedReportPreview from '../../NewsFeedScreen/components/DetailedReportPreview'
import { toast } from 'react-toastify'
import './MyCompanyScreen.css'
import { Skeleton } from '@mui/material'
import { SkeletonComponent } from '../../../components/Skeleton/index'
export default function MyCompanyScreen(props) {
    const filterInputRef = useRef(null)
    const screenType = props.location.pathname === '/app/companies/' ? 'companies' : 'competitors'
    const [allCompanies, setAllCompanies] = useState([])
    const [selectedCompany, setSelectedCompany] = useState()
    const [companyInformation, setCompanyInformation] = useState()
    const [companyNews, setCompanyNews] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [premeetingPreview, setPremeetingPreview] = useState(false)
    const [premeetingData, setPremeetingData] = useState('')
    const [preMeetingLoader, setPreMeetingLoader] = useState(false)
    const [companyName, setCompanyName] = useState({ id: null, name: null })
    const [payloadToken, setPayloadToken] = useState(null)
    const [progressContent, setProgressContent] = useState(null)
    const [detailedProgressContent, setDetailedProgressContent] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [strategicModal, setStrategicModal] = useState(false)
    const [newsFilters, setNewsFilters] = useState(false)
    const [selectedSectors, setSelectedSectors] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedTopics, setSelectedTopics] = useState([])
    const [selectedRegions, setSelectedRegions] = useState([])
    const [selectedSources, setSelectedSources] = useState([])
    const [allHierarchy, setAllHierarchy] = useState([])
    const [regionHierarchy, setRegionHierarchy] = useState([])
    const [dateSection, setDateSection] = useState(false)
    const [defaultData, setDefaultData] = useState([])
    const [selectedSubCategories, setSelectedSubCategories] = useState([])
    const [combinedRegCouPro, setCombinedRegCouPro] = useState([])
    const [allSources, setAllSources] = useState([])
    const [allSubCategories, setAllSubCategories] = useState([])
    const [allCompaniess, setAllCompaniess] = useState([])
    const [selectedOption, setSelectedOption] = useState('29')
    const [filtersCleared, setFiltersCleared] = useState(false)
    const [reportView, setReportView] = useState(null)
    // Cache object to store generated reports by company ID
    const [companyReports, setCompanyReports] = useState([])
    const [reportStatus, setReportStatus] = useState({})

    const token = getCookie('csrftoken')

    useEffect(() => {
        const headerTitle = document.getElementById('header_title')
        headerTitle.textContent = 'My Companies'
    }, [screenType])

    useEffect(() => {
        getRegions()
        getData('/news/all-companies/', setAllCompaniess)
        getData('/news/industries-sectors-topics/', setAllHierarchy)
        getData('/news/sources/', setAllSources)
        getData('/news/all-subcategories/', setAllSubCategories)
    }, [])

    const getData = (api, setState) => {
        axios.get(api).then(res => setState(res.data))
    }

    useEffect(() => {
        axios
            .get(`/endusers/user-${screenType}/`)
            .then(res => {
                setAllCompanies(res.data)
                if (res.data.length > 0) {
                    // setSelectedCompany(res.data[0].id)
                    setCompanyName({ id: res.data[0].id, name: res.data[0].name })
                }
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        if (companyName.id) {
            setIsLoading(true)
            getCompanyInfoNews(companyName.id)
            // setPreMeetingLoader(false)
            setReportView(null)
        }
    }, [companyName])

    useEffect(() => {
        if (filtersCleared) {
            getNews()
            setFiltersCleared(false)
        }
    }, [
        filtersCleared,
        selectedSources,
        selectedSubCategories,
        selectedCompanies,
        selectedTopics,
        selectedSectors,
        selectedRegions,
        selectedOption,
    ])

    const getRegions = () => {
        axios.get('/news/region-countries-provinces/').then(response => {
            const regions = response.data.map(item => ({
                ...item,
                id: item.type + '_' + item.id,
                countries: item.countries
                    ? item.countries.map(cty => ({
                          ...cty,
                          id: cty.type + '_' + cty.id,
                          provinces: cty.provinces
                              ? cty.provinces.map(pro => ({
                                    ...pro,
                                    id: pro.type + '_' + pro.id,
                                }))
                              : [],
                      }))
                    : [],
            }))
            const data = regions

            const customSort = (a, b) => {
                if (a.name === 'North America') {
                    return -1
                } else if (b.name === 'North America') {
                    return 1
                }
                return a.name.localeCompare(b.name)
            }

            const sortedData = data.sort((a, b) => customSort(a, b))

            sortedData.forEach(region => {
                region.countries.sort((a, b) => a.name.localeCompare(b.name))
            })

            setRegionHierarchy(sortedData)

            const result = sortedData.flatMap(regions => [
                { value: regions.id, label: regions.name, type: 'region' },
                ...regions.countries.flatMap(country => [
                    { value: country.id, label: country.name, type: 'country' },
                    ...country.provinces.map(province => ({
                        value: province.id,
                        label: province.name,
                        type: 'province',
                    })),
                ]),
            ])

            setCombinedRegCouPro(result)
        })
    }

    const getIndsecTop = () => {
        axios
            .get('/news/industries-sectors-topics/')
            .then(res => setAllHierarchy(res.data))
            .catch(err => console.log(err))
    }

    const QuickprogressSteps = ['Generating search strategy', 'Validating news results', 'Almost done']
    const progressSteps = [
        'Searching Kaitongo news database',
        'Validating news results',
        'Searching for financial data',
        'Summarising news and financial data',
        'Writing strategic priorities and risks',
        'Writing executive summary',
        'Composing the report',
        'Final touches',
    ]

    let timeoutIds = [] // Store timeout IDs to manage clearing

    const clearTimeouts = () => {
        timeoutIds.forEach(id => clearTimeout(id))
        timeoutIds = []
    }

    const progressContents = (responseTime = 30000, completionTime = 30000) => {
        const stepsArray = QuickprogressSteps

        clearTimeouts()

        stepsArray.forEach((step, index) => {
            const timeoutId = setTimeout(() => {
                const percentage = Math.round(((index + 1) / stepsArray.length) * 100)
                console.log(percentage)
                const displayPercentage = percentage > 94 ? 94 : percentage
                const steps = `${step}...(${displayPercentage}%)`
                setProgressContent(steps)
            }, index * (responseTime / stepsArray.length))
            timeoutIds.push(timeoutId) // Save the timeout ID
        })
    }

    let timeoutIdss = [] // Store timeout IDs to manage clearing

    const clearTimeoutss = () => {
        timeoutIds.forEach(id => clearTimeouts(id))
        timeoutIds = []
    }

    const progressContentss = (responseTime = 30000, completionTime = 30000) => {
        const stepsArray = progressSteps

        clearTimeoutss()

        stepsArray.forEach((step, index) => {
            const timeoutId = setTimeout(() => {
                const percentage = Math.round(((index + 1) / stepsArray.length) * 100)
                // console.log(percentage)
                const displayPercentage = percentage > 94 ? 94 : percentage
                const steps = `${step}...(${displayPercentage}%)`
                setDetailedProgressContent(steps)
            }, index * (responseTime / stepsArray.length))
            timeoutIdss.push(timeoutId) // Save the timeout ID
        })
    }

    const getCompanyInfoNews = id => {
        setCompanyInformation()
        // const completionTime = 15000
        // const responseTime = 15000
        setProgressContent('Working on it...(5%)')
        // const initialTimeoutId = setTimeout(() => {
        //     progressContents(responseTime, completionTime)
        //     setIsLoading(true)
        // }, 100)
        // timeoutIds.push(initialTimeoutId)
        if (payloadToken) {
            payloadToken.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setPayloadToken(newCancelSource)
        axios
            .get(`/news/my-companies/?id=${id}`, { cancelToken: newCancelSource.token })
            .then(res => {
                setCompanyInformation(res.data.company_info)

                // setCompanyNews(res.data)

                getNews()
            })
            .catch(err => {
                // setIsLoading(false)
                if (axios.isCancel(err)) {
                    console.log('POST Request canceled:', err.message)
                    setProgressContent(null)
                    clearTimeouts()
                    setIsLoading(true)
                } else {
                    setProgressContent(null)
                    clearTimeouts()
                    setIsLoading(false)
                }
            })
        // .finally(() => {
        //     setIsLoading(false)
        // })
    }

    const getNews = date => {
        const completionTime = 30000
        const responseTime = 30000
        setProgressContent('Working on it...')
        // const initialTimeoutId = setTimeout(() => {
        //     progressContents(responseTime, completionTime)
        //     setIsLoading(true)
        // }, 100)
        // timeoutIds.push(initialTimeoutId)
        if (payloadToken) {
            payloadToken.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setPayloadToken(newCancelSource)

        const selectedDate = Number(date ? date : selectedOption)
        const cutoff =
            selectedDate <= 7 ? 3 : selectedDate === 29 ? 13 : selectedDate === 59 ? 23 : selectedDate === 89 ? 33 : 43
        var startDate = new Date()
        var endDate = new Date()
        startDate.setDate(startDate.getDate() - selectedDate)
        startDate = getYMD(startDate)
        endDate = getYMD(endDate)
        const allRegionName = regionHierarchy.map(val => val.name)

        const allCountryName = regionHierarchy
            .map(val => val.countries.map(cty => cty.name))
            .reduce((acc, curr) => acc.concat(curr), [])

        const allProvinceName = regionHierarchy
            .map(val => val.countries.map(cty => cty.provinces.map(pro => pro.name)))
            .reduce((acc, curr) => acc.concat(curr), [])
            .reduce((acc, curr) => acc.concat(curr), [])

        const payload = {
            // query: companyName.name,
            relevance_cutoff: cutoff,
            prompt_type: 'Company Quick Search News Validation',
            semantic_preference: 0.5,
            type: 'level0',
            my_companies_page: true,
            //opensearch
            search_type: 'advanced',
            next: 0, // For Next Batch
            size: 15, // Batch size

            filters: {
                articleDate: {
                    operator: 'BETWEEN',
                    from: startDate,
                    to: endDate,
                },
                company: { operator: 'IS', values: [companyName.name] },
                source: {
                    operator: 'IS',
                    values: selectedSources,
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                category: {
                    operator: 'IS',
                    values: selectedSubCategories,
                },
            },
        }

        payload.entities = {
            regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
            provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
            countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
            companies: [companyName.name],
            subcategories: selectedSubCategories,
            source_name: selectedSources,
        }

        console.log('payload', payload)

        const getConversations = data => {
            const news_articles =
                data && data.data ? data.data.slice(0, 15).map(v => ({ title: v.title, id: v.id })) : []
            console.log(news_articles)

            const strategic = data && data.strategic_content

            const strategicPayload = {
                news_articles,
                ...(strategic && { strategic_content: strategic }),
                ...(companyName && companyName.name && { company_name: companyName.name }),
            }

            console.log(strategicPayload)

            axios
                .post('/semantic/conversation_starters/', strategicPayload, { cancelToken: newCancelSource.token })
                .then(res => {
                    console.log(res)
                    const responsedata = res.data

                    if (responsedata) {
                        setCompanyNews(prevNews => {
                            // Convert previous data to a map for easier comparison
                            const prevNewsState = ((prevNews.data && prevNews.data) || []).reduce((acc, item) => {
                                acc[item.id] = item
                                return acc
                            }, {})

                            const strategyResponse = responsedata.strategic_content
                                ? responsedata.strategic_content
                                : null
                            const convoResponse = responsedata.conversation_starters
                                ? responsedata.conversation_starters
                                : null

                            // Prepare additional content for strategic priorities and conversation starters

                            // Create a new state by adding only unique news and appending additional content
                            const updatedNewsState = Object.values(data.data).reduce((acc, item) => {
                                const updatedItem = {
                                    ...item,
                                }

                                // Avoid duplicates by checking if the item already exists
                                if (!prevNewsState[item.id]) {
                                    acc[item.id] = updatedItem
                                }
                                return acc
                            }, {})

                            // Return the updated state with only unique items
                            return {
                                ...prevNews,
                                data: [...Object.values(prevNewsState), ...Object.values(updatedNewsState)],
                                conversation_starters: convoResponse,
                                strategic_priorities: strategyResponse,
                            }
                        })
                    }
                })
                .catch(err => console.log(err))
        }

        const getduplicateUpdates = data => {
            const news_ids = data && data.data ? data.data.map(v => v.id) : []
            console.log(news_ids)
            const duplicatepayload = {
                news_ids,
            }
            console.log(duplicatepayload)
            axios
                .post('/semantic/duplicate_news/', duplicatepayload)
                .then(res => {
                    console.log(res)
                    const duplicateNews = res.data
                    // const updatedNewsState = Object.values(data.data).reduce((acc, item) => {
                    //     const duplicates = duplicateNews[item.id] || []
                    //     acc[item.id] = {
                    //         ...item,
                    //         duplicate_news: duplicates,
                    //     }
                    //     return acc
                    // }, {})

                    // // Update the news state

                    // // Wrap the updated state into the `data` key and set it to `companyNews`
                    // setCompanyNews({
                    //     data: Object.values(updatedNewsState),
                    // })
                    setCompanyNews(prevNews => {
                        // Convert previous data to a map for easier comparison
                        const prevNewsState = ((prevNews.data && prevNews.data) || []).reduce((acc, item) => {
                            acc[item.id] = item
                            return acc
                        }, {})

                        // Create a new state by merging the updates
                        const updatedNewsState = Object.values(data.data).reduce((acc, item) => {
                            const duplicates = duplicateNews[item.id] || []
                            const updatedItem = {
                                ...item,
                                duplicate_news: duplicates,
                                size: data.size,
                                next: data.next,
                                total: data.total,
                                scroll_id: data.Scrollid,
                            }

                            // Compare and update only if there are changes
                            if (JSON.stringify(prevNewsState[item.id]) !== JSON.stringify(updatedItem)) {
                                acc[item.id] = updatedItem
                            } else {
                                acc[item.id] = prevNewsState[item.id] // Retain the old item if unchanged
                            }
                            return acc
                        }, {})

                        // Return the updated state
                        return {
                            ...prevNews,
                            data: Object.values(updatedNewsState),
                        }
                    })

                    setIsLoading(false)
                })
                .catch(err => console.log(err))
        }

        axios
            .post('/news/level2/search/', payload, { cancelToken: newCancelSource.token })
            .then(res => {
                setCompanyNews(res.data)
                setProgressContent(null)
                clearTimeouts()
                setIsLoading(false)
                getConversations(res.data)
                // getduplicateUpdates(res.data)
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('POST Request canceled:', err.message)
                    setProgressContent(null)
                    clearTimeouts()
                    setIsLoading(true)
                } else {
                    setProgressContent(null)
                    clearTimeouts()
                    setIsLoading(false)
                }
            })
    }

    const companyMap = allCompanies.map(({ id, name }) => {
        return (
            <div
                key={id}
                onClick={() => setCompanyName({ id: id, name: name })}
                style={{
                    color: companyName.id === id ? 'rgb(25, 118, 210)' : 'black',
                    fontWeight: companyName.id === id ? 'bold' : 'normal',
                    cursor: 'pointer',
                }}
                className="companies_sub_tab"
            >
                {name}
            </div>
        )
    })

    const handleLoadMore = () => {
        console.log('fkadfnkadf', companyNews)

        if (companyNews.next && companyNews.data.length !== companyNews.total) {
            let data = {
                next: companyNews.next,
                size: companyNews.size,
                total: companyNews.total,
                scroll_id: companyNews.scroll_id,
            }

            axios
                .post('/news/scroll/', data)
                .then(response => {
                    let newsBefore = [...companyNews.data, ...response.data.data]

                    // setCompanyNews(prevResponse => {
                    //     return {
                    //         ...prevResponse,
                    // data: newsBefore,
                    // total: response.data.total,
                    // next: response.data.next,
                    // scroll_id: response.data.scroll_id,
                    // size: response.data.size,
                    //     }
                    // })
                    setCompanyNews(prevNews => {
                        // Convert previous data to a map for easier comparison
                        const prevNewsState = ((prevNews.data && prevNews.data) || []).reduce((acc, item) => {
                            acc[item.id] = item
                            return acc
                        }, {})

                        // Create a new state by merging the updates
                        const updatedNewsState = Object.values(newsBefore).reduce((acc, item) => {
                            const updatedItem = {
                                ...item,
                                total: response.data.total,
                                next: response.data.next,
                                scroll_id: response.data.scroll_id,
                                size: response.data.size,
                            }

                            // Compare and update only if there are changes
                            if (JSON.stringify(prevNewsState[item.id]) !== JSON.stringify(updatedItem)) {
                                acc[item.id] = updatedItem
                            } else {
                                acc[item.id] = prevNewsState[item.id] // Retain the old item if unchanged
                            }
                            return acc
                        }, {})

                        console.log('new', updatedNewsState)

                        // Return the updated state
                        return {
                            ...prevNews,
                            data: Object.values(updatedNewsState),
                        }
                    })
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }

        // const scrollUrl =
        //     companyNews.next !== null &&
        //     companyNews.next.replace('http://localhost:8000', '') &&
        //     companyNews.next.replace('http://kaitongo-backend:8000', '')
        // const defaultUnique = [...new Set(companyNews.data)]
        // companyNews.next !== null &&
        //     axios.get(scrollUrl).then(response => {
        //         let newsBefore = [...defaultUnique, ...response.data.data]
        //         let uniqueNews = [...new Set(newsBefore)]
        //         setCompanyNews({
        //             next: response.data.next,
        //             data: uniqueNews,
        //         })
        //     })
    }

    let interval

    // const getPreMeetingData = () => {
    //     setPremeetingPreview(true)

    //     setTimeout(() => {
    //         setPreMeetingLoader(true)
    //     }, 100)
    //     setTimeout(() => {
    //         const search = document.getElementById('pregenerating')
    //         if (search) {
    //             const searchPlaceholder = 'Generating...'
    //             let i = 0
    //             let placeholder = ''
    //             typing()
    //             function typing() {
    //                 clearInterval(interval)
    //                 interval = setInterval(() => {
    //                     if (search) {
    //                         placeholder += searchPlaceholder[i]
    //                         i++
    //                         search.value = placeholder
    //                         search.scrollTop = search.scrollHeight
    //                         if (i > searchPlaceholder.length - 1) {
    //                             clearInterval(interval)
    //                         }
    //                     }
    //                 }, 50)
    //             }
    //         }
    //     }, 500)

    //     const pay = { company_id: companyName.id }

    //     axios
    //         .post(`/news/premium/`, pay)
    //         .then(res => {
    //             setPremeetingData(res.data)
    //         })
    //         .catch(err => console.log(err))
    //         .finally(() => {
    //             setPreMeetingLoader(false)
    //         })
    // }

    const getPreMeetingData = async companyId => {
        console.log(companyId)

        // Check if the report is already generated (status is 'completed')
        if (reportStatus[companyId] && reportStatus[companyId].status === 'completed') {
            // Load the already generated report data into the modal without re-fetching
            setReportView(reportStatus[companyId].data)
            setPremeetingPreview(true)
            return
        }

        // Set status to 'loading' before starting the fetch
        setReportStatus(prevStatus => ({
            ...prevStatus,
            [companyId]: { status: 'loading', data: null, id: companyId },
        }))

        const completionTime = 210000
        const responseTime = 210000
        setDetailedProgressContent('Working on it...(5%)')
        const initialTimeoutId = setTimeout(() => {
            progressContentss(responseTime, completionTime)
            setPreMeetingLoader(true)
        }, 100)
        timeoutIdss.push(initialTimeoutId)
        // if (payloadToken) {
        //     payloadToken.cancel('Operation canceled due to new request.')
        // }
        // const newCancelSource = axios.CancelToken.source()
        // setPayloadToken(newCancelSource)

        var startDate = new Date()
        const detailDateFill = 180
        startDate.setDate(startDate.getDate() - detailDateFill)
        const payload = {
            filters: {
                articleDate: {
                    operator: 'BETWEEN',
                    from: getYMD(startDate),
                    to: getYMD(new Date()),
                },
                company: {
                    operator: 'IS',
                    values: [companyName.name],
                },
            },
            relevance_cutoff: 13,
            semantic_preference: 0.5,
            download_report: true,
            report_type: 'Detailed Report',
            non_entity_query: companyName.name,
            type: 'company',
            detailed_report_show: true,
        }

        axios
            .post('/semantic/quick-search/', payload)
            .then(res => {
                const data = res.data
                if (data && typeof data === 'object') {
                    setReportStatus(prevStatus => ({
                        ...prevStatus,
                        [companyId]: { status: 'completed', data: data, id: companyId },
                    }))

                    // Open modal with the new report data
                    if (companyName.id === companyId) {
                        setReportView(data)
                        setPremeetingPreview(true)
                    }

                    console.log('company', companyReports)
                } else {
                    setReportView(null)
                    toast(data)
                    setPreMeetingLoader(false)
                    setReportStatus(prevStatus => ({
                        ...prevStatus,
                        [companyId]: { status: 'error', data: null, id: companyId },
                    }))
                }
                setDetailedProgressContent(null)
                clearTimeoutss()
                setPreMeetingLoader(false)
            })
            .catch(err => {
                setProgressContent(null)
                clearTimeouts()
                setPreMeetingLoader(false)
            })
        // try {
        //     const reportData = await getReportData(companyId)

        //     // Update the state with the fetched report data and set status to 'completed'
        // setReportStatus(prevStatus => ({
        //     ...prevStatus,
        //     [companyId]: { status: 'completed', data: reportData },
        // }))

        // // Open modal with the new report data
        // setReportView(reportData)
        // setPremeetingPreview(true)
        // } catch (error) {
        //     console.error('Error generating report:', error)
        // }
    }

    const windowWidth = window.innerWidth
    const ConversationCheck =
        companyNews &&
        companyNews.data &&
        companyNews.data.length > 0 &&
        companyNews.conversation_starters &&
        companyNews.conversation_starters['Conversation Starters'] &&
        companyNews.conversation_starters['Conversation Starters'].length > 0
    const StrategicCheck =
        companyNews &&
        companyNews.data &&
        companyNews.data.length > 0 &&
        companyNews.strategic_priorities &&
        companyNews.strategic_priorities['Strategic Priorities'] &&
        companyNews.strategic_priorities['Strategic Priorities'].length > 0
    const companyNewsCheck = companyNews && companyNews.data && companyNews.data.length > 0
    const clearFilters = () => {
        setSelectedSources([])
        setSelectedSubCategories([])
        setSelectedCompanies([])
        setSelectedTopics([])
        setSelectedSectors([])
        setSelectedRegions([])
        setSelectedOption('29')
        setFiltersCleared(true)

        // getNews()
    }

    return (
        <BaseScreen>
            <div style={{ padding: '0px 15px' }}>
                {newsFilters && (
                    <div ref={filterInputRef}>
                        <div
                            className="news_filter_section"
                            style={{
                                height: '100vh',
                                transition: 'opacity 3s ease',
                                overflow: 'auto',
                                position: 'fixed',
                                width: '240px',
                                top: 0,
                                left: 0,
                                zIndex: 10,
                                background: '#051340',
                                color: 'white',
                            }}
                        >
                            <div style={{}}>
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ width: '80%', margin: '21.5px auto' }}
                                >
                                    <label style={{ fontSize: '16px', color: 'white' }}>Filters</label>
                                    <span style={{ cursor: 'pointer' }} onClick={() => setNewsFilters(false)}>
                                        <IoIosClose style={{ fontSize: '26px' }} />
                                    </span>
                                </div>
                                <hr style={{ background: 'lightgray' }} />
                                <div style={{ margin: 'auto' }}>
                                    <QuickAdvanceFilters
                                        selectedSectors={selectedSectors}
                                        setSelectedSectors={setSelectedSectors}
                                        selectedCompanies={selectedCompanies}
                                        setSelectedCompanies={setSelectedCompanies}
                                        selectedCategories={selectedCategories}
                                        setSelectedCategories={setSelectedCategories}
                                        selectedTopics={selectedTopics}
                                        setSelectedTopics={setSelectedTopics}
                                        selectedRegions={selectedRegions}
                                        setSelectedRegions={setSelectedRegions}
                                        selectedSources={selectedSources}
                                        setSelectedSources={setSelectedSources}
                                        allHierarchy={allHierarchy}
                                        regionHierarchy={regionHierarchy}
                                        practiceSubCategory={[]}
                                        generateQuery={getNews}
                                        defaultData={defaultData}
                                        setDefaultData={setDefaultData}
                                        dateSection={dateSection}
                                        setDateSection={setDateSection}
                                        clearFilters={clearFilters}
                                        selectedSubCategories={selectedSubCategories}
                                        setSelectedSubCategories={setSelectedSubCategories}
                                        basicSearchInput={''}
                                        allCompanies={allCompaniess}
                                        allSources={allSources}
                                        allSubCategories={allSubCategories}
                                        selectedOption={selectedOption}
                                        setSelectedOption={setSelectedOption}
                                        isMyCompanyPage={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {!isLoading && allCompanies.length === 0 && (
                    <h1 style={{ textAlign: 'center', marginTop: '20px' }}>
                        You have not chosen companies to view. For Insights on Companies, click on{' '}
                        <NavLink to="/app/preferences/">My Preferences</NavLink> and choose {screenType}.
                    </h1>
                )}
                {allCompanies.length > 0 && (
                    <>
                        <div
                            className="indSelect__container"
                            style={{ height: '50px', borderTop: '1px solid lightgray' }}
                        >
                            <div className="indSelect__content" style={{ width: '100%' }}>
                                {companyMap.length > (windowWidth <= 768 ? 3 : 5) ? (
                                    <ScrollMenu
                                        data={companyMap}
                                        arrowLeft={<ArrowBackIosNewSharp />}
                                        arrowRight={<ArrowForwardIosSharp />}
                                        dragging={true}
                                        wheel={false}
                                        hideSingleArrow={true}
                                        translate={5}
                                    />
                                ) : (
                                    <div className="d-flex" style={{ color: 'rgb(25, 118, 210)' }}>
                                        {companyMap}
                                    </div>
                                )}
                            </div>
                        </div>
                        {(!isLoading || (companyInformation && companyName.id && companyInformation.name)) && (
                            <div className={!isLoading && companyNewsCheck ? 'd-md-flex d-sm-block' : ''}>
                                <div
                                    className={
                                        !isLoading && companyNewsCheck
                                            ? 'responsive_company_description_60'
                                            : 'responsive_company_description_100'
                                    }
                                >
                                    <div
                                        style={{
                                            marginTop: '5%',
                                            width: '100%',
                                        }}
                                    >
                                        {companyName.id && companyInformation && (
                                            <div>
                                                <Description
                                                    Logo={companyInformation.logo}
                                                    Name={companyInformation.name}
                                                    Overview={companyInformation.description}
                                                    companyId={companyInformation.id}
                                                    companyUrl={companyInformation.url}
                                                    windowWidth={windowWidth}
                                                    getPreMeetingData={getPreMeetingData}
                                                    screenType={screenType}
                                                    Industry={companyInformation.sector_name}
                                                    Revenue={companyInformation.revenue}
                                                    Share={companyInformation.company_type}
                                                    Headquaters={companyInformation.headquater}
                                                    preMeetingLoader={preMeetingLoader}
                                                    detailedProgressContent={detailedProgressContent}
                                                    reportStatus={reportStatus}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {isLoading && (
                                        <div
                                            style={{ marginTop: allCompanies.length === 0 ? '' : '5%' }}
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            <CustomLoader progressContent={progressContent} />
                                        </div>
                                    )}

                                    {!isLoading && companyNewsCheck && !(ConversationCheck || StrategicCheck) && (
                                        <SkeletonComponent />
                                    )}

                                    {!isLoading && (ConversationCheck || StrategicCheck) && (
                                        <div
                                            style={{
                                                marginTop: '3%',
                                            }}
                                        >
                                            <div>
                                                {ConversationCheck && (
                                                    <div
                                                        style={{
                                                            background: 'white',
                                                            paddingBottom: '15px',
                                                            borderRadius: '4px',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: '95%',
                                                                margin: 'auto',
                                                                paddingTop: '10px',
                                                            }}
                                                            className="d-flex align-items-center justify-content-between"
                                                        >
                                                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                Conversation Starters
                                                            </div>
                                                            {companyNews.conversation_starters['Conversation Starters']
                                                                .length > 3 && (
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between"
                                                                    style={{ color: 'gray', cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        setOpenModal(true)
                                                                    }}
                                                                >
                                                                    <CallMadeIcon style={{ fontSize: '14px' }} />
                                                                    <span
                                                                        style={{
                                                                            fontSize: '14px',
                                                                            marginLeft: '3px',
                                                                            fontWeight: 600,
                                                                        }}
                                                                    >
                                                                        See All
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {companyNews.conversation_starters['Conversation Starters']
                                                            .slice(0, 3)
                                                            .map((value, index) => (
                                                                <ConversationStarters content={value} index={index} />
                                                            ))}
                                                    </div>
                                                )}
                                                {StrategicCheck && (
                                                    <div style={{ marginTop: '3%' }}>
                                                        <div
                                                            style={{
                                                                background: 'white',
                                                                paddingBottom: '15px',
                                                                borderRadius: '4px',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: '95%',
                                                                    margin: 'auto',
                                                                    paddingTop: '10px',
                                                                }}
                                                                className="d-flex align-items-center justify-content-between"
                                                            >
                                                                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                                    Strategic Priorities
                                                                </div>
                                                                {companyNews.strategic_priorities[
                                                                    'Strategic Priorities'
                                                                ].length > 3 && (
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-between"
                                                                        style={{ color: 'gray', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            setStrategicModal(true)
                                                                        }}
                                                                    >
                                                                        <CallMadeIcon style={{ fontSize: '14px' }} />
                                                                        <span
                                                                            style={{
                                                                                fontSize: '14px',
                                                                                marginLeft: '3px',
                                                                                fontWeight: 600,
                                                                            }}
                                                                        >
                                                                            See All
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {companyNews.strategic_priorities['Strategic Priorities']
                                                                .slice(0, 3)
                                                                .map((value, index) => (
                                                                    <ConversationStarters
                                                                        content={value}
                                                                        index={index}
                                                                    />
                                                                ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {!isLoading && (
                                    <div
                                        style={{
                                            marginTop: '3%',
                                            background:
                                                companyNews && companyNews.data && companyNews.data.length > 0
                                                    ? 'white'
                                                    : '',
                                        }}
                                        className={
                                            !isLoading && companyNewsCheck
                                                ? 'responsive_company_description_40'
                                                : 'responsive_company_description_100'
                                        }
                                    >
                                        {companyNews && companyNews.data && companyNews.data.length > 0 ? (
                                            <div
                                                style={{
                                                    marginLeft: '8px',
                                                }}
                                            >
                                                <CompanyNewsV2
                                                    CompanyNews={companyNews}
                                                    handleLoadMore={handleLoadMore}
                                                    setNewsFilters={setNewsFilters}
                                                    rowCheck={!isLoading && (ConversationCheck || StrategicCheck)}
                                                />
                                            </div>
                                        ) : (
                                            <div style={{ marginTop: '2%' }}>
                                                <h1 style={{ textAlign: 'center' }}>
                                                    No recent news for current company. To change preference, Click on{' '}
                                                    <NavLink to="/app/preferences/">My Preferences</NavLink> and update{' '}
                                                    {screenType}.
                                                </h1>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
                {/* setPremeetingModal */}

                <ConversationModal
                    News={ConversationCheck ? companyNews.conversation_starters['Conversation Starters'] : []}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    heading={'Conversation Starters'}
                />

                <ConversationModal
                    News={StrategicCheck ? companyNews.strategic_priorities['Strategic Priorities'] : []}
                    openModal={strategicModal}
                    setOpenModal={setStrategicModal}
                    heading={'Strategic Priorities'}
                />

                {/* <PreMeetingBriefModal
                    premeetingData={premeetingData}
                    setPremeetingPreview={setPremeetingPreview}
                    premeetingPreview={premeetingPreview}
                    preMeetingLoader={preMeetingLoader}
                    companyInformation={companyInformation}
                    token={token}
                /> */}

                {/* <NewPreMeetingBriefModal
                    premeetingData={premeetingData}
                    setPremeetingPreview={setPremeetingPreview}
                    premeetingPreview={premeetingPreview}
                    preMeetingLoader={preMeetingLoader}
                    companyInformation={companyInformation}
                    conversationStarters={
                        ConversationCheck ? companyNews.conversation_starters['Conversation Starters'] : []
                    }
                    strategicPriorities={StrategicCheck ? companyNews.strategic_priorities['Strategic Priorities'] : []}
                    token={token}
                /> */}

                {console.log('inside', reportStatus)}
                {console.log('insifedre', reportStatus[companyName && companyName.id])}
                {console.log('com', companyName.id)}

                {reportView && (
                    // reportStatus &&
                    // reportStatus[companyName && companyName.id] &&
                    // reportStatus[companyName && companyName.id].id === (companyName && companyName.id) &&
                    <DetailedReportPreview
                        detailedReportModal={premeetingPreview}
                        setDetailedReportModal={setPremeetingPreview}
                        jsonData={reportView}
                    />
                )}
            </div>
        </BaseScreen>
    )
}
