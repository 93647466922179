import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiOutlineSave } from 'react-icons/ai'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import CancelIcon from '@mui/icons-material/Cancel'
import { Autocomplete, Chip, Grid, TextField, createFilterOptions } from '@mui/material'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { Tooltip } from 'reactstrap'
import InfoIcon from '@mui/icons-material/Info'
import ClearIcon from '@mui/icons-material/Clear'

const filter = createFilterOptions()

export default function PreferenceContainers(props) {
    const {
        input,
        setInput,
        handleToggle,
        selectedinput,
        setSelectedInput,
        handleRemove,
        selectedData = [],
        tooltip,
        toggle,
    } = props

    const [data, setData] = useState([])
    const [companyModal, setCompanyMOdal] = useState(false)
    const [companyName, setCompanyName] = useState('')
    const [selectedurl, setSelectedUrl] = useState([])
    const [existedCompanies, setExistedCompanies] = useState([])
    const [existedUrls, setExistedUrls] = useState('')
    const [searchingData, setSearchingData] = useState([])

    useEffect(() => {
        setData(props.data)
    }, [])

    useEffect(() => {
        if (companyName.length < 3 && existedCompanies.length > 0) {
            setExistedCompanies([])
        }
    }, [existedCompanies])

    const addCompany = () => {
        if (companyName !== '' && selectedurl.length !== 0) {
            const payload = { name: companyName, domain: selectedurl.map(val => val.label) }

            if (existedUrls === '') {
                axios
                    .post('/news/fetch-add-company/', payload)
                    .then(res => {
                        console.log(res)
                        toast('Company request has been successfully added.')
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => {
                        setCompanyMOdal(!companyModal)
                        setExistedCompanies([])
                        setCompanyName('')
                        setSelectedUrl('')
                        setExistedUrls('')
                    })
            } else {
                toast.warn('Domain already exists')
            }
        } else {
            toast.warn('Please fill out all the fields.')
        }
    }

    const getCompany = (name, domain) => {
        if (name.length > 3 || domain.length !== 0) {
            axios
                .get(
                    `/news/fetch-add-company/?company${name !== '' ? '=' + name : ''}${
                        domain.length > 0 ? '&domain=' + domain.map(val => val.label) : ''
                    }`
                )
                .then(res => {
                    if (res.data.Companies) {
                        setExistedCompanies(res.data.Companies.map(val => val))
                        setExistedUrls('')
                    } else if (res.data) {
                        setExistedUrls(res.data)
                        setExistedCompanies([])
                    }
                })
                .catch(err => console.log(err))
        }
    }
    const customErrorMessage = (
        <div>
            We do not have this company in our database right now. Would you like to be notified when we add it?
            <br />
            <div className="d-flex justify-content-around">
                <div className="d-flex justify-content-between w-25">
                    <button className="btn">Yes</button>
                    <button className="btn">No</button>
                </div>
            </div>
        </div>
    )
    const filterOption = ({ label, alias }, inputValue) => {
        return (
            label.toLowerCase().includes(inputValue.toLowerCase()) ||
            (alias && alias.toLowerCase().includes(inputValue.toLowerCase()))
        )
    }

    return (
        <div>
            <div style={{ width: '90%' }}>
                <label className="d-flex align-items-center" style={{ fontSize: '16px', fontWeight: 400 }}>
                    <span>{props.header}</span>
                    {(window.location.pathname === '/app/preferences/' ||
                        window.location.pathname.includes('/app/admin/enduser/') ||
                        window.location.pathname.includes('app/admin/add-enduser')) && (
                        <>
                            {props.state === 'company' && (
                                <span style={{ marginLeft: '10px' }}>
                                    <InfoIcon
                                        id="user_companies"
                                        style={{
                                            cursor: 'pointer',
                                            display: 'inline-block',
                                        }}
                                        className="tooltip_icon"
                                    />
                                    <Tooltip
                                        placement="top-end"
                                        isOpen={tooltip}
                                        target="user_companies"
                                        toggle={() => toggle('user_companies')}
                                        className="custom-tooltip"
                                    >
                                        Start typing the name of companies the user wants to follow - you can select a
                                        company from the dropdownlist that appears
                                        {/* or type the full name if you don't
                                        see it in the dropdown. */}
                                    </Tooltip>
                                </span>
                            )}
                            {props.state === 'sector' && (
                                <span style={{ marginLeft: '10px' }}>
                                    <InfoIcon
                                        id="user_topics"
                                        style={{
                                            cursor: 'pointer',
                                            display: 'inline-block',
                                        }}
                                        className="tooltip_icon"
                                    />
                                    <Tooltip
                                        placement="top-end"
                                        isOpen={tooltip}
                                        target="user_topics"
                                        toggle={() => toggle('user_topics')}
                                        className="custom-tooltip"
                                    >
                                        Start typing the name of topics of interest. Select the topics (or consider
                                        selecting a similar topic) from dropdown or enter the full name of topic.
                                    </Tooltip>
                                </span>
                            )}
                        </>
                    )}
                </label>
                <div className="">
                    <div className="mt-2">
                        {' '}
                        <Select
                            isMulti
                            className={
                                window.location.pathname.includes('app/admin/add-enduser')
                                    ? 'preference_container'
                                    : 'w-100'
                            }
                            value={selectedData}
                            placeholder={props.placeHolder}
                            options={data.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            controlShouldRenderValue={false}
                            // noOptionsMessage={() => customErrorMessage}
                            onChange={value => {
                                handleToggle(props.state, value)
                            }}
                            styles={{
                                clearIndicator: () => ({ display: 'none' }), // Hide "remove all" icon
                                multiValueRemove: () => ({ display: 'none' }), // Hide "remove" (X) icon for each selected option
                                width: '90%',
                                height: '45px',
                                borderRadius: '4px',
                                background: 'white',
                                border: '1px solid hsl(0,0%,80%)',
                                outline: 'none',
                                fontSize: '14px',
                                menu: provided => ({
                                    ...provided,
                                    zIndex: 2, // Set z-index for the dropdown menu
                                }),
                            }}
                            filterOption={(option, inputValue) => filterOption(option.data, inputValue)}
                            onInputChange={typedValue => {
                                if (props.state === 'company' || props.state === 'competitor') {
                                    setInput(typedValue)
                                    const value = typedValue
                                    if (value.length > 2) {
                                        axios
                                            .get(
                                                `/news/auto-complete/?type=company&string=${encodeURIComponent(value)}`
                                            )
                                            .then(response => {
                                                const filteredData = response.data
                                                    .filter(item =>
                                                        item.name.toLowerCase().startsWith(typedValue.toLowerCase())
                                                    )
                                                    .map(item => ({
                                                        value: item.id,
                                                        label: item.name,
                                                        alias: item.alias,
                                                    }))
                                                const uniqueData = [
                                                    ...filteredData,
                                                    ...props.data,
                                                    ...searchingData,
                                                ].filter(
                                                    (ele, index, self) =>
                                                        index === self.findIndex(obj => obj.label === ele.label)
                                                )
                                                const arrayFromSet = [...uniqueData]
                                                setSearchingData(arrayFromSet)
                                                setData(arrayFromSet)
                                            })
                                            .catch(err => console.log(err))
                                    } else {
                                        const filterSelda = searchingData.filter(item =>
                                            selectedData.includes(item.value)
                                        )

                                        setData([...props.data, ...filterSelda])
                                    }
                                } else {
                                    setInput(typedValue)
                                }
                            }}
                        />
                        <Grid item>
                            <div
                                style={{
                                    maxHeight: '100px',
                                    overflowY: 'auto',
                                    padding: '5px',
                                    borderRadius: '4px',
                                }}
                            >
                                <Grid container direction="row" spacing={1}>
                                    {selectedData.map(user => (
                                        <Grid item key={user.value}>
                                            <Chip
                                                label={user.label}
                                                style={{
                                                    background: '#D4E4FA',
                                                    color: '#0D4EA6',
                                                }}
                                                size="small"
                                                onDelete={() => handleRemove(props.state, user.value)}
                                                deleteIcon={
                                                    <ClearIcon style={{ color: '#0D4EA699', fontSize: '12px' }} />
                                                }
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        </Grid>
                    </div>
                    {/* <div className="col-12 col-md-5">
                        {selectedData.length <= 0 && <p className="mt-2">{props.title}</p>}
                    </div> */}
                </div>
            </div>

            {/* New company modal */}
            <Modal
                isOpen={companyModal}
                toggle={() => {
                    setCompanyMOdal(!companyModal)
                }}
                size="lg"
            >
                <div style={{ padding: '10px', background: 'black' }} className="d-flex justify-content-between">
                    <h3 style={{ paddingLeft: '2%', color: 'white', fontWeight: 600 }}>Adding New Company</h3>

                    <span
                        style={{ paddingRight: '2%', cursor: 'pointer' }}
                        onClick={() => {
                            setCompanyMOdal(!companyModal)
                            setExistedCompanies([])
                            setCompanyName('')
                            setSelectedUrl('')
                            setExistedUrls('')
                        }}
                    >
                        <CancelIcon style={{ color: 'white' }} />
                    </span>
                </div>
                <ModalBody>
                    <div className="d-flex justify-content-between">
                        <div style={{ width: '60%' }}>
                            <div style={{ marginTop: '10px' }}>
                                <TextField
                                    sx={{ width: '400px' }}
                                    value={companyName}
                                    onChange={e => {
                                        setCompanyName(e.target.value)
                                        if (e.target.value.length > 3) {
                                            getCompany(e.target.value, selectedurl)
                                        } else {
                                            setExistedCompanies([])
                                        }
                                    }}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault()
                                            if (companyName !== '') {
                                                getCompany(e.target.value, selectedurl)
                                            }
                                        }
                                    }}
                                    // onBlur={() => {
                                    //     if (companyName !== '') {
                                    //         getCompany(companyName, selectedurl)
                                    //     }
                                    // }}
                                    variant="outlined"
                                    label="Company Name"
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Autocomplete
                                    sx={{ width: '400px' }}
                                    multiple
                                    options={[]}
                                    value={selectedurl}
                                    getOptionLabel={option => option.label}
                                    onChange={(event, newValue) => {
                                        if (newValue && newValue.length) {
                                            const results = []

                                            newValue.forEach(value => {
                                                if (typeof value === 'string') {
                                                    function validateurl(url) {
                                                        return /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(
                                                            url
                                                        )
                                                    }

                                                    if (!validateurl(value)) {
                                                        toast.warn(
                                                            `You have entered an incorrect domain name! ${value}`
                                                        )
                                                        return
                                                    }

                                                    return results.push({
                                                        label: value,
                                                        value: value,
                                                    })
                                                }

                                                return results.push(value)
                                            })

                                            setSelectedUrl(results)
                                            getCompany(companyName, results)
                                        } else {
                                            setSelectedUrl([])
                                            setExistedUrls('')
                                        }
                                    }}
                                    renderInput={params => (
                                        <TextField {...params} variant="outlined" label="Company Domains" />
                                    )}
                                    // renderTags={() => null}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                key={index}
                                                label={option.label}
                                                {...getTagProps({ index })}
                                                clickable
                                                onDelete={() => {
                                                    const newValues = [...selectedurl]
                                                    newValues.splice(index, 1)
                                                    setSelectedUrl(newValues)
                                                    if (newValues.length === 0) {
                                                        setExistedUrls('')
                                                    }
                                                    getCompany(companyName, newValues)
                                                }}
                                            />
                                        ))
                                    }
                                    freeSolo
                                    filterOptions={(options, params) => {
                                        const filtered = options.filter(option =>
                                            option.label.toLowerCase().includes(params.inputValue.toLowerCase())
                                        )

                                        if (
                                            params.inputValue !== '' &&
                                            !filtered.some(option => params.inputValue === option.label)
                                        ) {
                                            filtered.push({
                                                value: `Add URL: ${params.inputValue}`,
                                                label: params.inputValue,
                                            })
                                        }

                                        return filtered
                                    }}
                                    renderOption={(props, option) => (
                                        <li {...props} title="Click to add">
                                            {option.label || option.value}
                                        </li>
                                    )}
                                />
                            </div>
                        </div>
                        {existedCompanies.length > 0 && (
                            <div style={{ width: '40%' }}>
                                <label>Existing Companies</label>
                                <br />
                                <div style={{ marginLeft: '10px' }}>
                                    {existedCompanies.map(val => (
                                        <li>{val}</li>
                                    ))}
                                </div>
                            </div>
                        )}
                        {existedUrls !== '' && (
                            <p style={{ color: 'red', marginLeft: '10px', marginTop: '1rem' }}>{existedUrls}</p>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter style={{ padding: '0' }}>
                    <button className="btn btn-primary" onClick={addCompany} title="save">
                        Add
                        <span style={{ fontSize: '18px', marginLeft: '5px' }}>
                            <AiOutlineSave />
                        </span>
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
