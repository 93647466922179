import React from 'react'
import { Card, CardContent, Typography, Grid, Button } from '@mui/material'
import PublicIcon from '@mui/icons-material/Public'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import BusinessIcon from '@mui/icons-material/Business'
import GroupIcon from '@mui/icons-material/Group'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
const About = () => {
    return (
        <Card
            variant="outlined"
            style={{
                borderRadius: '8px',
                height: '280px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <CardContent style={{ padding: 0, flex: 1 }}>
                {/* Header Section */}
                <div
                    style={{
                        position: 'sticky',
                        top: 0,
                        background: '#fff',
                        zIndex: 10,
                        padding: '10px',
                    }}
                >
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography
                            variant="h6"
                            component="div"
                            style={{
                                fontWeight: 500,
                                color: '#0A0A0A',
                                fontSize: '16px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {' '}
                            <AccountBalanceIcon style={{ fontSize: '20px', color: '3B82F6', marginRight: '8px' }} />
                            About Klarna Bank
                        </Typography>
                        <Button
                            variant="contained"
                            size="small"
                            endIcon={<ArrowRightAltIcon />}
                            style={{
                                textTransform: 'none',
                                fontWeight: 600,
                                backgroundColor: '#f2f2f2',
                                color: '#171717',
                                boxShadow: 'none',
                            }}
                            href="https://www.klarna.com/international/"
                            target="_blank"
                        >
                            View
                        </Button>
                    </Grid>
                </div>

                {/* Scrollable Content */}
                <div
                    style={{
                        overflowY: 'auto',
                        height: 'calc(100% - 60px)',
                        padding: '10px',
                    }}
                >
                    {/* Description */}
                    <Typography
                        variant="body2"
                        style={{
                            marginBottom: '10px',
                            color: '#555',
                        }}
                    >
                        Klarna Bank operates in the financial technology sector. The company specializes in providing
                        payment solutions and services, ....
                    </Typography>

                    {/* Information Grid */}
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography
                                variant="body2"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <BusinessIcon
                                        fontSize="small"
                                        style={{ marginRight: '5px', color: '#555', fontSize: '17px' }}
                                    />
                                    Industry
                                </span>
                                <div style={{ color: '#9e9e9e', fontSize: '14px' }}>Financial Services</div>
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography
                                variant="body2"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <BusinessIcon
                                        fontSize="small"
                                        style={{ marginRight: '5px', color: '#555', fontSize: '17px' }}
                                    />
                                    Employees
                                </span>
                                <div style={{ color: '#9e9e9e', fontSize: '14px' }}>10</div>
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography
                                variant="body2"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                {' '}
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <BusinessIcon
                                        fontSize="small"
                                        style={{ marginRight: '5px', color: '#555', fontSize: '17px' }}
                                    />
                                    Headquarters
                                </span>
                                <div style={{ color: '#9e9e9e', fontSize: '14px' }}>10</div>
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography
                                variant="body2"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                {' '}
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <BusinessIcon
                                        fontSize="small"
                                        style={{ marginRight: '5px', color: '#555', fontSize: '17px' }}
                                    />
                                    Revenue range
                                </span>
                                <div style={{ color: '#9e9e9e', fontSize: '14px' }}>$2.2Bn</div>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    )
}

export default About
