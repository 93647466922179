import React, { useState } from 'react'
import { Button, Popover, Tooltip } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import companyIcon from '../../../assets/icons/companyReport.svg'
import alertsIcon from '../../../assets/icons/alerts.svg'
import emailIcon from '../../../assets/icons/emailOutreach.svg'
import linkedInIcon from '../../../assets/icons/linkedin.svg'
import thoughtIcon from '../../../assets/icons/thoughtLeadership.svg'
import sectorIcon from '../../../assets/icons/sectorReport.svg'
import takeAction from '../../../assets/icons/circle-top-up-svgrepo-com.svg'

function Share() {
    const [anchorEl, setAnchorEl] = useState(null)
    const [tooltipOpen, setTooltipOpen] = useState(false)

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const toggle = () => setTooltipOpen(!tooltipOpen)

    const open = Boolean(anchorEl)

    return (
        <>
            {/* <div
                style={{
                    fontWeight: 500,
                    color: '#0A0A0A',
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    gap: '8px',
                }}
                onClick={handlePopoverOpen}
            >
                {' '}
                <img src={takeAction} alt="thought" style={{ fontSize: '24px' }} />
                Take action
            </div> */}

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className="row">
                    <div className="col-sm">
                        <div
                            className="align-items-center"
                            style={{ borderRadius: '5px', padding: '10px 20px 20px 20px' }}
                        >
                            <label className="d-flex align-items-center justify-content-center heading font-h">
                                <b>Meeting Preparation</b>
                            </label>
                            <div className="card assisstant mb-3">
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <span className="d-flex align-items-center">
                                        <img src={companyIcon} alt="company_report" style={{ fontSize: '24px' }} />
                                        <span
                                            className="card-text font"
                                            style={{ marginLeft: '10px', fontWeight: 600 }}
                                        >
                                            Company Reports
                                        </span>
                                    </span>
                                    <span style={{ marginLeft: '4px' }}>
                                        <ArrowForwardIcon style={{ fontSize: '24px', color: 'black' }} />
                                    </span>
                                </div>
                            </div>
                            <div className="card assisstant mb-3">
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <span className="d-flex align-items-center">
                                        <img src={sectorIcon} alt="company_report" style={{ fontSize: '24px' }} />
                                        <span
                                            className="card-text font"
                                            style={{ marginLeft: '10px', fontWeight: 600 }}
                                        >
                                            Sector Reports
                                        </span>
                                    </span>
                                    <span style={{ marginLeft: '4px' }}>
                                        <ArrowForwardIcon style={{ fontSize: '24px', color: 'black' }} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm">
                        <div
                            className="align-items-center"
                            style={{ borderRadius: '5px', padding: '10px 20px 20px 20px' }}
                        >
                            <label className="d-flex align-items-center justify-content-center heading font-h">
                                <b>Client Prospecting/Targeting</b>
                            </label>
                            <div className="card assisstant mb-3">
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <span className="d-flex align-items-center">
                                        <img src={alertsIcon} alt="alerts" style={{ fontSize: '24px' }} />
                                        <span
                                            className="card-text font"
                                            style={{ marginLeft: '10px', fontWeight: 600 }}
                                        >
                                            Send me alerts for a Company/Topic
                                        </span>
                                    </span>
                                    <span style={{ marginLeft: '4px' }}>
                                        <ArrowForwardIcon style={{ fontSize: '24px', color: 'black' }} />
                                    </span>
                                </div>
                            </div>
                            <div
                                className="card"
                                id="Tooltip-email-outreach"
                                style={{ backgroundColor: '', border: 'none' }}
                            >
                                <div
                                    className="card-body d-flex align-items-center justify-content-between"
                                    style={{
                                        backgroundColor: '#E5E5E5',
                                        borderRadius: '5px',
                                        border: '1px solid lightgray',
                                    }}
                                >
                                    <span className="d-flex align-items-center" style={{ opacity: '0.5' }}>
                                        <img src={emailIcon} alt="email_outreach" style={{ fontSize: '24px' }} />
                                        <span
                                            className="card-text font"
                                            style={{ marginLeft: '10px', fontWeight: 600 }}
                                        >
                                            Send me content for Email Outreach
                                        </span>
                                    </span>
                                    <span style={{ marginLeft: '4px', opacity: '0.5' }}>
                                        <ArrowForwardIcon style={{ fontSize: '24px', color: 'black' }} />
                                    </span>
                                </div>
                                <Tooltip
                                    placement={'bottom'}
                                    open={tooltipOpen}
                                    onClose={toggle}
                                    onOpen={toggle}
                                    title="This feature is coming soon!"
                                    className="custom-tooltips"
                                >
                                    <span />
                                </Tooltip>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm">
                        <div
                            className="align-items-center"
                            style={{ borderRadius: '5px', padding: '10px 20px 20px 20px' }}
                        >
                            <label className="d-flex align-items-center justify-content-center heading font-h">
                                <b>Marketing/Thought Leadership</b>
                            </label>
                            <div className="card assisstant mb-3">
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <span className="d-flex align-items-center">
                                        <img src={thoughtIcon} alt="thought" style={{ fontSize: '24px' }} />
                                        <span
                                            className="card-text font"
                                            style={{ marginLeft: '10px', fontWeight: 600 }}
                                        >
                                            Give me content for Thought Leadership
                                        </span>
                                    </span>
                                    <span style={{ marginLeft: '4px' }}>
                                        <ArrowForwardIcon style={{ fontSize: '24px', color: 'black' }} />
                                    </span>
                                </div>
                            </div>
                            <div className="card assisstant">
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <span className="d-flex align-items-center">
                                        <img src={linkedInIcon} alt="linkedin" style={{ fontSize: '24px' }} />
                                        <span
                                            className="card-text font"
                                            style={{ marginLeft: '10px', fontWeight: 600 }}
                                        >
                                            Give me content for Social Media Posts
                                        </span>
                                    </span>
                                    <span style={{ marginLeft: '4px' }}>
                                        <ArrowForwardIcon style={{ fontSize: '24px', color: 'black' }} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    )
}

export default Share
