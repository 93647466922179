import React, { useState, useRef, useEffect } from 'react'
import { Dialog, DialogContent, Switch, Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/ArrowForward'
import { useHistory } from 'react-router-dom'

export default function ChatbotModal({ open, handleClose }) {
    const [searchQuery, setSearchQuery] = useState('')
    const [isPro, setIsPro] = useState(false)
    const inputRef = useRef(null)
    const history = useHistory()

    useEffect(() => {
        if (open && inputRef.current) {
            inputRef.current.focus()
        }
    }, [open])

    const handleSearch = () => {
        if (searchQuery.trim()) {
            // Instead of Redirect, use history to push a new route
            history.push(`/app/chat/?query=${encodeURIComponent(searchQuery)}`)
            handleClose()
        } else {
            console.warn('Search query is empty')
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogContent
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    minHeight: '170px',
                    backgroundColor: '#f8f8f8',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        maxWidth: '700px',
                        borderRadius: '16px',
                        backgroundColor: '#ffffff',
                        border: '1px solid #ddd',
                        padding: '38px 16px',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <input
                        ref={inputRef}
                        type="text"
                        placeholder="What do you want to know?"
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                handleSearch()
                            }
                        }}
                        style={{
                            flex: 1,
                            border: 'none',
                            outline: 'none',
                            fontSize: '16px',
                            color: '#333',
                            padding: '8px',
                            backgroundColor: 'transparent',
                        }}
                    />

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px',
                            marginLeft: '16px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                            }}
                        >
                            <Switch checked={isPro} onChange={e => setIsPro(e.target.checked)} size="small" />
                            <span style={{ fontSize: '14px', color: '#555' }}>Web search</span>
                        </div>
                        <Button
                            onClick={handleSearch}
                            style={{
                                minWidth: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                backgroundColor: '#007aff',
                                color: '#ffffff',
                                padding: '0',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <SearchIcon />
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
