import React from 'react'
// Import the Search component
import Search from './components/Search'
// Import the BaseScreen wrapper
import BaseScreen from '../BaseScreen/BaseScreen'

function ChatbotTemplate() {
    return (
        <BaseScreen>
            {/* Render the Search component inside the BaseScreen */}
            <Search />
            {/* <h1>hekldsufgiuhiodbfuhiodf</h1> */}
        </BaseScreen>
    )
}

export default ChatbotTemplate
