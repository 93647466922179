import React, { useState, useEffect, useRef } from 'react'
import BaseScreen from '../../BaseScreen/BaseScreen'
import { Button, Tab, Tabs } from '@mui/material'
import DataTable from 'react-data-table-component'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import { Circles } from 'react-loader-spinner'
import axios from 'axios'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { Grid, Chip, Autocomplete, TextField, createFilterOptions, ToggleButton } from '@mui/material'
import { toast } from 'react-toastify'
import QuickReportPreview from './QuickReportPreview'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NewAdvanceFilters from './NewAdvanceFilters'
import store from '../../../Store'
import { getYMD } from '../../../utils/dateHelpers'
import SchedulingSection from './SchedulingSection'
import { CgNotes } from 'react-icons/cg'
import { FcOpenedFolder } from 'react-icons/fc'
import { RiDeleteBin6Line } from 'react-icons/ri'
import DeleteNews from './DeleteNews'
import CancelIcon from '@mui/icons-material/Cancel'
import NewSearch from './NewSearch'
import NewSearchQueryResult from '../../../components/NewsEditedCard/NewSearchQueryResult'
import InfiniteScroll from 'react-infinite-scroller'
import FilterListIcon from '@mui/icons-material/FilterList'
import Cron from 'react-js-cron'
import { AiOutlineSave } from 'react-icons/ai'
import CronTab from '../../AnalystAdmin/CronTab'
import './MyRequests.css'
import NewAdvanceSearchTab from './NewAdvanceSearchTab'
import SaveSearches from './SaveSearches'
import { StepperButton, Steppers } from './Steppers'
import AddIcon from '@mui/icons-material/Add'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'

var lo_ = require('lodash')

const customStyles = {
    headRow: {
        style: {
            backgroundColor: '#00193C', // Change to your desired header background color
            color: '#FFF', // Change to your desired header text color
            fontSize: '16px', // Change to your desired header font size
            height: '20px',
        },
    },
}

const sections = [
    { label: 'Business Events', value: 'subcategories' },
    { label: 'Company', value: 'companies' },
    { label: 'Industry', value: 'industries' },
    { label: 'Sector', value: 'sectors' },
    { label: 'Topic', value: 'topics' },
]

const filter = createFilterOptions()
export default function NewNewsLetters() {
    const currentPath = window.location.pathname

    const usertype = store.getState().auth.user.user_type
    const userEmail = store.getState().auth.user.email
    const [isLoading, setIsLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [subTab, setSubTab] = useState(0)
    const [search, setSearch] = useState('')
    const [reports, setReports] = useState([])
    const [alerts, setAlerts] = useState([])
    const [collectionQueries, setCollectionQueries] = useState([])
    const [nonCollectionQueries, setNonCollectionQueries] = useState([])
    const [iframeReport, setIframeReport] = useState(false)
    const [documents, setDocument] = useState('')
    const [quickReportModal, setQuickReportModal] = useState(false)
    const [EditPage, setEditPage] = useState(false)
    const [editQuery, setEditQuery] = useState(null)
    const [allCategories, setAllCategories] = useState([])
    const [allCompanies, setAllCompanies] = useState([])
    const [regionHierarchy, setRegionHierarchy] = useState([])
    const [allIndustries, setAllIndustries] = useState([])
    const [allSubCategories, setAllSubCategories] = useState([])
    const [allSources, setAllSources] = useState([])
    const [allHierarchy, setAllHierarchy] = useState([])
    const [selectedSectors, setSelectedSectors] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedTopics, setSelectedTopics] = useState([])
    const [selectedRegions, setSelectedRegions] = useState([])
    const [selectedSources, setSelectedSources] = useState([])
    const [practiceSubCategory, setPracticeSubCategory] = useState([])
    const [showBusinessEvents, setShowBusinessEvents] = useState([])
    const [defaultData, setDefaultData] = useState([])
    const [dateSection, setDateSection] = useState(false)
    const [selectedOption, setSelectedOption] = useState('59')
    const [includeSection, setIncludeSection] = useState(false)
    const [includeBusiness, setIncludeBusiness] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [users, setUsers] = useState([])
    const [selectedSection, setSelectedSection] = useState(null)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [expandedView, setExpandedView] = useState(false)
    const [basicSearchResponse, setBasicSearchResponse] = useState({ data: [], next: null })
    const [basicSearchInput, setBasicSearchInput] = useState('')
    const [loadmoreFilter, setLoadMoreFilter] = useState({ value: null, type: null })
    const [cronTabValue, setCronTabValue] = useState('0 0 * * 1')
    const [editCron, setEditCron] = useState(null)
    const [isEnabled, setIsEnabled] = useState(false)
    const [lastScheduled, setLastScheduled] = useState(null)
    const [nextScheduled, setNextScheduled] = useState(null)
    const [templates, setTemplates] = useState([])
    const [emailSubject, setEmailSubject] = useState('Recommendation Newsletter')
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteId, setDeleteId] = useState({ id: null, query: '' })
    const [allCollectionName, setAllCollectionName] = useState([])
    const [collectionName, setCollectionName] = useState(null)
    const [collectionDescription, setCollectionDescription] = useState('')
    const [collectionShared, setCollectionShared] = useState(false)
    const [collectionUsers, setCollectionUsers] = useState([])
    const [collectionModal, setCollectionModal] = useState(false)
    const [collectionShareList, setCollectionShareList] = useState([
        { value: true, label: 'Share with Everyone' },
        { value: false, label: 'Private' },
    ])
    const [collectionPrivacy, setCollectionPrivacy] = useState({ value: true, label: 'Share with Everyone' })
    const [collectionId, setCollectionId] = useState(null)
    const [editCollection, setEditCollection] = useState(false)
    const [deleteCollection, setDeleteCollection] = useState(false)
    const [saveQueryName, setSaveQueryName] = useState('')
    const [basicSearchDrop, setBasicSearchDrop] = useState(false)

    var startDate = new Date()
    startDate.setDate(startDate.getDate() - Number(selectedOption))
    const [selectedDatefrom, setSelectedDateFrom] = useState(getYMD(startDate))
    const [selectedDateTo, setSelectedDateTo] = useState(getYMD(new Date()))
    const [isLoader, setIsLoader] = useState(false)
    const [collectionTab, setCollectionTab] = useState(0)
    const [allTopics, setAllTopics] = useState([])
    const [allRegions, setAllRegions] = useState([])
    const [scheduleModel, setScheduleModal] = useState(false)
    const [scheduleContent, setScheduleContent] = useState([])
    const [allowedDropdowns, setAllowedDropdowns] = useState(['period', 'months', 'month-days', 'week-days', 'hours'])
    const [fromFilter, setFromFilter] = useState([])

    const [advanceResponse, setAdvanceResponse] = useState({ data: [], next: null })
    const [semanticSaveModal, setSemanticSaveModal] = useState(false)
    const [editId, setEditId] = useState('')
    const [directRun, setDirectRun] = useState(true)
    const [selectedLevel, setSelectedLevel] = useState('level2')
    const [selectedQueries, setSelectedQueries] = useState({
        articleDateFrom: '',
        articleOperator: 'BETWEEN',
        articleDateTo: '',
        region: [],
        regionOperator: 'IS',
        sectors: [],
        industryOperator: 'IS',
        company: [],
        companyOperator: 'IS',
        category: [],
        categoryOperator: 'IS',
        topic: [],
        topicOperator: 'IS',
        newsTitle: '',
        newsTitleOperator: 'CONTAINS',
        newsContent: '',
        newsContentOperator: 'CONTAINS',
        source: [],
        sourceOperator: 'IS',
    })
    const [selectedStories, setSelectedStories] = useState([])
    const [editqueryName, setEditQueryName] = useState('')
    const [saveModal, setSaveModal] = useState(false)
    const [activeStep, setActiveStep] = useState(false)
    const [createNews, setCreateNews] = useState(false)
    const [createNewsTab, setCreateNewsTab] = useState(null)

    useEffect(() => {
        const headerTitle = document.getElementById('header_title')
        headerTitle.textContent = 'Newsletters'
    }, [])

    useEffect(() => {
        setIsLoader(true)
        getData('/news/report-request/', setReports)
        // getData('/news/search-query/', setAlerts)
        getData('/news/all-companies/', setAllCompanies)
        getData('/news/industries-sectors-topics/', setAllHierarchy)
        getData('/news/sources/', setAllSources)
        getData('/news/all-subcategories/', setAllSubCategories)

        axios.get('/news/categories/').then(response => {
            setAllCategories(response.data.results)
        })
        getCollection()
        getIndSecTopics()
        getRegions()
        getPracticeArea()
        getUsers()
        getAllCollections()
        getTemplates()
        getTopics()
        axios.get(`/news/all-regions/`).then(response => {
            setAllRegions(response.data)
        })
    }, [])

    useEffect(() => {
        setDefaultData(allCompanies)
        flattenCategories()
    }, [allCompanies])

    const getTopics = () => {
        axios.get('/news/topics/').then(response => {
            let remappedTopics = response.data.map(item => {
                return { value: item.id, label: item.name }
            })
            remappedTopics = lo_.orderBy(remappedTopics, ['label'], ['asc'])

            setAllTopics(remappedTopics)
        })
    }

    const handleLoadQuick = () => {
        // const relevanceResult = 110 - Number(selectedRelavance)
        // const aplhaResult = Number(selectedAlpha)
        let data = {
            filters: {
                source: {
                    operator: 'IS',
                    values: selectedSources,
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                articleDate: {
                    operator: 'BETWEEN',
                    from: selectedDatefrom,
                    to: selectedDateTo,
                },
                sectors: {
                    operator: 'IS',
                    values:
                        allIndustries.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allIndustries
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedSectors)
                                  ),
                              ]
                            : addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                },
                company: {
                    operator: 'IS',
                    values:
                        defaultData.filter(val => val.label === basicSearchInput).length > 0 && basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      defaultData
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedCompanies)
                                  ),
                              ]
                            : selectedCompanies,
                },
                category: {
                    operator: 'IS',
                    values:
                        allSubCategories.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allSubCategories
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedCategories)
                                  ),
                              ]
                            : [
                                  ...new Set(
                                      practiceSubCategory.reduce((allitems, item) => {
                                          const selList = allitems

                                          if (selectedCategories.includes(item.id)) {
                                              if (!selList.includes(item.name)) {
                                                  selList.push(item.name)
                                              }
                                          }
                                          item.subcategories.forEach(subItem => {
                                              if (
                                                  Boolean(selectedCategories.includes(subItem.id)) &&
                                                  !selList.includes(subItem.name)
                                              ) {
                                                  selList.push(subItem.name)
                                              }
                                          })

                                          return selList
                                      }, [])
                                  ),
                              ],
                },
                topics: {
                    operator: 'IS',
                    values:
                        allIndustries.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allIndustries
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedSectors)
                                  ),
                              ]
                            : allTopicsName.filter(acc => selectedSectors.includes(acc)),
                },
            },
            next: basicSearchResponse.next,
            relevance_cutoff:
                selectedOption === '1' || selectedOption === '0' || selectedOption === '6'
                    ? 3
                    : selectedOption === '29'
                    ? 13
                    : selectedOption === '59'
                    ? 23
                    : selectedOption === '89'
                    ? 33
                    : 43,
            semantic_preference: 0.5,
            type: 'level2',
        }
        if (basicSearchInput !== '' && loadmoreFilter.value === null) {
            data.query = basicSearchInput
        }

        if (basicSearchInput !== '' && loadmoreFilter.value !== null) {
            data.filters[loadmoreFilter.type] = {
                operator: 'IS',
                values: [loadmoreFilter.value],
            }
        }
        if (basicSearchInput !== '' && loadmoreFilter.value !== null) {
            data.non_entity_query = loadmoreFilter.value
        }

        if (basicSearchResponse.next !== null) {
            axios
                .post('/semantic/quick-search/', data)
                .then(response => {
                    let newsBefore = [...basicSearchResponse.data, ...response.data.data]
                    setBasicSearchResponse({
                        total: response.data.total,
                        next: response.data.next,
                        data: newsBefore,
                    })
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            setIsLoading(false)
        }
    }

    const getCollection = () => {
        axios
            .get('/news/collection/')
            .then(res => {
                const data = res.data.collections.map(collection => ({
                    ...collection,
                    queries: collection.queries.filter(query => query.query_string === null),
                }))
                setCollectionQueries(data)
                // const noncollectiondata = res.data.queries
                // setNonCollectionQueries(noncollectiondata)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setIsLoader(false)
            })
    }

    const getUsers = () => {
        axios.get('/endusers/firm-users/').then(res => {
            const filter = [userEmail]
            filter.forEach(email => {
                const existingUser = res.data.find(user => user.email === email)

                if (!existingUser) {
                    const newUser = {
                        id: email,
                        email: email,
                        full_name: email,
                    }

                    res.data.push(newUser)
                }
            })
            res.data.sort((a, b) => (a.email === userEmail ? -1 : b.email === userEmail ? 1 : 0))

            setUsers(res.data)
        })
    }

    const scheduling = row => {
        setScheduleContent(row)
        setScheduleModal(!scheduleModel)
        setTimeout(() => {
            // const parentElement = document.querySelector('.react-js-cron-hours')
            // const spanElement = parentElement.querySelector('span')

            // if (parentElement && spanElement && spanElement.textContent.trim() === 'at') {
            //     spanElement.innerHTML = 'at hour'
            // }
            setIsEnabled(row.scheduled)
            const currentTemplate = templates.filter(val => val.value === row.template)
            if (currentTemplate.length > 0) {
                setSelectedTemplate(currentTemplate[0])
            } else {
                setSelectedTemplate(null)
            }
            if (row.section) {
                const currentsection = sections.filter(val => val.value === row.section)
                setSelectedSection(currentsection[0])
            } else {
                setSelectedSection(null)
            }
            if (row.has_section) {
                setIncludeSection(true)
            } else {
                setIncludeSection(false)
            }
            if (row.include_subcategory) {
                setIncludeBusiness(true)
            } else {
                setIncludeBusiness(false)
            }
            if (row.subject) {
                if (row.subject !== '') {
                    setEmailSubject(row.subject)
                } else {
                    setEmailSubject(`${row.query_name + '-insights'}`)
                }
            } else {
                setEmailSubject(`${row.query_name + '-insights'}`)
            }
            if (row.recipients) {
                const resultArray = row.recipients.split(',')

                const filter = resultArray

                filter.forEach(email => {
                    const existingUser = users.find(user => user.email === email)

                    if (!existingUser) {
                        const newUser = {
                            id: email,
                            email: email,
                            full_name: email,
                        }

                        users.push(newUser)
                    }
                })

                const currentUser = users.filter(val => resultArray.includes(val.email))

                if (currentUser.length > 0) {
                    setSelectedUsers(currentUser)
                }
            } else {
                setSelectedUsers([])
            }
            if (row.cron_expression) {
                setCronTabValue(row.cron_expression)
            } else {
                setCronTabValue('0 0 * * 1')
            }
        }, scheduleModel)
    }

    const getTemplates = () => {
        axios.get('/endusers/templates/').then(response => {
            setTemplates(
                response.data
                    .filter(each => each.custom_template === false)
                    .map(each => {
                        return {
                            value: each.id,
                            label: each.name,
                            imageUrl: each.thumbnail,
                            has_sections: each.has_sections,
                        }
                    })
            )
        })
    }

    const getPracticeArea = () => {
        axios.get('/news/practice-areas-subcategories/').then(response => {
            setPracticeSubCategory(
                response.data.results.map((item, index) => ({
                    ...item,
                    id: 'Cat_' + item.id + index,
                    subcategories: item.subcategories.map((it, i) => ({
                        ...it,
                        id: 'Sub_' + it.id + index + i,
                    })),
                }))
            )
        })
    }

    const getRegions = () => {
        axios.get('/news/region-countries-provinces/').then(response => {
            const regions = response.data.map(item => ({
                ...item,
                id: item.type + '_' + item.id,
                countries: item.countries
                    ? item.countries.map(cty => ({
                          ...cty,
                          id: cty.type + '_' + cty.id,
                          provinces: cty.provinces
                              ? cty.provinces.map(pro => ({
                                    ...pro,
                                    id: pro.type + '_' + pro.id,
                                }))
                              : [],
                      }))
                    : [],
            }))
            const data = regions

            const customSort = (a, b) => {
                if (a.name === 'North America') {
                    return -1
                } else if (b.name === 'North America') {
                    return 1
                }
                return a.name.localeCompare(b.name)
            }

            const sortedData = data.sort((a, b) => customSort(a, b))

            sortedData.forEach(region => {
                region.countries.sort((a, b) => a.name.localeCompare(b.name))
            })

            setRegionHierarchy(sortedData)
        })
    }

    const getIndSecTopics = () => {
        axios.get(`/news/all-industries-sectors/`).then(response => {
            const updatedIndustries = []
            response.data.forEach(item => {
                if (item.nick_name) {
                    const tempObj = { ...item }
                    tempObj.label = item.nick_name
                    updatedIndustries.push(tempObj)
                } else {
                    updatedIndustries.push(item)
                }
            })
            setAllIndustries(updatedIndustries)
        })
    }

    const saveChanges = row => {
        const payload = {
            id: row.id,
            query: row.query,
            query_name: row.query_name,
            template: selectedTemplate && selectedTemplate.value ? selectedTemplate.value : null,
            user_recipients: selectedUsers.map(val => val.id).filter(val => !isNaN(val) && typeof val === 'number'),
            last_runtime: row.last_runtime,
            scheduled: isEnabled,
            cron_expression: cronTabValue,
            recipients: selectedUsers.map(val => val.email),
            newsletter: 'saved_search_newsletter',
            section:
                selectedTemplate && selectedTemplate.has_sections && selectedSection ? selectedSection.value : null,
            query_string: row.query_string,
            quick_search_query: row.quick_search_query,
            subject: emailSubject,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            // curation_level: selectedLevel,
        }
        if (isEnabled) {
            if (
                selectedTemplate &&
                selectedTemplate.value &&
                selectedUsers.length > 0 &&
                emailSubject !== ''
                //  &&
                // ((selectedTemplate.has_sections && selectedSection) || !selectedTemplate.has_sections)
            ) {
                if (row.apscheduler_id !== null) {
                    payload.apscheduler_id = row.apscheduler_id
                }
                axios
                    .put(`/news/search-query/${row.id}/`, payload)
                    .then(res => {
                        toast('Query has been successfully updated')
                        setScheduleModal(!scheduleModel)
                        // getSearchQueries()
                        getCollection()
                    })
                    .catch(err => console.log(err))
            } else {
                toast.warn('Please fill out all the fields.')
            }
        } else {
            if (row.apscheduler_id !== null) {
                payload.apscheduler_id = row.apscheduler_id
            }
            axios
                .put(`/news/search-query/${row.id}/`, payload)
                .then(res => {
                    toast('Query has been successfully updated')
                    setScheduleModal(!scheduleModel)
                    // getSearchQueries()
                    getCollection()
                })
                .catch(err => console.log(err))
        }
    }

    const getAllCollections = () => {
        axios.get('/news/collection/').then(response => {
            const data = response.data.collections.map(res => {
                return {
                    value: res.id,
                    label: res.name,
                    description: res.description,
                    users: res.users,
                    creator: res.creator,
                    firm: res.firm,
                    record_date: res.record_date,
                    is_active: res.is_active,
                    shared: res.shared,
                    thumbnail: res.thumbnail,
                }
            })

            const sortedOptions = [...data].sort((a, b) => a.label.localeCompare(b.label))

            setAllCollectionName(sortedOptions)
        })
    }

    const flattenCategories = () => {
        const flattened = []

        const flattenHelper = category => {
            flattened.push({ id: category.id, name: category.name })
            if (category.subcategories && category.subcategories.length > 0) {
                category.subcategories.forEach(subcategory => {
                    flattenHelper(subcategory)
                })
            }
        }

        practiceSubCategory.forEach(category => {
            flattenHelper(category)
        })

        setShowBusinessEvents([...new Set(flattened)])

        return [...new Set(flattened)]
    }

    const getData = (api, setState) => {
        axios.get(api).then(res => setState(res.data))
    }

    const allIndustriesName = allHierarchy.map(val => val.name)

    const allSectorsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.name))
        .reduce((acc, curr) => acc.concat(curr), [])

    const allTopicsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.topics.map(top => top.name)))
        .reduce((acc, curr) => acc.concat(curr), [])
        .reduce((acc, curr) => acc.concat(curr), [])

    const addingOfIndustriesSectorsNames = allIndustriesName.concat(allSectorsName)

    const advanceSearch = () => {
        setBasicSearchResponse({ data: [], next: null })
        setAdvanceResponse({ data: [], next: null })
        // setQuickSummary(null)
        setIsLoading(true)
        // setSortBy(null)
        // setNewsSourceFilter([])
        // setDateFilter('59')
        const payLoad = {
            filters: {
                source: {
                    operator: selectedQueries.sourceOperator,
                    values: selectedQueries.source.map(val => val.value),
                },
                articleDate: {
                    operator: selectedQueries.articleOperator,
                    from: selectedQueries.articleDateFrom !== '' ? selectedQueries.articleDateFrom : getYMD(startDate),
                    to: selectedQueries.articleDateTo !== '' ? selectedQueries.articleDateTo : getYMD(new Date()),
                },
                countries: {
                    operator: selectedQueries.regionOperator,
                    values: selectedQueries.region.map(value => value.label),
                },
                sectors: {
                    operator: selectedQueries.industryOperator,
                    values: selectedQueries.sectors.map(val => val.label),
                },
                company: {
                    operator: selectedQueries.companyOperator,
                    values: selectedQueries.company.map(val => val.label),
                },
                category: {
                    operator: selectedQueries.categoryOperator,
                    values: selectedQueries.category.map(val => val.label),
                },
                topics: {
                    operator: selectedQueries.topicOperator,
                    values: selectedQueries.topic.map(val => val.label),
                },
                newsTitle: {
                    operator: selectedQueries.newsTitleOperator,
                    value: selectedQueries.newsTitle,
                    fuzziness: 3,
                },
                newsContent: {
                    operator: selectedQueries.newsContentOperator,
                    regex: selectedQueries.newsContent,
                },
            },
            search_type: 'advanced',
            next: 0, // For Next Batch
            size: 15, // Batch size
        }
        axios
            .post(`/news/level2/search/`, payLoad)
            .then(res => {
                setAdvanceResponse(res)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleLoadMore = () => {
        let data = {
            next: advanceResponse.data.next,
            size: advanceResponse.data.size,
            total: advanceResponse.data.total,
            scroll_id: advanceResponse.data.scroll_id,
        }
        axios.post('/news/scroll/', data).then(response => {
            let newsBefore = [...advanceResponse.data.data, ...response.data.data]
            setAdvanceResponse({
                ...response,
                data: {
                    total: response.data.total,
                    next: response.data.next,
                    scroll_id: response.data.scroll_id,
                    data: newsBefore,
                },
            })
        })
    }

    const clearFilters = () => {
        setEditQuery(null)
        setEditPage(false)
        setSubTab(0)
        setCollectionDescription('')
        setCollectionId(null)
        setCollectionName(null)
        setBasicSearchResponse({ data: [], next: null })
        setAdvanceResponse({ data: [], next: null })
        setEditId('')
        setActiveTab(0)
        getCollection()
    }

    const weviateSearch = (dropdownValue, type) => {
        setBasicSearchResponse({ data: [], next: null })
        setAdvanceResponse({ data: [], next: null })
        setBasicSearchDrop(false)
        // setQuickSummary(null)
        // setShowFullText(false)
        // setSearchName('')
        // setAdvanceResponse({ data: [], next: null })

        const keyName =
            type === 'topic'
                ? 'topics'
                : type === 'SubCategory'
                ? 'category'
                : type === 'Company'
                ? 'company'
                : 'sectors'
        setIsLoading(true)
        if (dropdownValue !== undefined) {
            setLoadMoreFilter({ value: dropdownValue, type: keyName })
        } else {
            setLoadMoreFilter({ value: null, type: null })
        }
        // const relevanceResult = 110 - Number(selectedRelavance)
        // const aplhaResult = Number(selectedAlpha)

        const payload = {
            filters: {
                source: {
                    operator: 'IS',
                    values: selectedSources,
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                articleDate: {
                    operator: 'BETWEEN',
                    from: selectedDatefrom,
                    to: selectedDateTo,
                },
                sectors: {
                    operator: 'IS',
                    values:
                        allIndustries.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allIndustries
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedSectors)
                                  ),
                              ]
                            : addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                },
                company: {
                    operator: 'IS',
                    values:
                        defaultData.filter(val => val.label === basicSearchInput).length > 0 && basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      defaultData
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedCompanies)
                                  ),
                              ]
                            : selectedCompanies,
                },
                category: {
                    operator: 'IS',
                    values:
                        allSubCategories.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allSubCategories
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedCategories)
                                  ),
                              ]
                            : [
                                  ...new Set(
                                      practiceSubCategory.reduce((allitems, item) => {
                                          const selList = allitems

                                          if (selectedCategories.includes(item.id)) {
                                              if (!selList.includes(item.name)) {
                                                  selList.push(item.name)
                                              }
                                          }
                                          item.subcategories.forEach(subItem => {
                                              if (
                                                  Boolean(selectedCategories.includes(subItem.id)) &&
                                                  !selList.includes(subItem.name)
                                              ) {
                                                  selList.push(subItem.name)
                                              }
                                          })

                                          return selList
                                      }, [])
                                  ),
                              ],
                },
                topics: {
                    operator: 'IS',
                    values:
                        allIndustries.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allIndustries
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedSectors)
                                  ),
                              ]
                            : allTopicsName.filter(acc => selectedSectors.includes(acc)),
                },
            },
            relevance_cutoff:
                selectedOption === '1' || selectedOption === '0' || selectedOption === '6'
                    ? 3
                    : selectedOption === '29'
                    ? 13
                    : selectedOption === '59'
                    ? 23
                    : selectedOption === '89'
                    ? 33
                    : 43,

            semantic_preference: 0.5,
            type: 'level2',
            // type: selectedLevel,
        }
        if (basicSearchInput !== '' && dropdownValue === undefined) {
            payload.query = basicSearchInput
            // setSearchName(basicSearchInput)
        }
        if (basicSearchInput !== '' && type !== undefined) {
            payload.filters[keyName] = {
                operator: 'IS',
                values: [dropdownValue],
            }
        }

        if (basicSearchInput !== '' && dropdownValue && dropdownValue.length > 0) {
            payload.non_entity_query = dropdownValue
            // setSearchName(dropdownValue)
        }

        axios
            .post('/semantic/quick-search/', payload)
            .then(res => {
                // if (res.data.summary) {
                //     setQuickSummary(res.data.summary)
                // } else {
                //     setQuickSummary(null)
                // }

                setBasicSearchResponse({
                    total: res.data.total,
                    next: res.data.next,
                    data: res.data.data,
                })
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false)
                setFromFilter([])
            })
    }

    const editSemanticQuery = () => {
        const payload = {
            query_name: directRun ? editqueryName : saveQueryName,
            quick_search_query: basicSearchInput,
            query: {
                filters: {
                    source: {
                        operator: 'IS',
                        values: selectedSources,
                    },
                    countries: {
                        operator: 'IS',
                        values: selectedRegions,
                    },
                    company: {
                        operator: 'IS',
                        values:
                            loadmoreFilter.type === 'company'
                                ? [...selectedCompanies, ...[loadmoreFilter.value]]
                                : selectedCompanies,
                    },
                    sectors: {
                        operator: 'IS',
                        values:
                            loadmoreFilter.type === 'sectors'
                                ? [
                                      ...addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                                      ...[loadmoreFilter.value],
                                  ]
                                : addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                    },
                    category: {
                        operator: 'IS',
                        values:
                            loadmoreFilter.type === 'category'
                                ? [...selectedCategories, ...[loadmoreFilter.value]]
                                : selectedCategories,
                    },
                    topics: {
                        operator: 'IS',
                        values:
                            loadmoreFilter.type === 'category'
                                ? [
                                      ...allTopicsName.filter(acc => selectedSectors.includes(acc)),
                                      ,
                                      ...[loadmoreFilter.value],
                                  ]
                                : allTopicsName.filter(acc => selectedSectors.includes(acc)),
                    },
                    date: selectedOption,
                    // relavance: selectedRelavance,
                },
            },
            query_string: null,
            section: selectedSection,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            subject: emailSubject,
            recipients: selectedUsers.map(val => val.email),
            curation_level: selectedLevel,
            collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
            collection_description: collectionDescription,
            collection_privacy: collectionPrivacy && collectionPrivacy.value,
            collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
            collection: collectionName && collectionName.value ? collectionName.value : null,
        }
        const collectionPayload = {
            name: collectionName,
            description: collectionDescription,
            shared: collectionPrivacy && collectionPrivacy.value,
            users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
        }

        const updateEditQuickQuery = () => {
            axios
                .put(`/news/search-query/${editId}/`, payload)
                .then(res => {
                    setEditPage(false)
                    toast('Query has been successfully updated')
                    setBasicSearchInput('')
                    setEditQueryName('')
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setSemanticSaveModal(false)
                    setCollectionDescription('')
                    setCollectionName(null)
                    setCollectionUsers([])
                    setCollectionModal(false)
                    getAllCollections()
                    getCollection()
                    clearFilters()
                })
        }
        if (editqueryName !== '') {
            if (collectionModal) {
                axios
                    .post('/news/collection/', collectionPayload)
                    .then(res => {
                        if (res.data && res.data.id) {
                            payload.collection = res.data.id
                            updateEditQuickQuery()
                        }
                    })
                    .catch(err => console.log(err))
            } else {
                updateEditQuickQuery()
            }
        }
    }

    const EditQueries = row => {
        setEditQuery(row)
        setBasicSearchInput(row.quick_search_query)
        setSaveQueryName(row.query_name)
        setEditQueryName(row.query_name)

        setEditPage(true)
        // if (row.quick_search_query !== null) {
        //     setSubTab(0)
        // } else {
        //     setSubTab(1)
        // }

        if (row.cron_expression) {
            setCronTabValue(row.cron_expression)
        } else {
            setCronTabValue('0 0 * * 1')
        }
        if (row.scheduled) {
            setIsEnabled(true)
        } else {
            setIsEnabled(false)
        }
        if (row.subject) {
            setEmailSubject(row.subject)
        }
        if (row.has_section) {
            setIncludeSection(row.has_section)
        }
        if (row.include_subcategory) {
            setIncludeBusiness(row.include_subcategory)
        }
        if (row.collection) {
            setCollectionId(row.collection)
        }
        if (row.collection_name) {
            setCollectionName(allCollectionName.filter(val => val.label === row.collection_name)[0])
        }
        if (row.collection_description) {
            setCollectionDescription(row.collection_description)
        }
        if (row.collection_privacy) {
            setCollectionPrivacy(collectionShareList.filter(val => val.row === row.collection_privacy)[0])
        }
        if (row.collection_users) {
            setCollectionUsers(users.filter(user => row.collection_users.some(f => f.row === user.id)))
        }

        if (row.template) {
            const currentTemplate = templates.filter(val => val.value === row.template)
            if (currentTemplate.length > 0) {
                setSelectedTemplate(currentTemplate[0])
            } else {
                setSelectedTemplate(templates[0])
            }
        }

        if (row.recipients) {
            console.log(row.recipients)
            const resultArray = row.recipients.split(',')
            const filter = resultArray
            filter.forEach(email => {
                const existingUser = users.find(user => user.email === email)
                if (!existingUser) {
                    const newUser = {
                        id: email,
                        email: email,
                        full_name: email,
                    }
                    users.push(newUser)
                }
            })
            const currentUser = users.filter(val => resultArray.includes(val.email))
            if (currentUser.length > 0) {
                setSelectedUsers(currentUser)
            }
        }
        if (row.quick_search_query !== null) {
            setSubTab(0)

            if (row.query && row.query.filters) {
                const populate = row.query.filters
                const sourcesFromSaved = populate.source.values.map(val => val)
                const regionfromSaved = populate.countries.values.map(val => val)
                const companyfromSaved = populate.company.values.map(val => val)
                const sectorFromSaved = populate.sectors.values.map(val => val)
                const categoryFromSaved = populate.category.values.map(val => val)
                const topicFromSaved = populate.topics.values.map(val => val)
                const dateFromSaved = populate.date ? populate.date : '59'

                const fromSaved = [...sourcesFromSaved, ...companyfromSaved, ...sectorFromSaved, ...topicFromSaved]

                setTimeout(() => {
                    setIsLoading(true)
                    setBasicSearchResponse({ data: [], next: null })
                    setBasicSearchDrop(false)
                    setEditId(row.id)

                    setLoadMoreFilter({ value: null, type: null })

                    const payload = {
                        filters: {
                            source: {
                                operator: 'IS',
                                values: sourcesFromSaved,
                            },
                            countries: {
                                operator: 'IS',
                                values: regionfromSaved,
                            },
                            articleDate: {
                                operator: 'BETWEEN',
                                from: selectedDatefrom,
                                to: selectedDateTo,
                            },
                            sectors: {
                                operator: 'IS',
                                values: [...sectorFromSaved, ...topicFromSaved],
                            },
                            company: {
                                operator: 'IS',
                                values: companyfromSaved,
                            },
                            category: {
                                operator: 'IS',
                                values: categoryFromSaved,
                            },
                            topics: {
                                operator: 'IS',
                                values: topicFromSaved,
                            },
                        },
                        relevance_cutoff:
                            selectedOption === '1' || selectedOption === '0' || selectedOption === '6'
                                ? 3
                                : selectedOption === '29'
                                ? 13
                                : selectedOption === '59'
                                ? 23
                                : selectedOption === '89'
                                ? 33
                                : 43,

                        semantic_preference: 0.5,
                        type: 'level2',
                        // type: selectedLevel,
                    }
                    if (row.quick_search_query && fromSaved.length === 0) {
                        payload.query = row.quick_search_query
                    }

                    if (fromSaved && fromSaved.length !== 0) {
                        payload.non_entity_query = fromSaved[0]
                    }

                    axios
                        .post('/semantic/quick-search/', payload)
                        .then(res => {
                            setBasicSearchResponse({
                                total: res.data.total,
                                next: res.data.next,
                                data: res.data.data,
                            })
                        })
                        .catch(err => console.log(err))
                        .finally(() => {
                            setIsLoading(false)
                        })
                }, 100)
            } else {
                setTimeout(() => {
                    setIsLoading(true)
                    setBasicSearchResponse({ data: [], next: null })
                    setBasicSearchDrop(false)
                    setEditId(row.id)

                    setLoadMoreFilter({ value: null, type: null })

                    const payload = {
                        filters: {
                            source: {
                                operator: 'IS',
                                values: [],
                            },
                            countries: {
                                operator: 'IS',
                                values: [],
                            },
                            articleDate: {
                                operator: 'BETWEEN',
                                from: selectedDatefrom,
                                to: selectedDateTo,
                            },
                            sectors: {
                                operator: 'IS',
                                values: [],
                            },
                            company: {
                                operator: 'IS',
                                values: [],
                            },
                            category: {
                                operator: 'IS',
                                values: [],
                            },
                            topics: {
                                operator: 'IS',
                                values: [],
                            },
                        },
                        relevance_cutoff:
                            selectedOption === '1' || selectedOption === '0' || selectedOption === '6'
                                ? 3
                                : selectedOption === '29'
                                ? 13
                                : selectedOption === '59'
                                ? 23
                                : selectedOption === '89'
                                ? 33
                                : 43,

                        semantic_preference: 0.5,
                        type: 'level2',
                        // type: selectedLevel,
                    }
                    if (row.quick_search_query) {
                        payload.query = row.quick_search_query
                    }

                    axios
                        .post('/semantic/quick-search/', payload)
                        .then(res => {
                            setBasicSearchResponse({
                                total: res.data.total,
                                next: res.data.next,
                                data: res.data.data,
                            })
                        })
                        .catch(err => console.log(err))
                        .finally(() => {
                            setIsLoading(false)
                        })
                }, 100)
            }
        } else {
            setIsLoading(true)

            setSubTab(1)
            setEditId(row.id)
            // setEditQueryName(value.query_name)

            if (row.quick_search_query === null && row.query && row.query.filters) {
                const populate = row.query.filters
                const regionfromSaved = populate.countries.values.map(val => val)
                const companyfromSaved = populate.company.values.map(val => val)
                const topicFromSaved = populate.topics.values.map(val => val)
                const categoryFromSaved = populate.category.values.map(val => val)
                const sectorsFromSaved = populate.sectors.values.map(val => val)
                const sourcesFromSaved = populate.source.values.map(val => val)
                const savedNewsContent = populate.newsContent.regex
                const savedRegion = allRegions.filter(val => regionfromSaved.includes(val.label))
                const savedCompany = allCompanies.filter(val => companyfromSaved.includes(val.label))
                const savedTopic = allTopics.filter(val => topicFromSaved.includes(val.label))
                const savedCategory = allSubCategories.filter(val => categoryFromSaved.includes(val.label))
                const savedSectors = allIndustries.filter(val => sectorsFromSaved.includes(val.label))
                const savedSources = allSources.filter(val => sourcesFromSaved.includes(val.value.toUpperCase()))

                setSelectedQueries({
                    ...selectedQueries,
                    articleDateFrom: populate.articleDate.from,
                    articleOperator: populate.articleDate.operator,
                    articleDateTo: populate.articleDate.to,
                    region: savedRegion,
                    regionOperator: populate.countries.operator,
                    sectors: savedSectors,
                    industryOperator: populate.sectors.operator,
                    company: savedCompany,
                    companyOperator: populate.company.operator,
                    category: savedCategory,
                    categoryOperator: populate.category.operator,
                    topic: savedTopic,
                    topicOperator: populate.topics.operator,
                    newsTitle: populate.newsTitle.value,
                    newsTitleOperator: populate.newsTitle.operator,
                    newsContent: savedNewsContent.substring(0, savedNewsContent.length - 1),
                    newsContentOperator: populate.newsContent.operator,
                    source: savedSources,
                    sourceOperator: populate.source.operator,
                })

                setTimeout(() => {
                    const payLoad = {
                        filters: {
                            source: {
                                operator: populate.source.operator,
                                values: savedSources.map(val => val.value),
                            },
                            articleDate: {
                                operator: populate.articleDate.operator,
                                from: populate.articleDate.from !== '' ? populate.articleDate.from : getYMD(startDate),
                                to: populate.articleDate.to !== '' ? populate.articleDate.to : getYMD(new Date()),
                            },
                            countries: {
                                operator: populate.countries.operator,
                                values: savedRegion.map(value => value.label),
                            },
                            sectors: {
                                operator: populate.sectors.operator,
                                values: savedSectors.map(value => value.label),
                            },
                            company: {
                                operator: populate.company.operator,
                                values: savedCompany.map(value => value.label),
                            },
                            category: {
                                operator: populate.category.operator,
                                values: savedCategory.map(value => value.label),
                            },
                            topics: {
                                operator: populate.topics.operator,
                                values: savedTopic.map(value => value.label),
                            },
                            newsTitle: {
                                operator: selectedQueries.newsTitleOperator,
                                value: populate.newsTitle.value,
                                fuzziness: 3,
                            },
                            newsContent: {
                                operator: populate.newsContent.operator,
                                regex: savedNewsContent.substring(0, savedNewsContent.length - 1),
                            },
                        },
                        search_type: 'advanced',
                        next: 0, // For Next Batch
                        size: 15, // Batch size
                    }
                    axios
                        .post(`/news/level2/search/`, payLoad)
                        .then(res => {
                            setAdvanceResponse(res)
                        })
                        .catch(err => console.log(err))
                        .finally(() => {
                            setIsLoading(false)
                        })
                }, 1000)
            } else {
                setIsLoading(false)
            }
        }
    }

    const alertcolumns = [
        {
            name: <label style={{ color: 'white' }}>Alert Name</label>,
            cell: row => row.query_name,
            width: '40%',
        },
        {
            name: <label style={{ color: 'white' }}>Status</label>,
            cell: row =>
                row.scheduled ? (
                    <span className="d-flex align-items-center" style={{ color: '#077D55', marginLeft: '-10px' }}>
                        <span>
                            <ToggleOnIcon style={{ fontSize: 'x-large' }} />
                        </span>
                        <span style={{ marginLeft: '5px' }}>Enabled</span>
                    </span>
                ) : (
                    <span className="d-flex align-items-center" style={{ color: 'lightgray', marginLeft: '-10px' }}>
                        <span>
                            <ToggleOffIcon style={{ fontSize: 'x-large' }} />
                        </span>
                        <span style={{ marginLeft: '5px' }}>Disabled</span>{' '}
                    </span>
                ),
            // width: '20%',
            textAlign: 'center',
        },
        {
            name: <label style={{ color: 'white' }}>Actions</label>,
            cell: row => (
                <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: '#186ADE',
                            cursor: 'pointer',
                            fontSize: '14px',
                            textDecoration: 'underline',
                        }}
                        onClick={() => {
                            EditQueries(row)
                        }}
                    >
                        Run/Edit
                    </span>
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: '#186ADE',
                            cursor: 'pointer',
                            fontSize: '14px',
                            textDecoration: 'underline',
                            marginLeft: '10px',
                        }}
                        onClick={() => scheduling(row)}
                    >
                        Scheduling Settings
                    </span>
                    <RiDeleteBin6Line
                        style={{
                            marginLeft: '10px',
                            color: '#FC3B3B',
                            cursor: 'pointer',
                            fontSize: '16px',
                            marginLeft: '10px',
                        }}
                        title="delete"
                        onClick={() => {
                            setDeleteModalOpen(!deleteModalOpen)
                            setDeleteId({
                                id: row.id,
                                query: row.query_name,
                            })
                        }}
                    />
                </div>
            ),
        },
    ]

    const collectionsWithQueries = collectionQueries.filter(
        collection => collection.queries.filter(q => q.query_string === null).length > 0
    )

    const deleteSavedQuery = () => {
        axios
            .delete(`/news/search-query/${deleteId.id}/`)
            .then(res => {
                getCollection()
                toast('Query was successfully deleted.')
            })
            .catch(err => console.log(err))
            .finally(() => {
                setDeleteModalOpen(false)
            })
    }

    const deleteSavedCollection = () => {
        axios
            .delete(`/news/collection/${collectionId}/`)
            .then(res => {
                getAllCollections()
                getCollection()
                toast('Collection was successfully deleted.')
            })
            .catch(err => console.log(err))
            .finally(() => {
                setDeleteCollection(false)
            })
    }

    const [selectedCollection, setSelectedCollection] = useState(null)

    const handleCollectionClick = collectionId => {
        setSelectedCollection(collectionId)
    }

    const Collections = ({ collection, onCollectionClick }) => {
        const handleClick = () => {
            onCollectionClick(collection.id)
            setCollectionName(allCollectionName.filter(val => val.label === collection.name)[0])
            setCollectionDescription(collection.description)
            setCollectionPrivacy(collectionShareList.filter(val => val.value === collection.shared)[0])
            setCollectionId(collection.id)
            setCollectionUsers(users.filter(item => collection.users.includes(item.id)))
        }

        return (
            <div
                style={{
                    width: '100%',
                    padding: '15px 10px',
                    textAlign: 'left',
                    display: 'block',
                    background: 'white',
                    borderRadius: '0px',
                    textTransform: 'none',
                    borderTop: '1px solid lightgray',
                }}
            >
                <div className="d-flex align-items-center" style={{ fontSize: '13px' }}>
                    <span className="col-5" style={{ marginLeft: '10px' }}>
                        {collection.name}
                        <span>({collection.queries.length})</span>
                    </span>

                    <span className="col-4">
                        <span className="d-flex align-items-center" style={{ color: 'lightgray', marginLeft: '-10px' }}>
                            <span>
                                <ToggleOffIcon style={{ fontSize: 'x-large' }} />
                            </span>
                            <span style={{ marginLeft: '5px' }}>Disabled</span>{' '}
                        </span>
                    </span>

                    <span className="col" style={{ textAlign: 'center', marginRight: '10%' }}>
                        <span>
                            <span
                                style={{
                                    textDecoration: 'underline',
                                    color: '#186ADE',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    textDecoration: 'underline',
                                }}
                                onClick={handleClick}
                            >
                                View
                            </span>
                            <span
                                style={{
                                    textDecoration: 'underline',
                                    color: collection.is_editable ? '#186ADE' : 'lightgray',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    textDecoration: 'underline',
                                    marginLeft: '10px',
                                }}
                                onClick={() => {
                                    if (collection.is_editable) {
                                        setCollectionName(collection.name)
                                        setCollectionDescription(collection.description)
                                        setCollectionPrivacy(
                                            collectionShareList.filter(val => val.value === collection.shared)[0]
                                        )
                                        setCollectionId(collection.id)
                                        setCollectionUsers(users.filter(item => collection.users.includes(item.id)))
                                        setEditCollection(!editCollection)
                                    }
                                }}
                            >
                                Edit
                            </span>
                        </span>

                        <span>
                            <span
                                onClick={() => {
                                    setCollectionName(collection.name)
                                    setCollectionId(collection.id)
                                    setDeleteCollection(!deleteCollection)
                                }}
                            >
                                <RiDeleteBin6Line
                                    style={{
                                        color: '#FC3B3B',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        marginLeft: '10px',
                                    }}
                                    className={'delete_action_btn'}
                                    title="delete"
                                />{' '}
                            </span>
                        </span>
                    </span>
                </div>
            </div>
        )
    }

    const QueryTable = ({ queries }) => {
        const columns = [
            {
                // name: <label>Name</label>,
                cell: row => row.query_name,
                width: '40%',
            },
            // {
            //     name: <label>Type</label>,
            //     cell: row =>
            //         // row.scheduled ? (
            //         //     <span className="d-flex align-items-center" style={{ color: '#6ED120', marginLeft: '-10px' }}>
            //         //         <span>
            //         //             <ToggleOnIcon style={{ fontSize: 'x-large' }} />
            //         //         </span>
            //         //         <span style={{ marginLeft: '5px' }}>Enabled</span>
            //         //     </span>
            //         // ) : (
            //         //     <span className="d-flex align-items-center" style={{ color: 'lightgray', marginLeft: '-10px' }}>
            //         //         <span>
            //         //             <ToggleOffIcon style={{ fontSize: 'x-large' }} />
            //         //         </span>
            //         //         <span style={{ marginLeft: '5px' }}>Disabled</span>{' '}
            //         //     </span>
            //         // ),
            //         row.query_string === null && row.quick_search_query === null ? (
            //             <span>Advanced Search</span>
            //         ) : (
            //             <span />
            //         ),
            //     // width: '20%',
            //     textAlign: 'center',
            // },
            {
                cell: row =>
                    row.quick_search_query !== null ? (
                        <span />
                    ) : (
                        <span
                            className="d-flex align-items-center"
                            style={{
                                color: 'rgb(57, 150, 218)',
                                background: 'rgb(178 210 233)',
                                padding: '5px',
                                marginLeft: '-10px',
                                borderRadius: '25px',
                            }}
                        >
                            Advanced Search
                        </span>
                    ),
                // width: '20%',
                textAlign: 'center',
            },
            {
                // name: <label>Actions</label>,
                cell: row => (
                    <div style={{ cursor: 'pointer' }}>
                        <span className="col">
                            <>
                                <span
                                    style={{
                                        textDecoration: 'underline',
                                        color: '#186ADE',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        // marginLeft: '10px',
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() => {
                                        EditQueries(row)
                                    }}
                                >
                                    Run/Edit
                                </span>
                                {/* <span
                                    style={{
                                        textDecoration: 'underline',
                                        color: 'rgb(57, 150, 218)',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        marginLeft: '10px',
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() => scheduling(row)}
                                >
                                    Schedule Settings
                                </span> */}
                                <RiDeleteBin6Line
                                    style={{
                                        marginLeft: '10px',
                                        color: '#FC3B3B',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        marginLeft: '10px',
                                    }}
                                    className="delete_action_btn"
                                    onClick={() => {
                                        setDeleteModalOpen(!deleteModalOpen)
                                        setDeleteId({
                                            id: row.id,
                                            query: row.query_name,
                                        })
                                    }}
                                    title="delete"
                                />
                            </>
                        </span>
                    </div>
                ),
            },
        ]

        return (
            <div className="collection_queries">
                <DataTable style={{ marginTop: '15px' }} columns={columns} data={queries} noHeader={true} />
            </div>
        )
    }

    return (
        <BaseScreen>
            <>
                {!EditPage ? (
                    <>
                        {!isLoader ? (
                            <>
                                <div className="tables">
                                    {!createNews ? (
                                        <>
                                            {collectionsWithQueries.length === 0 && (
                                                <div className="d-flex justify-content-center bg-white">
                                                    <p style={{ marginTop: '20px' }}>There are no records to display</p>
                                                </div>
                                            )}
                                            {selectedCollection === null && collectionsWithQueries.length !== 0 && (
                                                <div style={{ padding: '20px 30px 0px 30px', margin: 'auto' }}>
                                                    <div
                                                        className="d-flex align-items-center p-3 text-white"
                                                        style={{ background: '#00193C' }}
                                                    >
                                                        <span className="col-5" style={{ marginLeft: '15px' }}>
                                                            <label style={{ color: 'white' }}>Title</label>
                                                        </span>

                                                        <span className="col-4">
                                                            <label style={{ color: 'white' }}>Status</label>
                                                        </span>

                                                        <span
                                                            className="col"
                                                            style={{ textAlign: 'end', marginRight: '15%' }}
                                                        >
                                                            <label style={{ color: 'white' }}>Actions</label>
                                                        </span>
                                                    </div>
                                                    {collectionsWithQueries.map((collection, index) => (
                                                        <Collections
                                                            key={index}
                                                            collection={collection}
                                                            onCollectionClick={handleCollectionClick}
                                                        />
                                                    ))}

                                                    <Button
                                                        variant="contained"
                                                        endIcon={<AddIcon />}
                                                        style={{
                                                            background: '#186ADE',
                                                            borderRadius: '4px',
                                                            width: 'max-content',
                                                            // marginRight: '40px',
                                                            marginTop: '20px',
                                                            fontSize: '14px',
                                                            height: '40px',
                                                            color: 'white',
                                                            boxShadow: 'none',
                                                            textTransform: 'none',
                                                        }}
                                                        onClick={() => {
                                                            setCreateNews(true)
                                                        }}
                                                    >
                                                        Create Newsletter
                                                    </Button>
                                                </div>
                                            )}

                                            {selectedCollection && (
                                                <>
                                                    <div style={{ marginTop: '20px' }}>
                                                        <div>
                                                            <ArrowBackIcon
                                                                onClick={() => {
                                                                    setSelectedCollection(null)
                                                                }}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                            <span>
                                                                <label style={{ marginLeft: '10px' }}>
                                                                    Newsletters /{' '}
                                                                    {
                                                                        collectionsWithQueries
                                                                            .filter(c => c.id === selectedCollection)
                                                                            .map(c => c.name)[0]
                                                                    }
                                                                </label>
                                                            </span>
                                                        </div>

                                                        <Tabs
                                                            value={collectionTab}
                                                            onChange={(event, newValue) => {
                                                                setCollectionTab(newValue)
                                                            }}
                                                            style={{ background: 'white', marginTop: '20px' }}
                                                        >
                                                            <Tab label="Searches" style={{ marginLeft: '20px' }} />
                                                            <Tab label="Schedule Settings" disabled />
                                                        </Tabs>
                                                        {collectionTab === 0 && (
                                                            <>
                                                                <div style={{ width: '95%', margin: 'auto' }}>
                                                                    <QueryTable
                                                                        queries={
                                                                            collectionsWithQueries.find(
                                                                                c => c.id === selectedCollection
                                                                            ).queries
                                                                        }
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        {collectionTab === 1 && 'Test'}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex align-items-center justify-content-center">
                                                {createNewsTab !== null && (
                                                    <Button
                                                        onClick={() => {
                                                            createNewsTab === 0
                                                                ? setCreateNewsTab(null)
                                                                : setCreateNewsTab(prevActiveStep => prevActiveStep - 1)
                                                        }}
                                                        startIcon={<MdNavigateBefore />}
                                                        style={{
                                                            background: '#186ADE',
                                                            borderRadius: '4px',
                                                            width: 'max-content',
                                                            // marginRight: '40px',
                                                            marginTop: '20px',
                                                            fontSize: '14px',
                                                            height: '40px',
                                                            color: 'white',
                                                            boxShadow: 'none',
                                                            textTransform: 'none',
                                                            margin: 'auto',
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        Previous
                                                    </Button>
                                                )}
                                                <Steppers activeStep={createNewsTab} isNewsLetters={true} />
                                                {createNewsTab !== null && (
                                                    <Button
                                                        endIcon={<MdNavigateNext />}
                                                        style={{
                                                            background: '#186ADE',
                                                            borderRadius: '4px',
                                                            width: 'max-content',
                                                            // marginRight: '40px',
                                                            marginTop: '20px',
                                                            fontSize: '14px',
                                                            height: '40px',
                                                            color: 'white',
                                                            boxShadow: 'none',
                                                            textTransform: 'none',
                                                            margin: 'auto',
                                                            display: 'flex',
                                                        }}
                                                        onClick={() => {
                                                            if (createNewsTab === 0) {
                                                                setCreateNewsTab(prevActiveStep => prevActiveStep + 1)
                                                            }
                                                            if (createNewsTab === 1) {
                                                                setCreateNewsTab(prevActiveStep => prevActiveStep + 1)
                                                            }
                                                        }}
                                                    >
                                                        Next
                                                    </Button>
                                                )}
                                            </div>
                                            {createNewsTab !== null ? (
                                                <>
                                                    <div style={{ padding: '20px 10px 10px 30px' }}>
                                                        {createNewsTab === 0 && (
                                                            <>
                                                                <div>
                                                                    <label>Newsletter Name</label>
                                                                    <br />
                                                                    <TextField
                                                                        value={collectionName}
                                                                        onChange={e => {
                                                                            setCollectionName(e.target.value)
                                                                        }}
                                                                        // label="Title"
                                                                        placeholder="Newsletter Name"
                                                                        variant="outlined"
                                                                        sx={{ width: '450px', marginTop: '10px' }}
                                                                        size="small"
                                                                    />
                                                                </div>

                                                                <div style={{ marginTop: '20px' }}>
                                                                    <label> Description</label>
                                                                    <br />
                                                                    <TextField
                                                                        value={collectionDescription}
                                                                        onChange={e => {
                                                                            setCollectionDescription(e.target.value)
                                                                        }}
                                                                        // label="Newsletter Description"
                                                                        placeholder="Newsletter Description"
                                                                        variant="outlined"
                                                                        sx={{ width: '450px', marginTop: '10px' }}
                                                                        size="small"
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        {createNewsTab === 1 && <TextField />}
                                                        {createNewsTab === 2 && <TextField />}
                                                    </div>
                                                </>
                                            ) : (
                                                <Button
                                                    style={{
                                                        background: '#186ADE',
                                                        borderRadius: '4px',
                                                        width: 'max-content',
                                                        // marginRight: '40px',
                                                        marginTop: '20px',
                                                        fontSize: '14px',
                                                        height: '40px',
                                                        color: 'white',
                                                        boxShadow: 'none',
                                                        textTransform: 'none',
                                                        margin: 'auto',
                                                        display: 'flex',
                                                    }}
                                                    onClick={() => {
                                                        setCreateNewsTab(0)
                                                    }}
                                                >
                                                    Start
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100vh',
                                    width: '100vw',
                                }}
                            >
                                <Circles key={0} type="Circles" color="#adadad" height={80} width={80} />
                            </div>
                        )}

                        {deleteModalOpen && (
                            <DeleteNews
                                deleteModalOpen={deleteModalOpen}
                                queryName={deleteId.query}
                                setDeleteModalOpen={setDeleteModalOpen}
                                savedQueryDelete={true}
                                handleBatchDeleteStories={deleteSavedQuery}
                            />
                        )}
                        {deleteCollection && (
                            <DeleteNews
                                deleteModalOpen={deleteCollection}
                                queryName={collectionName}
                                setDeleteModalOpen={setDeleteCollection}
                                savedQueryDelete={true}
                                handleBatchDeleteStories={deleteSavedCollection}
                            />
                        )}
                        <Modal
                            isOpen={editCollection}
                            toggle={() => {
                                setEditCollection(!editCollection)
                            }}
                        >
                            <div
                                style={{ padding: '10px', background: 'black' }}
                                className="d-flex justify-content-between"
                            >
                                <h3 style={{ paddingLeft: '2%', color: 'white', fontWeight: 600 }}>Edit Collection</h3>

                                <span
                                    style={{ paddingRight: '2%', cursor: 'pointer' }}
                                    onClick={() => {
                                        setEditCollection(!editCollection)
                                    }}
                                >
                                    <CancelIcon style={{ color: 'white' }} />
                                </span>
                            </div>
                            <ModalBody>
                                <TextField
                                    value={collectionName}
                                    onChange={e => {
                                        setCollectionName(e.target.value)
                                    }}
                                    label="Title"
                                    variant="outlined"
                                    sx={{ width: '450px', marginTop: '20px' }}
                                />

                                <TextField
                                    value={collectionDescription}
                                    onChange={e => {
                                        setCollectionDescription(e.target.value)
                                    }}
                                    label="Description"
                                    variant="outlined"
                                    sx={{ width: '450px', marginTop: '20px' }}
                                />
                                <Autocomplete
                                    sx={{ width: '450px', marginTop: '20px' }}
                                    options={collectionShareList}
                                    autoHighlight
                                    value={collectionPrivacy}
                                    getOptionLabel={option => option.label}
                                    // renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            style={{ borderRadius: '10px' }}
                                            variant="outlined"
                                            label="Privacy"
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        if (value !== null) {
                                            setCollectionPrivacy(value)
                                        }
                                    }}
                                />
                                {collectionPrivacy && collectionPrivacy.value === false && (
                                    <Autocomplete
                                        multiple
                                        sx={{ width: '450px', marginTop: '20px' }}
                                        options={users}
                                        autoHighlight
                                        value={collectionUsers}
                                        getOptionLabel={option => option.email}
                                        renderOption={(props, option) => <li {...props}>{option.email}</li>}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                // sx={{ height: '40px' }}
                                                style={{ borderRadius: '10px' }}
                                                variant="outlined"
                                                label="Users"
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            setCollectionUsers(value)
                                        }}
                                    />
                                )}
                                <div
                                    style={{
                                        display: 'flex',
                                        margin: 'auto',
                                        paddingTop: '20px',
                                        marginLeft: '269px',
                                    }}
                                >
                                    <Button
                                        className="Cancel_btn"
                                        onClick={() => {
                                            setEditCollection(false)
                                        }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            const payload = {
                                                name: collectionName,
                                                description: collectionDescription,
                                                shared: collectionPrivacy.value,
                                                users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
                                            }
                                            axios
                                                .put(`/news/collection/${collectionId}/`, payload)
                                                .then(res => {
                                                    getAllCollections()
                                                    setEditCollection(false)
                                                    toast('Collection successfully updated')
                                                    clearFilters()
                                                    getCollection()
                                                })
                                                .catch(err => console.log(err))
                                        }}
                                        className="save_btn"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </ModalBody>
                        </Modal>
                        <Modal
                            isOpen={iframeReport}
                            toggle={() => {
                                setIframeReport(!iframeReport)
                            }}
                            style={{ height: '100%' }}
                            size="lg"
                            className="pdf_viewer"
                        >
                            <ModalBody style={{ height: '100%' }}>
                                <div class="container">
                                    <iframe title="PDF Viewer" src={documents} />
                                </div>
                            </ModalBody>
                        </Modal>
                        {quickReportModal && (
                            <QuickReportPreview
                                quickReportModal={quickReportModal}
                                setQuickReportModal={setQuickReportModal}
                                jsonURLID={documents}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <div className="" style={{}}>
                            <div className="">
                                <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{ fontSize: '20px', paddingLeft: '10px', fontWeight: 700 }}
                                >
                                    <span>
                                        <ArrowBackIcon
                                            onClick={() => {
                                                clearFilters()
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        />

                                        <span style={{ marginLeft: '10px' }}>
                                            <label style={{ fontSize: '18px' }}>{editQuery.query_name}</label>
                                        </span>
                                    </span>

                                    <div>
                                        <Button
                                            variant="contained"
                                            style={{
                                                background: '#186ADE',
                                                borderRadius: '4px',
                                                width: 'max-content',
                                                marginRight: '40px',
                                                fontSize: '14px',
                                                height: '40px',
                                                color: 'white',
                                                boxShadow: 'none',
                                                textTransform: 'none',
                                            }}
                                            onClick={() => {
                                                if (subTab === 0) {
                                                    setSemanticSaveModal(true)
                                                } else {
                                                    setSaveModal(true)
                                                }
                                            }}
                                        >
                                            Save Search
                                        </Button>
                                    </div>
                                </div>

                                {subTab === 0 && (
                                    <>
                                        <div className="search_prompts m-3" style={{ padding: '0px 20px' }}>
                                            <label className="d-flex align-items-center">Search Prompt</label>

                                            <NewSearch
                                                basicSearch={weviateSearch}
                                                basicSearchInput={basicSearchInput}
                                                setBasicSearchInput={setBasicSearchInput}
                                                clearFilters={clearFilters}
                                                selectedOption={selectedOption}
                                                usertype={usertype}
                                                allCompanies={allCompanies}
                                                allTopics={allTopics}
                                                allSubCategories={allSubCategories}
                                                allIndustries={allIndustries}
                                                basicSearchDrop={basicSearchDrop}
                                                setBasicSearchDrop={setBasicSearchDrop}
                                                setSaveQueryName={setSaveQueryName}
                                                defaultData={defaultData}
                                                setDefaultData={setDefaultData}
                                            />

                                            {isLoading && (
                                                <div style={{ marginTop: '20px' }}>
                                                    <Circles
                                                        key={0}
                                                        type="Circles"
                                                        color="#adadad"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            )}

                                            {basicSearchResponse.data.length !== 0 &&
                                                basicSearchResponse.data.filter(
                                                    val =>
                                                        (val.title && val.title.length > 0) ||
                                                        (val.title && val.title !== '') ||
                                                        !val.title
                                                ).length > 0 && (
                                                    <>
                                                        <div style={{ width: '99%', marginTop: '15px' }}>
                                                            <div
                                                                style={{
                                                                    background: '#00193C',
                                                                    color: 'white',
                                                                    padding: '10px',
                                                                    borderRadius: '4px 4px 0px 0px',
                                                                    marginTop: '10px',
                                                                }}
                                                                className="d-flex align-items-center justify-content-between"
                                                            >
                                                                <div style={{ width: '52%' }}>
                                                                    <span style={{ marginLeft: '3%' }}>Title</span>
                                                                </div>
                                                                {basicSearchResponse.data[0].score && (
                                                                    <div style={{ width: '10%' }}>Relevance</div>
                                                                )}
                                                                <div
                                                                    style={{
                                                                        width: '15%',
                                                                        marginLeft: basicSearchResponse.data[0].score
                                                                            ? '2%'
                                                                            : '',
                                                                    }}
                                                                >
                                                                    Source
                                                                </div>
                                                                <div style={{ width: '15%', marginRight: '1%' }}>
                                                                    Date
                                                                </div>
                                                                <div />
                                                            </div>
                                                            <Grid item>
                                                                <InfiniteScroll
                                                                    pageStart={0}
                                                                    loadMore={handleLoadQuick}
                                                                    hasMore={
                                                                        basicSearchResponse.next <
                                                                        basicSearchResponse.total
                                                                    }
                                                                    loader={
                                                                        basicSearchResponse.next !== null && (
                                                                            <Circles
                                                                                key={0}
                                                                                type="Circles"
                                                                                color="#adadad"
                                                                                height={80}
                                                                                width={80}
                                                                            />
                                                                        )
                                                                    }
                                                                >
                                                                    {basicSearchResponse.data
                                                                        // .filter(story => {
                                                                        //     const startDate = new Date()
                                                                        //     startDate.setDate(
                                                                        //         startDate.getDate() - dateFilter
                                                                        //     )

                                                                        //     const filterDate = getYMD(startDate)
                                                                        //     const storyDate = story.date.split('T')[0]
                                                                        //     const dateCondition = storyDate > filterDate

                                                                        //     const isMultipleSources =
                                                                        //         newFilterSource.length > 0

                                                                        //     const sourceCondition = isMultipleSources
                                                                        //         ? newFilterSource.includes(
                                                                        //               story.source_name
                                                                        //           )
                                                                        //         : true

                                                                        //     return dateCondition && sourceCondition
                                                                        // })
                                                                        .sort((a, b) =>
                                                                            a.date.split('T')[0] > b.date.split('T')[0]
                                                                                ? -1
                                                                                : 1
                                                                        )
                                                                        .map(story => {
                                                                            return (
                                                                                <NewSearchQueryResult
                                                                                    key={story.id}
                                                                                    EachrawNews={story}
                                                                                    isBasicSearch={true}
                                                                                    // selectedStories={selectedStories}
                                                                                    isStorySelected={false}
                                                                                    // addToSelection={addToSelection}
                                                                                    allCategories={allCategories}
                                                                                    allSubCategories={allSubCategories}
                                                                                    allIndustries={allIndustries}
                                                                                    allCompanies={allCompanies}
                                                                                    setAllCompanies={setAllCompanies}
                                                                                    allRegions={allRegions}
                                                                                    allLinkedInGroups={[]}
                                                                                    // removeFromSelection={
                                                                                    //     removeFromSelection
                                                                                    // }
                                                                                    allTopics={allTopics}
                                                                                    collapsed={!expandedView}
                                                                                    usertype={usertype}
                                                                                    // selectAll={isAllSelected}
                                                                                    currentPath={currentPath}
                                                                                    // selectedNewsOnly={selectedNewsOnly}
                                                                                    isScore={true}
                                                                                    // selectedLevel={selectedLevel}
                                                                                    isAssisstant={true}
                                                                                />
                                                                            )
                                                                        })}
                                                                </InfiniteScroll>
                                                            </Grid>
                                                        </div>
                                                    </>
                                                )}
                                        </div>
                                    </>
                                )}
                                {subTab === 1 && (
                                    <>
                                        <NewAdvanceSearchTab
                                            advanceResponse={advanceResponse}
                                            setAdvanceResponse={setAdvanceResponse}
                                            expandedView={expandedView}
                                            setExpandedView={setExpandedView}
                                            editId={editId}
                                            setEditId={setEditId}
                                            editqueryName={editqueryName}
                                            setEditQueryName={setEditQueryName}
                                            directRun={directRun}
                                            setDirectRun={setDirectRun}
                                            allCategories={allCategories}
                                            allLinkedInGroups={[]}
                                            usertype={usertype}
                                            isLoading={isLoading}
                                            handleLoadMore={handleLoadMore}
                                            selectedQueries={selectedQueries}
                                            setSelectedQueries={setSelectedQueries}
                                            allSources={allSources}
                                            setAllSources={setAllSources}
                                            startDate={startDate}
                                            allRegions={allRegions}
                                            setAllRegions={setAllRegions}
                                            allTopics={allTopics}
                                            setAllTopics={setAllTopics}
                                            allCompanies={allCompanies}
                                            setAllCompanies={setAllCompanies}
                                            allIndustries={allIndustries}
                                            setAllIndustries={setAllIndustries}
                                            allSubCategories={allSubCategories}
                                            advanceSearch={advanceSearch}
                                            clearFilters={clearFilters}
                                            setAllSubCategories={setAllSubCategories}
                                            regionHierarchy={regionHierarchy}
                                            setRegionHierarchy={setRegionHierarchy}
                                            currentPath={currentPath}
                                            selectedCompanies={selectedCompanies}
                                            setSelectedCompanies={setSelectedCompanies}
                                            selectedSection={selectedSection}
                                            setSelectedSection={setSelectedSection}
                                            selectedUsers={selectedUsers}
                                            setSelectedUsers={setSelectedUsers}
                                            emailSubject={emailSubject}
                                            setEmailSubject={setEmailSubject}
                                            includeBusiness={includeBusiness}
                                            setIncludeBusiness={setIncludeBusiness}
                                            includeSection={includeSection}
                                            setIncludeSection={setIncludeSection}
                                            selectedLevel={selectedLevel}
                                            setSelectedLevel={setSelectedLevel}
                                            collectionDescription={collectionDescription}
                                            setCollectionDescription={setCollectionDescription}
                                            collectionShared={collectionShared}
                                            setCollectionShared={setCollectionShared}
                                            allCollectionUsers={allCollectionName}
                                            collectionUsers={collectionUsers}
                                            setCollectionUsers={setCollectionUsers}
                                            collectionName={collectionName}
                                            setCollectionName={setCollectionName}
                                            allCollectionName={allCollectionName}
                                            saveQueryName={saveQueryName}
                                            setSaveQueryName={setSaveQueryName}
                                            collectionModal={collectionModal}
                                            setCollectionModal={setCollectionModal}
                                            collectionPrivacy={collectionPrivacy}
                                            setCollectionPrivacy={setCollectionPrivacy}
                                            collectionShareList={collectionShareList}
                                            collectionId={collectionId}
                                            getAllCollections={getAllCollections}
                                            isScore={true}
                                            // dateFilter={setDateFilter}
                                            // newFilterSource={newFilterSource}
                                            // setNewsFilters={setNewsFilters}
                                            // sortBy={sortBy}
                                            // setSortBy={setSortBy}
                                            // setActiveStep={setActiveStep}
                                            setIsLoading={setIsLoading}
                                            selectedStories={selectedStories}
                                            setSelectedStories={setSelectedStories}
                                            isMyRequest={true}
                                            saveModal={saveModal}
                                            setSaveModal={setSaveModal}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
                <Modal
                    isOpen={scheduleModel}
                    toggle={() => {
                        setScheduleModal(!scheduleModel)
                    }}
                    size="lg"
                >
                    <div
                        style={{ padding: '20px 10px 0px 10px' }}
                        className="d-flex justify-content-between align-items-center"
                    >
                        <h3 style={{ paddingLeft: '2%', fontWeight: 600 }}>Scheduling NewsLetter</h3>

                        <span
                            style={{ paddingRight: '2%', cursor: 'pointer' }}
                            onClick={() => {
                                setScheduleModal(!scheduleModel)
                            }}
                        >
                            <CancelIcon />
                        </span>
                    </div>
                    <hr />
                    <ModalBody style={{ padding: '0px 10px' }}>
                        <div
                            style={{
                                width: '320px',
                                border: '1px solid lightgray',
                                height: '50px',
                                marginLeft: '10px',
                                borderRadius: '4px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div className="form-check form-switch" style={{ marginLeft: '10px' }}>
                                <input
                                    style={{ cursor: 'pointer' }}
                                    title="enabled/disabled of scheduling"
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    value={isEnabled}
                                    checked={isEnabled ? true : false}
                                    onChange={e => {
                                        setIsEnabled(current => !current)
                                    }}
                                />
                                <label className="form-check-label text-black" htmlFor="flexSwitchCheckChecked">
                                    {isEnabled ? 'Scheduling Enabled' : 'Scheduling Disabled'}
                                </label>
                            </div>
                        </div>

                        <div className="d-flex" style={{ padding: '10px' }}>
                            <div>
                                <div style={{ marginTop: '5px' }}>
                                    <TextField
                                        disabled={isEnabled ? false : true}
                                        sx={{ width: '320px' }}
                                        multiline
                                        rows={3}
                                        value={emailSubject}
                                        onChange={e => {
                                            setEmailSubject(e.target.value)
                                        }}
                                        variant="outlined"
                                        label="Email subject"
                                    />
                                </div>
                                <div style={{ marginTop: '15px' }}>
                                    <Autocomplete
                                        disabled={isEnabled ? false : true}
                                        sx={{ width: '320px' }}
                                        options={templates}
                                        autoHighlight
                                        value={selectedTemplate}
                                        getOptionLabel={option => option.label}
                                        renderOption={(props, option) => (
                                            <li {...props}>
                                                <img
                                                    src={option.imageUrl}
                                                    alt={option.label}
                                                    style={{ width: '24px', marginRight: '8px' }}
                                                />
                                                {option.label}
                                            </li>
                                        )}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                sx={{ height: '40px' }}
                                                style={{ height: 40 }}
                                                label="Choose a newsletter"
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            setSelectedTemplate(value)
                                            if (value === null || value.has_sections === false) {
                                                setIncludeSection(false)
                                            }
                                        }}
                                    />
                                </div>

                                {/* Includes Sections and Business Events */}
                                <div style={{ marginTop: '25px' }}>
                                    <div className="form-check form-switch">
                                        <label className="form-check-label text-black">
                                            {'Include Business Events in the Newsletter'}
                                        </label>
                                        <input
                                            style={{ cursor: 'pointer' }}
                                            title="include/exclude of business events in the newsletter"
                                            className="form-check-input"
                                            type="checkbox"
                                            value={includeBusiness}
                                            checked={includeBusiness ? true : false}
                                            onChange={e => {
                                                setIncludeBusiness(current => !current)
                                            }}
                                            disabled={isEnabled ? false : true}
                                        />
                                    </div>
                                    {selectedTemplate && selectedTemplate.has_sections && (
                                        <>
                                            <div className="form-check form-switch">
                                                <label className="form-check-label text-black">
                                                    {'Include Section in the Newsletter'}
                                                </label>
                                                <input
                                                    style={{ cursor: 'pointer' }}
                                                    title="include/exlude of Section in the newsletter"
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={includeSection}
                                                    checked={includeSection ? true : false}
                                                    onChange={e => {
                                                        setIncludeSection(current => !current)
                                                    }}
                                                    disabled={isEnabled ? false : true}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>

                                {/* section */}
                                {selectedTemplate !== null && selectedTemplate.has_sections && includeSection && (
                                    <div style={{ marginTop: '10px' }}>
                                        <Autocomplete
                                            disabled={isEnabled ? false : true}
                                            sx={{ width: '320px' }}
                                            options={sections}
                                            autoHighlight
                                            aria-required={selectedTemplate.has_sections ? true : false}
                                            value={selectedSection}
                                            getOptionLabel={option => option.label}
                                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    sx={{ height: '40px' }}
                                                    style={{ height: 40 }}
                                                    label="Choose a section type"
                                                />
                                            )}
                                            onChange={(event, value) => {
                                                setSelectedSection(value)
                                                if (value === null) {
                                                    setIncludeSection(false)
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                {/* users */}
                                <div
                                    style={{
                                        marginTop: includeSection ? '25px' : '15px',
                                    }}
                                >
                                    <Autocomplete
                                        disabled={isEnabled ? false : true}
                                        sx={{ width: '320px' }}
                                        multiple
                                        options={users}
                                        value={selectedUsers}
                                        getOptionLabel={option => {
                                            if (option.label) {
                                                return option.label
                                            }

                                            return option.email
                                        }}
                                        onChange={(event, newValue) => {
                                            if (newValue && newValue.length) {
                                                const results = []

                                                newValue.forEach(value => {
                                                    if (typeof value === 'string') {
                                                        function ValidateEmail(mail) {
                                                            return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                                                mail
                                                            )
                                                        }

                                                        if (!ValidateEmail(value)) {
                                                            toast.error(
                                                                `You have entered an invalid email address! ${value}`
                                                            )
                                                            return
                                                        }

                                                        return results.push({
                                                            email: value,
                                                        })
                                                    }

                                                    return results.push(value)
                                                })

                                                setSelectedUsers(results)
                                            }
                                        }}
                                        renderInput={params => (
                                            <TextField {...params} variant="outlined" label="Recipients" />
                                        )}
                                        renderTags={() => null}
                                        freeSolo
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params)
                                            const { inputValue } = params
                                            const isExisting = options.some(option => inputValue === option.email)
                                            if (inputValue !== '' && !isExisting) {
                                                filtered.push({
                                                    id: `Add email :   ${inputValue}`,
                                                    email: inputValue,
                                                })
                                            }

                                            return filtered
                                        }}
                                        renderOption={(props, option) => (
                                            <li
                                                {...props}
                                                style={{
                                                    color: option.email === userEmail ? '#197bbd' : '',
                                                    title: option.email === userEmail ? 'User' : 'exiting user',
                                                }}
                                            >
                                                <span>{option.label || option.email}</span>
                                                {usertype !== 'Regular User' && option.user_category === 'LiteUser' && (
                                                    <span
                                                        className="Lite"
                                                        style={{
                                                            marginLeft: '5px',
                                                            paddingLeft: '5px',
                                                            fontSize: 'small',
                                                            background: 'skyblue',
                                                            padding: '2px 5px',
                                                            borderRadius: '5px',
                                                        }}
                                                    >
                                                        Lite*
                                                    </span>
                                                )}
                                            </li>
                                        )}
                                    />
                                </div>
                            </div>

                            {selectedUsers.length > 0 && (
                                <div
                                    style={{
                                        marginTop: '-60px',
                                        marginLeft: '20px',
                                        padding: '10px',
                                        width: '400px',
                                        height:
                                            selectedTemplate !== null && selectedTemplate.has_sections
                                                ? '377px'
                                                : '300px',
                                        overflow: 'auto',
                                        border: '1px solid lightgray',
                                        borderRadius: '4px',
                                    }}
                                >
                                    <label style={{ fontSize: '16px' }}>Selected Users</label>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={1}
                                            style={{ marginTop: '5px', borderTop: '1px solid lightgray' }}
                                        >
                                            {selectedUsers.map(user => (
                                                <Grid item>
                                                    <Chip
                                                        key={user.id}
                                                        label={user.email}
                                                        style={{
                                                            background: typeof user.id === 'number' ? '' : '#cdc8c7',
                                                        }}
                                                        title={
                                                            typeof user.id === 'number'
                                                                ? 'Existing user'
                                                                : 'Custom user'
                                                        }
                                                        disabled={isEnabled ? false : true}
                                                        onDelete={() =>
                                                            setSelectedUsers(
                                                                selectedUsers.filter(
                                                                    currUser => currUser.id !== user.id
                                                                )
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </div>
                        <div className="d-flex justify-content-between" style={{ padding: '10px' }}>
                            <div className="cron_tab" style={{ marginLeft: '5px' }}>
                                <div style={{ marginTop: '10px' }}>
                                    <CronTab
                                        isEnabled={isEnabled}
                                        setIsEnabled={setIsEnabled}
                                        cronTabValue={cronTabValue}
                                        setCronTabValue={setCronTabValue}
                                    />
                                </div>
                            </div>
                        </div>

                        <ModalFooter style={{ padding: '10px' }}>
                            <Button
                                variant="contained"
                                endIcon={<AiOutlineSave style={{ fontSize: '18px' }} />}
                                style={{
                                    background: '#186ADE',
                                    borderRadius: '4px',
                                    width: 'max-content',
                                    marginRight: '40px',
                                    fontSize: '14px',
                                    height: '40px',
                                    color: 'white',
                                    boxShadow: 'none',
                                    textTransform: 'none',
                                }}
                                className="btn btn-primary"
                                onClick={() => {
                                    saveChanges(scheduleContent)
                                }}
                                title="save"
                            >
                                Save
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
                {semanticSaveModal && (
                    <SaveSearches
                        collectionDescription={collectionDescription}
                        setCollectionDescription={setCollectionDescription}
                        collectionShared={collectionShared}
                        setCollectionShared={setCollectionShared}
                        allCollectionUsers={allCollectionName}
                        collectionUsers={collectionUsers}
                        setCollectionUsers={setCollectionUsers}
                        collectionName={collectionName}
                        setCollectionName={setCollectionName}
                        allCollectionName={allCollectionName}
                        openModal={semanticSaveModal}
                        setOpenModal={setSemanticSaveModal}
                        editSemanticQuery={editSemanticQuery}
                        saveQueryName={saveQueryName}
                        setSaveQueryName={setSaveQueryName}
                        editqueryName={editqueryName}
                        setEditQueryName={setEditQueryName}
                        collectionModal={collectionModal}
                        setCollectionModal={setCollectionModal}
                        collectionPrivacy={collectionPrivacy}
                        setCollectionPrivacy={setCollectionPrivacy}
                        collectionShareList={collectionShareList}
                        isQuickSearch={true}
                        directRun={directRun}
                    />
                )}
            </>
        </BaseScreen>
    )
}
