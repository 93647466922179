import React, { PureComponent } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import { FiPackage, FiUsers, FiSettings, FiLogOut, FiLogIn } from 'react-icons/fi'
import { logoutUser } from '../../actions/AuthActions'
import { getTrans } from '../../utils'
import EnduserSidebar from './components/EnduserSidebar'
import AnalystSidebar from './components/AnalystSidebar'
import { setLoadingFalse } from '../../actions/utilActions'
import FirmAnalystSidebar from './components/FirmAnalystSidebar'
import EndUserAdminSidebar from './components/EndUserAdminSidebar'
import './Sidebar.css'
import Logo from '../Logo/Logo'

class Sidebar extends PureComponent {
    render() {
        const { className, user, logoutUser, isLoggedIn } = this.props

        return (
            <div
                className={`sidebar ${className}`}
                style={
                    user.user_type === 'Regular User' ||
                    user.user_type === 'analyst' ||
                    user.user_type === 'client_analyst' ||
                    user.user_type === 'Power User'
                        ? {
                              position: 'fixed',
                              height: '100vh',
                              top: 0,
                          }
                        : { position: 'sticky' }
                }
            >
                {/* MEDIA ONLY */}
                <div className="d-lg-none d-xl-none d-none">
                    {isLoggedIn ? (
                        <Button className="nav-item btn-block" color="link" onClick={logoutUser}>
                            <span className="sidebar-icon">
                                <FiLogOut className="nav-icon" fontSize="16px" />
                            </span>
                            <span>{getTrans('Log Out')}</span>
                        </Button>
                    ) : (
                        <Link to="/" className="nav-item">
                            <strong>{getTrans('Log in')}</strong>
                            <span className="sidebar-icon">
                                <FiLogIn className="nav-icon ml-2" fontSize="17px" />
                            </span>
                        </Link>
                    )}
                </div>
                {/* END MEDIA ONLY */}

                <div style={{ margin: '-10px 0px 10px 20px' }}>
                    <Logo
                        user={user}
                        white={user.subscription_type === 'Direct' && user.user_type === 'Regular User' ? true : false}
                    />
                </div>

                {user.user_type === 'Regular User' && <EnduserSidebar user={user} />}

                {user.user_type === 'analyst' && <AnalystSidebar urlPath={this.props.match.url} />}

                {user.user_type === 'client_analyst' && (
                    // <FirmAnalystSidebar user={user} urlPath={this.props.match.url} />
                    <AnalystSidebar urlPath={this.props.match.url} />
                )}

                {user.user_type === 'Power User' && (
                    <>
                        <EndUserAdminSidebar user={user} urlPath={this.props.match.url} />

                        {/* <NavLink to="/app/users" className="nav-item">
                            <span className="sidebar-icon">
                                {' '}
                                <FiUsers className="nav-icon" fontSize="16px" />
                            </span>
                            <span>{getTrans('Users')}</span>
                        </NavLink>
                        <NavLink to="/app/settings-details" className="nav-item">
                            <span className="sidebar-icon">
                                {' '}
                                <FiSettings className="nav-icon" fontSize="16px" />
                            </span>
                            <span>{getTrans('Settings')}</span>
                        </NavLink>
                        <NavLink to="/app/packages" className="nav-item">
                            <span className="sidebar-icon">
                                {' '}
                                <FiPackage className="nav-icon" fontSize="16px" />
                            </span>
                            <span>{getTrans('Packages')}</span>
                        </NavLink>
                        <NavLink to="/app/faq" className="nav-item">
                            <span>{getTrans('Faq')}</span>
                        </NavLink> */}
                    </>
                )}
                <Button
                    className="nav-item btn-block nav-item"
                    color="link"
                    style={{ position: 'fixed', bottom: '20px' }}
                    onClick={logoutUser}
                >
                    <span className="sidebar-icon">
                        <FiLogOut className="nav-icon" fontSize="16px" />
                    </span>
                    <span>{getTrans('Log Out')}</span>
                </Button>
            </div>
        )
    }
}

const mapDispatchToProps = {
    setLoadingFalse,
    logoutUser,
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        isLoggedIn: state.auth.isLoggedIn,
    }
}

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Sidebar)
