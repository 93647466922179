import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import { BiPlus, BiMinus } from 'react-icons/bi'
import { Autocomplete, Chip, Grid, TextField, createFilterOptions } from '@mui/material'
import { Tooltip } from 'reactstrap'
import InfoIcon from '@mui/icons-material/Info'
import ClearIcon from '@mui/icons-material/Clear'

// Transform hierarchical data into a flat list with parent-child relationships
const transformData = data => {
    const options = []
    data.forEach(region => {
        const regionOption = {
            value: region.id,
            label: region.name,
            type: 'industries',
            level: 0,
            parent: null,
        }
        options.push(regionOption)

        region.sectors.forEach(country => {
            const countryOption = {
                value: country.id,
                label: country.name,
                type: 'sectors',
                level: 1,
                parent: regionOption,
            }
            options.push(countryOption)

            country.topics.forEach(province => {
                const provinceOption = {
                    value: province.id,
                    label: province.name,
                    type: 'topics',
                    level: 2,
                    parent: countryOption,
                }
                options.push(provinceOption)
            })
        })
    })
    return options
}

export default function NewTopicsHierarchy(props) {
    const { isMypreference, regionHierarchy, selectedOptions, setSelectedOptions } = props
    const { tooltip, toggle } = props
    const [expanded, setExpanded] = useState({})
    const [inputValue, setInputValue] = useState('')
    const options = transformData(regionHierarchy)

    const getDefaultSelections = () => {
        const selectedLabels = !isMypreference
            ? [...selectedOptions.topic, ...selectedOptions.sectors].map(v => v.label)
            : selectedOptions

        const selected = options.filter(opt => selectedLabels.includes(opt.label))

        return selected
    }

    useEffect(() => {
        // Set the default selected options when the component mounts
        isMypreference
            ? setSelectedOptions(getDefaultSelections())
            : setSelectedOptions(prev => ({
                  ...prev,
                  topic: getDefaultSelections().filter(v => v.type === 'topics'),
                  sectors: getDefaultSelections().filter(v => v.type === 'industries' || v.type == 'sectors'),
              }))
    }, [regionHierarchy])

    // Toggle expansion for regions and countries
    const toggleExpand = option => {
        setExpanded(prev => {
            const newState = { ...prev }
            if (option.type === 'industries') {
                newState[option.value] = !newState[option.value]
            } else if (option.type === 'sectors') {
                newState[option.value] = !newState[option.value]
            }
            return newState
        })
    }

    // Handle option selection
    const handleSelect = selectedOption => {
        const updatedSelection = isMypreference
            ? [...selectedOptions]
            : [...selectedOptions.topic, ...selectedOptions.sectors]

        const isSelected = updatedSelection.some(option => option.value === selectedOption.value)

        if (isSelected) {
            const removeWithChildren = option => {
                const index = updatedSelection.findIndex(opt => opt.value === option.value)
                if (index > -1) {
                    updatedSelection.splice(index, 1)
                }
                const children = options.filter(opt => opt.parent && opt.parent.value === option.value)
                children.forEach(removeWithChildren)
            }
            removeWithChildren(selectedOption)
        } else {
            const addWithChildren = option => {
                updatedSelection.push(option)
                const children = options.filter(opt => opt.parent && opt.parent.value === option.value)
                children.forEach(addWithChildren)
            }
            addWithChildren(selectedOption)
        }

        // setSelectedOptions(updatedSelection)
        !isMypreference
            ? setSelectedOptions(prev => ({
                  ...prev,
                  topic: updatedSelection.filter(v => v.type === 'topics'),
                  sectors: updatedSelection.filter(v => v.type === 'industries' || v.type == 'sectors'),
              }))
            : setSelectedOptions(updatedSelection)
    }

    // Check if the option is already selected
    // const isChecked = option =>
    //     Array.isArray(selectedOptions.region || selectedOptions) && selectedOptions.region
    //         ? selectedOptions.region.some(selected => selected.value === option.value)
    //         : selectedOptions.some(selected => selected.value === option.value)

    const isChecked = option => {
        const mergeArray =
            selectedOptions.topic && selectedOptions.sectors
                ? [...selectedOptions.topic, ...selectedOptions.sectors]
                : []
        const selectionArray = isMypreference
            ? Array.isArray(selectedOptions) && selectedOptions
            : Array.isArray(mergeArray) && mergeArray

        // Check if the option is present in the selection array
        return selectionArray && selectionArray.some(selected => selected.value === option.value)
    }

    // const isChecked = option =>
    //     Array.isArray(selectedOptions) && selectedOptions
    //         ? selectedOptions.some(selected => selected.value === option.value)
    //         : isArrayOfObjects(selectedOptions) === false
    //         ? getDefaultSelections()
    //         : selectedOptions.some(selected => selected.value === option.value)

    // console.log('ischecked', isChecked)

    // Custom Option component with expand/collapse functionality
    const CustomOption = props => {
        const { data, innerRef, innerProps } = props
        const paddingLeft = `${data.level * 20}px` // Indentation for hierarchy
        const hasChildren =
            data.type === 'sectors' && options.some(opt => opt.parent && opt.parent.value === data.value)

        const isExpandable = data.type === 'industries' || hasChildren // Expand Regions and Countries with provinces
        const isExpanded = expanded[data.value] || false

        return (
            <div
                ref={innerRef}
                {...innerProps}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '5px 10px',
                    paddingLeft,
                    cursor: 'pointer',
                }}
                onClick={() => handleSelect(data)} // Trigger selection
            >
                <span>
                    <input
                        type="checkbox"
                        checked={isChecked(data)}
                        onChange={() => handleSelect(data)}
                        style={{ marginLeft: '5px' }}
                    />
                    <span style={{ marginLeft: '10px' }}>
                        {data.label} ({data.type})
                    </span>
                </span>
                {isExpandable && (
                    <span
                        onClick={e => {
                            e.stopPropagation() // Prevent triggering the select event
                            toggleExpand(data) // Expand/collapse
                        }}
                        style={{
                            cursor: 'pointer',
                            userSelect: 'none',
                        }}
                    >
                        {isExpanded ? <BiMinus /> : <BiPlus />}
                    </span>
                )}
            </div>
        )
    }

    // Filter options based on search input and expansion state
    const filteredOptions = options.filter(option => {
        // Case when there's no search input:
        if (!inputValue) {
            // Show regions, countries, and provinces based on their expanded state
            if (option.level === 0) return true // Show regions
            if (option.level === 1 && expanded[option.parent.value]) return true // Show countries if region is expanded
            if (option.level === 2 && expanded[option.parent.value]) return true // Show provinces if country is expanded
            return false
        }

        // Case when there is a search input:
        const matchesSearch = option.label.toLowerCase().includes(inputValue.toLowerCase())
        return matchesSearch
    })

    const isArrayOfObjects = array => {
        if (!Array.isArray(array)) return false // Ensure it's an array
        if (array.length === 0) return false
        return array.every(item => typeof item === 'object' && item !== null)
    }

    return (
        <div>
            <label className="d-flex align-items-center" style={{ fontSize: '16px', fontWeight: 400 }}>
                {isMypreference && <span>Topics</span>}
                {(window.location.pathname === '/app/preferences/' ||
                    window.location.pathname.includes('/app/admin/enduser/') ||
                    window.location.pathname.includes('app/admin/add-enduser')) && (
                    <>
                        <span style={{ marginLeft: '10px' }}>
                            <InfoIcon
                                id="user_topics"
                                style={{
                                    cursor: 'pointer',
                                    display: 'inline-block',
                                }}
                                className="tooltip_icon"
                            />
                            <Tooltip
                                placement="top-end"
                                isOpen={tooltip}
                                target="user_topics"
                                toggle={() => toggle('user_topics')}
                                className="custom-tooltip"
                            >
                                Start typing the name of topics of interest. Select the topics (or consider selecting a
                                similar topic) from dropdown
                                {/* or enter the full name of topic. */}
                            </Tooltip>
                        </span>
                    </>
                )}
            </label>
            <div className={isMypreference ? 'mt-2' : ''}>
                <Select
                    options={filteredOptions}
                    value={
                        isMypreference
                            ? // isArrayOfObjects(selectedOptions) === false
                              //     ? getDefaultSelections(selectedOptions)
                              //     :
                              selectedOptions
                            : [...selectedOptions.topic, ...selectedOptions.sectors]
                    }
                    onChange={newValue => {
                        !isMypreference
                            ? setSelectedOptions(prev => ({
                                  ...prev,
                                  topic: (newValue || []).filter(v => v.type === 'topics'),
                                  sectors: (newValue || []).filter(
                                      v => v.type === 'industries' || v.type === 'sectors'
                                  ),
                              }))
                            : setSelectedOptions(newValue)
                    }}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option: CustomOption }}
                    placeholder={
                        isMypreference ? 'Search and Select Topics of Interest' : 'Select Industry, Sector, or Topic'
                    }
                    filterOption={null}
                    onInputChange={value => setInputValue(value)}
                    className={isMypreference ? 'preference_add' : 'inputs_new'}
                    controlShouldRenderValue={isMypreference ? false : true}
                />
            </div>

            {isMypreference && (
                <Grid item>
                    <div
                        style={{
                            maxHeight: '100px',
                            overflowY: 'auto',
                            // border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '5px',
                        }}
                    >
                        <Grid container direction="row" spacing={1} style={{ marginTop: '5px' }}>
                            {selectedOptions.map(user => (
                                <Grid item key={user.value}>
                                    <Chip
                                        label={user.label}
                                        style={{
                                            background: '#D4E4FA',
                                            color: '#0D4EA6',
                                        }}
                                        size="small"
                                        onDelete={() => {
                                            setSelectedOptions(selectedOptions.filter(val => val.value !== user.value))
                                        }}
                                        deleteIcon={<ClearIcon style={{ color: '#0D4EA699', fontSize: '12px' }} />}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </Grid>
            )}
        </div>
    )
}
