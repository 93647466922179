import React, { useEffect, useState } from 'react'
import { Grid, TextField, Paper, Button, CircularProgress } from '@mui/material'
import NewsSection from './NewsSections'
import RenderTemplate from './RenderTemplate'
import Grape from './Grapejs'
import ClearIcon from '@mui/icons-material/Clear'
import axios from 'axios'
import { getCookie } from '../../../utils'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { toast } from 'react-toastify'
import { FaRegPlusSquare } from 'react-icons/fa'
import { Modal, ModalBody, ModalFooter, Input } from 'reactstrap'
import CancelIcon from '@mui/icons-material/Cancel'
import { AiOutlineSave } from 'react-icons/ai'
const token = getCookie('csrftoken')
axios.defaults.headers.common['X-CSRFToken'] = token

export default function TemplatePreview(props) {
    const {
        selectedTemplate,
        selectedStories,
        subjectLine,
        setSubjectLine,
        title,
        setTitle,
        primaryColor,
        setPrimaryColor,
        secondaryColor,
        setSecondaryColor,
        introText,
        setIntroText,
        hyperlink,
        setHyperlink,
        setLogo,
        resetPrimaryLogo,
        logoFileName,
        secondaryLogo,
        resetSecondaryLogo,
        setSize,
        setsecondaryLogo,
        seclogoFileName,
        setBackgroundImage,
        bgFileName,
        selectedLogo,
        selectedBg,
        categorizedNews,
        setContent,
        setNewsCategory,
        isSearchComponent = null,
        includeSection,
        setIncludeSection,
        includeBusiness,
        setIncludeBusiness,
        hyperlinkSource,
        setHyperLinkSource,
        hyperlinkTitle,
        setHyperLinkTitle,
        pathname,
    } = props
    const [isLoader, setIsLoader] = useState(false)
    const [isHyperModal, setIsHyperModal] = useState(false)

    const logoButtonRef = React.useRef()
    const secondarylogoButtonRef = React.useRef()
    const bgButtonRef = React.useRef()

    useEffect(() => {
        setIncludeSection(true)
        const cat = categorizedNews.length > 0 ? categorizedNews.flatMap(val => val.news && val.news).length > 1 : false

        if (selectedTemplate.has_sections && cat) {
            categoryNewsSummary()
        } else {
            setIntroText('')
        }

        if (!selectedTemplate.has_sections && selectedStories.length > 1) {
            newsSummary()
        } else {
            setIntroText('')
        }
    }, [categorizedNews, selectedStories])

    const newsSummary = () => {
        if (selectedStories.length > 0) {
            const payload = {
                titles: selectedStories.map(val => val.title),
                summarised_contents: selectedStories.map(val => val.snippet),
            }
            setIsLoader(true)
            axios
                .post(`/core/generate-summary/`, payload)
                .then(res => {
                    setIntroText(res.data)
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setIsLoader(false)
                })
        }
    }

    const categoryNewsSummary = () => {
        const payload = {
            titles: categorizedNews.flatMap(val => val.news.map(item => item.title)),
            summarised_contents: categorizedNews.flatMap(val => val.news.map(item => item.snippet)),
        }
        setIsLoader(true)
        axios
            .post(`/core/generate-summary/`, payload)
            .then(res => {
                setIntroText(res.data)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoader(false)
            })
    }

    const generateSummary = () => {
        if (selectedTemplate.has_sections) {
            if (categorizedNews.length === 0) {
                toast('Choose sections for summarizing the news')
            } else {
                categoryNewsSummary()
            }
        } else {
            newsSummary()
        }
    }

    return (
        <Grid container direction="column" style={{ paddingRight: '1rem' }} key="step2">
            <Grid item>
                <Paper
                    elevation={3}
                    style={{ padding: '1rem', background: 'rgba(207, 207, 207, 0)', boxShadow: 'none' }}
                >
                    <Grid container direction="row" spacing={3}>
                        {selectedTemplate.custom_template === false && (
                            <Grid item xs={4}>
                                <Grid container direction="column" spacing={3}>
                                    <Grid item key="subjectLine" style={{ width: '90%' }}>
                                        <TextField
                                            style={{ width: '-webkit-fill-available' }}
                                            label="Insert subject line"
                                            variant="outlined"
                                            value={subjectLine}
                                            onChange={e => setSubjectLine(e.target.value)}
                                        />
                                    </Grid>
                                    {selectedTemplate.html_name !== 'f3law_newsletter.html' &&
                                        selectedTemplate.has_title && (
                                            <Grid item key="insertTitle" style={{ width: '90%' }}>
                                                <TextField
                                                    style={{ width: '-webkit-fill-available' }}
                                                    label="Insert Title"
                                                    variant="outlined"
                                                    value={title}
                                                    onChange={e => setTitle(e.target.value)}
                                                />
                                            </Grid>
                                        )}
                                    {selectedTemplate.has_sections && (
                                        <Grid item style={{ width: '90%' }}>
                                            <label>Assign News items to Section</label>
                                            <NewsSection
                                                style={{ width: '90%' }}
                                                selectedNews={selectedStories}
                                                setNewsCategory={setNewsCategory}
                                                categorizedNews={categorizedNews}
                                            />
                                        </Grid>
                                    )}

                                    {/* {selectedTemplate.has_sections && (
                                        <div
                                            className="form-check form-switch"
                                            style={{ width: '90%', marginTop: '15px', marginLeft: '20px' }}
                                        >
                                            <input
                                                style={{ cursor: 'pointer' }}
                                                title="include/exclude of Section"
                                                className="form-check-input"
                                                type="checkbox"
                                                id="sectionSwitchCheckChecked"
                                                value={includeSection}
                                                checked={includeSection ? true : false}
                                                onChange={e => {
                                                    setIncludeSection(current => !current)
                                                }}
                                            />
                                            <label
                                                className="form-check-label text-black"
                                                htmlFor="sectionSwitchCheckChecked"
                                            >
                                                Include Sections in the Newsletter
                                            </label>
                                        </div>
                                    )} */}

                                    <div
                                        className="form-check form-switch"
                                        style={{ width: '90%', marginTop: '10px', marginLeft: '20px' }}
                                    >
                                        <input
                                            style={{ cursor: 'pointer' }}
                                            title="include/exclude of Business Events"
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckChecked"
                                            value={includeBusiness}
                                            checked={includeBusiness ? true : false}
                                            onChange={e => {
                                                setIncludeBusiness(current => !current)
                                            }}
                                        />
                                        <label className="form-check-label text-black" htmlFor="flexSwitchCheckChecked">
                                            Include Business Events in the Newsletter
                                        </label>
                                    </div>

                                    {selectedTemplate.html_name !== 'f3law_newsletter.html' && (
                                        <Grid item style={{ width: '275px' }}>
                                            <label
                                                onClick={() => {
                                                    setIsHyperModal(!isHyperModal)
                                                }}
                                                style={{ marginLeft: '5px', marginBottom: '5px' }}
                                            >
                                                Add Reference
                                                <span style={{ marginLeft: '5px' }}>
                                                    <FaRegPlusSquare
                                                        style={{ color: 'rgb(59, 125, 186)', fontSize: '18px' }}
                                                    />
                                                </span>
                                            </label>
                                        </Grid>
                                    )}

                                    {selectedTemplate.has_introtext &&
                                        ((!selectedTemplate.has_sections && selectedStories.length > 1) ||
                                            (selectedTemplate.has_sections &&
                                                categorizedNews.length > 0 &&
                                                categorizedNews.flatMap(val => val.news && val.news).length > 1)) && (
                                            <Grid item key="introText" style={{ width: '90%' }}>
                                                <TextField
                                                    style={{ width: '98%' }}
                                                    multiline
                                                    rows={4}
                                                    label="Insert introductory email text"
                                                    value={introText}
                                                    onChange={e => setIntroText(e.target.value)}
                                                />

                                                <span
                                                    onClick={generateSummary}
                                                    style={{
                                                        position: 'absolute',
                                                        marginLeft: '-17px',
                                                        color: '#3b7dba',
                                                        cursor: 'pointer',
                                                    }}
                                                    className="generate_summary_introtext"
                                                    title="Generate Introtext"
                                                >
                                                    {!isLoader ? (
                                                        <HiOutlineLightBulb />
                                                    ) : (
                                                        <CircularProgress
                                                            style={{
                                                                fontSize: '10px',
                                                                width: '10px',
                                                                height: '10px',
                                                            }}
                                                        />
                                                    )}
                                                </span>
                                            </Grid>
                                        )}
                                    {/* {selectedTemplate.has_hyperlink && (
                                        <Grid item key="email_hyperlink" style={{ width: '90%' }}>
                                            <TextField
                                                style={{ width: '-webkit-fill-available' }}
                                                multiline
                                                rows={3}
                                                value={hyperlink}
                                                onChange={e => setHyperlink(e.target.value)}
                                                label="Emails to send, seperate by comma(,)"
                                            />
                                        </Grid>
                                    )} */}
                                    {selectedTemplate.html_name !== 'f3law_newsletter.html' && (
                                        <Grid item key="selectColor">
                                            <Grid container direction="row" spacing={2} style={{ width: '90%' }}>
                                                <Grid item xs={6} key="primaryColor" style={{ width: '90%' }}>
                                                    <label>Primary Color</label>
                                                    <Input
                                                        style={{ width: '100px' }}
                                                        type="color"
                                                        value={primaryColor}
                                                        onChange={e => setPrimaryColor(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} key="secondaryColor" style={{ width: '90%' }}>
                                                    <label>Secondary Color</label>
                                                    <Input
                                                        style={{ width: '100px' }}
                                                        type="color"
                                                        value={secondaryColor}
                                                        onChange={e => setSecondaryColor(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {selectedTemplate.html_name !== 'f3law_newsletter.html' && (
                                        <Grid item xs={2} key="selectLogo" style={{ width: '90%' }}>
                                            <div>
                                                <h4>Select Logo</h4>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={e => setLogo(e)}
                                                    style={{ display: 'none' }}
                                                    ref={ref => (logoButtonRef.current = ref)}
                                                />
                                                <Button
                                                    style={{ border: 'solid 1px' }}
                                                    onClick={e => logoButtonRef.current.click()}
                                                >
                                                    Choose File
                                                </Button>
                                                <Button>
                                                    <ClearIcon onClick={() => resetPrimaryLogo()} />
                                                </Button>
                                                <span>{logoFileName}</span>
                                            </div>
                                        </Grid>
                                    )}
                                    {selectedTemplate.has_multilogo && (
                                        <Grid item xs={2} key="secondaryLogo" style={{ width: '90%' }}>
                                            <div>
                                                <h4>Secondary Logo</h4>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={e => setsecondaryLogo(e)}
                                                    style={{ display: 'none' }}
                                                    ref={ref => (secondarylogoButtonRef.current = ref)}
                                                />
                                                <Button
                                                    style={{ border: 'solid 1px' }}
                                                    onClick={e => secondarylogoButtonRef.current.click()}
                                                >
                                                    Choose File
                                                </Button>
                                                <Button>
                                                    <ClearIcon onClick={() => resetSecondaryLogo()} />
                                                </Button>
                                                <span style={{ paddingLeft: '20px' }}>{seclogoFileName}</span>
                                            </div>
                                        </Grid>
                                    )}
                                    {selectedTemplate.has_background && (
                                        <Grid item xs={2} key="selectBackground" style={{ width: '90%' }}>
                                            <h4>Select Background Image</h4>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={e => setBackgroundImage(e)}
                                                style={{ display: 'none' }}
                                                ref={ref => (bgButtonRef.current = ref)}
                                            />
                                            <Button
                                                style={{ border: 'solid 1px' }}
                                                onClick={e => bgButtonRef.current.click()}
                                            >
                                                Choose File
                                            </Button>
                                            <span style={{ paddingLeft: '20px' }}>{bgFileName}</span>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={8}
                            direction={'column'}
                            key="mailPreview"
                            style={{
                                marginLeft: selectedTemplate.custom_template === false ? '0px' : '',
                            }}
                        >
                            <Paper elevation={3}>
                                {selectedTemplate.custom_template === false && (
                                    <RenderTemplate
                                        setSize={setSize}
                                        justPreview={false}
                                        title={title}
                                        introText={introText}
                                        primaryColor={primaryColor}
                                        selectedLogo={selectedLogo}
                                        secondaryLogo={secondaryLogo}
                                        secondaryColor={secondaryColor}
                                        selectedTemplate={selectedTemplate}
                                        selectedBg={selectedTemplate.has_background ? selectedBg : null}
                                        selectedStories={
                                            selectedTemplate.has_sections ? categorizedNews : selectedStories
                                        }
                                        newsBodySize={false}
                                        includeSection={includeSection}
                                        includeBusiness={includeBusiness}
                                        hyperlinkTitle={hyperlinkTitle}
                                        hyperlinkSource={hyperlinkSource}
                                        subjectLine={subjectLine}
                                    />
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
                {selectedTemplate.custom_template === true && (
                    <div>
                        <Grape
                            selectedTemplate={selectedTemplate}
                            selectedStories={selectedStories}
                            categorizedNews={categorizedNews}
                            setCategorizedNews={setNewsCategory}
                            setHtmlContent={setContent}
                            isSearchComponent={isSearchComponent}
                            pathname={pathname}
                        />
                    </div>
                )}

                <Modal
                    isOpen={isHyperModal}
                    toggle={() => {
                        setIsHyperModal(!isHyperModal)
                    }}
                >
                    <div style={{ padding: '10px', background: 'black' }} className="d-flex justify-content-between">
                        <h3 style={{ paddingLeft: '2%', color: 'white', fontWeight: 600 }}>Add Reference</h3>

                        <span
                            style={{ paddingRight: '2%', cursor: 'pointer' }}
                            onClick={() => {
                                setIsHyperModal(!isHyperModal)
                            }}
                        >
                            <CancelIcon style={{ color: 'white' }} />
                        </span>
                    </div>
                    <ModalBody style={{ padding: '0.5rem' }}>
                        <div style={{ margin: '10px' }}>
                            <div>
                                <label>Title</label>
                                <Input
                                    type="text"
                                    value={hyperlinkTitle}
                                    onChange={e => {
                                        setHyperLinkTitle(e.target.value)
                                    }}
                                    style={{ padding: '10px' }}
                                    autoFocus={true}
                                />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <label>Source</label>
                                <Input
                                    type="text"
                                    value={hyperlinkSource}
                                    onChange={e => {
                                        setHyperLinkSource(e.target.value)
                                    }}
                                    placeholder="https://insights.kaitongo.com/"
                                    style={{ padding: '10px' }}
                                    autoFocus={true}
                                />
                            </div>
                        </div>
                        <ModalFooter>
                            <Button
                                className="Cancel_btn"
                                onClick={() => {
                                    setIsHyperModal(false)
                                }}
                                style={{ background: 'rgb(59, 125, 186)', color: 'white', marginRight: '15px' }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    if (hyperlinkSource !== '' && hyperlinkTitle !== '') setIsHyperModal(!isHyperModal)
                                    else toast.warn('Please fill out all the fields.')
                                }}
                                style={{ background: 'rgb(59, 125, 186)', color: 'white' }}
                                className="save_btn"
                            >
                                Save
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            </Grid>
        </Grid>
    )
}
