import React, { useState, useRef, useEffect } from 'react'

import CompanyLogo from '../../../components/CompanyLogo/CompanyLogo'
import DownCarrotLarge from '../../../assets/icons/down-carrot-large.svg'
import { CgNotes } from 'react-icons/cg'
import { Button } from '@mui/material'
import ApartmentIcon from '@mui/icons-material/Apartment'
import FactoryIcon from '@mui/icons-material/Factory'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { Spinner } from 'reactstrap'
import CustomLoader from '../../NewsFeedScreen/components/CustomLoader'

export default function Description(props) {
    const {
        Name,
        Logo,
        Overview,
        windowWidth,
        companyId,
        screenType,
        companyUrl,
        preMeetingLoader,
        detailedProgressContent,
        reportStatus,
    } = props
    const { Industry, Revenue, Share, Headquaters } = props
    const [boxExpand, setBoxExpand] = useState(windowWidth > 900 ? true : false)
    const [descExpanded, setDescExpanded] = useState(false)
    const [isOverflowing, setIsOverflowing] = useState(false)
    const descriptionRef = useRef(null)
    const [isHovered, setIsHovered] = useState(false)
    useEffect(() => {
        if (descriptionRef.current) {
            const isOverflow = descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight
            setIsOverflowing(isOverflow)
        }
    }, [Overview, descExpanded])

    const CompanyInfoLines = props => {
        return (
            <div
                className="companyOverview__keyValueBox"
                style={{
                    padding: '16px',
                    border: '1px solid #ececec',
                    // borderRadius: '4px',
                    backgroundColor: 'white',
                    boxShadow: '0 2px 5px whitesmoke',
                    // margin: '0px 80px 0px 120px',
                    // padding: '15px',
                }}
            >
                <p className="companyOverview__key">{props.first}</p>
                <p className="companyOverview__value">{props.second}</p>
            </div>
        )
    }

    const CompanyInfoLine = props => {
        return (
            <div style={{ marginTop: '10px' }}>
                <Button
                    variant="contained"
                    // startIcon={props.icon}
                    style={{
                        background: '#66B2EC1A',
                        borderRadius: '4px',
                        width: '92%',
                        fontSize: '12px',
                        height: '30px',
                        color: 'rgb(25, 118, 210)',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        border: 'none',
                        boxShadow: 'none',
                        justifyContent: 'left',
                        borderRadius: '25px',
                        margin: 'auto',
                        display: 'flex',
                        overflow: 'hidden',
                    }}
                    // title={props.first}
                >
                    {props.first} - {props.second}
                </Button>
            </div>
        )
    }

    return (
        <div className="companyOverview__descriptionContainer d-md-flex d-sm-block bg-white" style={{ width: '100%' }}>
            <div className="responsive_company_description_content">
                <div className="companyOverview__heading" style={{ width: '100%' }}>
                    <div
                        className="companyOverview__logoAndName"
                        id="companyOverview__logoAndName"
                        style={{ width: '100%' }}
                    >
                        <CompanyLogo
                            logo={Logo}
                            name={Name}
                            id={companyId}
                            title_link={null}
                            OuterStyle={{ margin: '0 10px' }}
                            square={true}
                            className="company_logo_size"
                        />

                        <div className="companyOverview__nameAndDesc">
                            <p className="companyOverview__companyName text-black">{Name}</p>
                            {companyUrl && (
                                <a
                                    href={
                                        companyUrl.split(';')[0].includes('http')
                                            ? companyUrl.split(';')[0]
                                            : `https://${companyUrl.split(';')[0]}`
                                    }
                                    style={{ color: 'black', textDecoration: 'none', fontSize: '14px' }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <p>
                                        {
                                            companyUrl
                                                .split(';')[0]
                                                .replace(/^(https?:\/\/)?(www\.)?/, '')
                                                .split('/')[0]
                                        }
                                    </p>
                                </a>
                            )}
                        </div>
                        {/* {screenType === 'companies' && (
                            <Button
                                variant="contained"
                                // endIcon={preMeetingLoader ? <Spinner style={{ fontSize: '12px' }} /> : <></>}
                                style={{
                                    marginBottom: '0px',
                                    background: 'rgb(25, 118, 210)',
                                    marginLeft: 'auto',
                                    color: 'white',
                                    fontSize:
                                        reportStatus[companyId] && reportStatus[companyId].status === 'loading'
                                            ? '8px'
                                            : '13px',
                                    borderRadius: '4px',
                                    height: '40px',
                                    fontWeight: 600,
                                    width: 'max-content',
                                    opacity:
                                        reportStatus[companyId] && reportStatus[companyId].status === 'loading'
                                            ? 0.6
                                            : 1,
                                    textTransform: 'none',
                                }}
                                disabled={reportStatus[companyId] && reportStatus[companyId].status === 'loading'}
                                onClick={() => props.getPreMeetingData(companyId)}
                            >
                                {reportStatus[companyId] && reportStatus[companyId].status === 'loading' ? (
                                    <CustomLoader
                                        progressContent={windowWidth <= 768 ? null : detailedProgressContent}
                                        isMyCompaniesPage={true}
                                    />
                                ) : reportStatus[companyId] && reportStatus[companyId].status === 'completed' ? (
                                    'View Brief'
                                ) : (
                                    <>{windowWidth <= 768 ? <CgNotes /> : 'Generate Brief'}</>
                                )}
                            </Button>
                            // <button
                            //     title="Pre-meeting brief"
                            //     className="d-flex align-items-center justify-content-center ms-auto"
                            //     style={{
                            //         marginBottom: '0px',
                            //         background: 'rgb(25, 118, 210)',
                            //         marginLeft: 'auto',
                            //         color: 'white',
                            //         fontSize: '13px',
                            //         padding: '0px 5px',
                            //         borderRadius: '4px',
                            //         height: '40px',
                            //         fontWeight: 600,
                            //         width: 'max-content',
                            //     }}
                            //     onClick={props.getPreMeetingData}
                            // >
                            //     Generate Brief
                            // </button>
                        )} */}
                    </div>
                </div>

                <div className="companyOverview__descriptionBox">
                    <div>
                        <span
                            ref={descriptionRef}
                            style={{
                                cursor: 'pointer',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: descExpanded ? 'unset' : 3,
                                WebkitBoxOrient: 'vertical',
                                fontSize: '14px',
                                textAlign: 'justify',
                            }}
                        >
                            {Overview.split('\n').map((article, index) => (
                                <div key={index}>{article}</div>
                            ))}
                        </span>
                        {isOverflowing || descExpanded ? (
                            <span
                                style={{ color: 'rgb(25, 118, 210)', cursor: 'pointer', marginTop: '5px' }}
                                onClick={() => setDescExpanded(!descExpanded)}
                                className="d-flex align-items-center"
                            >
                                <span style={{ fontWeight: 600, fontSize: '14px' }}>
                                    {descExpanded ? 'See Less' : 'See More'}
                                </span>
                                {descExpanded ? (
                                    <FaChevronUp
                                        style={{
                                            fontSize: '12px',
                                            marginLeft: '4px',
                                        }}
                                    />
                                ) : (
                                    <FaChevronDown
                                        style={{
                                            fontSize: '12px',
                                            marginLeft: '4px',
                                        }}
                                    />
                                )}
                            </span>
                        ) : null}
                    </div>
                </div>
            </div>

            <div
                style={{
                    background: 'white',

                    // display: 'grid',
                    // gridTemplateColumns: 'repeat(2, 1fr)',
                    // gap: '16px',
                    // rowGap: '0px',
                    // columnGap: '1px',
                    // justifyContent: 'center',
                    // padding: '8px 40px 8px 120px',
                }}
                // className="d-md-grid d-sm-block"
                // className="col-md-4 col-md-30"
                className="responsive_company_sectorinfo"
            >
                <div
                    style={{
                        width: '95%',
                        margin: '10px auto',
                        borderRadius: '5px',
                        padding: '10px 0',
                        boxShadow: isHovered ? '0px 0px 2px 2px rgb(16, 16, 16)' : 'none',
                        transition: 'box-shadow 0.3s ease',
                        border: '1px solid black',
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {Industry && Industry.length > 0 && (
                        <CompanyInfoLine
                            icon={<FactoryIcon style={{ fontSize: '18px' }} />}
                            first="Industry"
                            second={Industry[0].name}
                        />
                    )}
                    {(Revenue || Revenue !== 0) && (
                        <CompanyInfoLine
                            icon={<AttachMoneyIcon style={{ fontSize: '18px' }} />}
                            first="Revenue"
                            second={Revenue.replace('$', '')}
                        />
                    )}
                    {Share && (
                        <CompanyInfoLine
                            icon={<WarehouseIcon style={{ fontSize: '18px' }} />}
                            first="Type"
                            second={Share}
                        />
                    )}
                    {Headquaters && (
                        <CompanyInfoLine
                            icon={<WarehouseIcon style={{ fontSize: '18px' }} />}
                            first="Headquater"
                            second={Headquaters}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
