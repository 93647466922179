import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { getTrans } from '../../../utils'
import './AnalystSidebar.css'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { IoMdSettings } from 'react-icons/io'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import { IoMdChatbubbles } from 'react-icons/io'
import ApartmentIcon from '@mui/icons-material/Apartment'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import { redirectUser } from '../../../utils/handleRedirects'
import { IoMdBriefcase, IoMdBusiness, IoMdAnalytics } from 'react-icons/io'
class EndUserAdminSidebar extends Component {
    // state = {
    //     allowedPage: [
    //         'Find and Share',
    //         'Admin',
    //         'Template',
    //         'Insights Assistant',
    //         'Settings',
    //         'Dashboard',
    //         'My Request',
    //         'My Preferences',
    //         'Newsletters',
    //         // 'My Companies',
    //     ],
    // }

    state = {
        // userRole: this.props.user.role,
        planType: this.props.user.plan_type,
        allowedPages: [
            ...this.props.user.firm_allowed_pages,
            'Admin',
            // 'My Request',
            'My Preferences',
            'Opportunities',
            'Companies',
            'Sectors',
        ],
        urlCheck: false,
    }
    componentDidMount() {
        this.checkAllowedPage()
    }
    // checkAllowedPage() {
    //     let currentPath = window.location.pathname
    //     if (currentPath === '/app/search/') {
    //         currentPath = 'Find and Share'
    //     } else if (currentPath.includes('admin')) {
    //         currentPath = 'Admin'
    //     } else if (currentPath.includes('template')) {
    //         currentPath = 'Template'
    //     } else if (currentPath.includes('dashboard')) {
    //         currentPath = 'Dashboard'
    //     } else if (currentPath === '/app/assistant/') {
    //         currentPath = 'Assistant'
    //     } else if (currentPath.includes('request')) {
    //         currentPath = 'My Request'
    //     } else if (currentPath.includes('preferences')) {
    //         currentPath = 'My Preferences'
    //     } else if (currentPath === '/app/newsletters/') {
    //         currentPath = 'Newsletters'
    //     }
    //     //  else if (currentPath.includes('companies')) {
    //     //     currentPath = 'My Companies'
    //     // }
    //     else {
    //         window.location.pathname = '/app/search/'
    //     }

    //     if (this.state.allowedPages.includes(currentPath) && this.state.urlCheck === false) {
    //         this.setState({ urlCheck: true })
    //     } else {
    //         this.setState({ urlCheck: false })
    //         redirectUser(this.props.user, this.props.history, this.props)
    //     }
    // }

    checkAllowedPage() {
        let currentPath = window.location.pathname

        if (currentPath === '/app/search/') {
            currentPath = 'Find and Share'
        } else if (currentPath === '/app/preferences/') {
            currentPath = 'My Preferences'
        } else if (currentPath === '/app/companies/' || currentPath === '/app/company/') {
            currentPath = 'My Companies'
        } else if (currentPath === '/app/assistant/') {
            currentPath = 'Insights Assistant'
        } else if (currentPath === '/app/request/') {
            currentPath = 'My Request'
        } else if (currentPath.includes('admin')) {
            currentPath = 'Admin'
        } else if (currentPath.includes('newsletters')) {
            currentPath = 'Newsletters'
        } else if (currentPath.includes('chat')) {
            currentPath = 'Chat'
        } else if (currentPath.includes('opportunities')) {
            currentPath = 'Opportunities'
        }

        if (this.state.allowedPages.includes(currentPath) && this.state.urlCheck === false) {
            this.setState({ urlCheck: true })
        } else {
            this.setState({ urlCheck: false })
            redirectUser(this.props.user, this.props.history, this.props)
        }
    }
    render() {
        const isPageAllowed = page => this.state.allowedPages.indexOf(page) !== -1

        return (
            <>
                {isPageAllowed('Insights Assistant') && (
                    <NavLink to="/app/assistant/" className="nav-item" id="assistant_navlink">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <AutoAwesomeOutlinedIcon />
                            </span>
                            <span>{getTrans('Insights Assistant')}</span>
                        </div>
                    </NavLink>
                )}
                {/* {isPageAllowed('Dashboard') && (
                    <NavLink to="/app/dashboard/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <DashboardOutlinedIcon />
                            </span>
                            <span>{getTrans('Dashboard')}</span>
                        </div>
                    </NavLink>
                )} */}
                {/* <NavLink to="/app/admin/opportunities/" className="nav-item">
                    <div className="d-flex align-items-center">
                        <span style={{ marginRight: '5px' }}>
                            <IoMdBriefcase />
                        </span>
                        <span>{getTrans('Opportunities')}</span>
                    </div>
                </NavLink>
                <NavLink to="/app/admin/sectors/" className="nav-item">
                    <div className="d-flex align-items-center">
                        <span style={{ marginRight: '5px' }}>
                            <IoMdAnalytics />
                        </span>
                        <span>{getTrans('Sectors')}</span>
                    </div>
                </NavLink>
                <NavLink to="/app/admin/companies/" className="nav-item">
                    <div className="d-flex align-items-center">
                        <span style={{ marginRight: '5px' }}>
                            <IoMdBusiness />
                        </span>
                        <span>{getTrans('Companies')}</span>
                    </div>
                </NavLink> */}
                {isPageAllowed('Chat') && (
                    <NavLink to="/app/chat/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <IoMdChatbubbles />
                            </span>
                            <span>
                                {getTrans('Chat')}
                                <span
                                    style={{
                                        border: '1px solid lightgray',
                                        fontSize: '8px',
                                        borderRadius: '25px',
                                        padding: '2.5px 5px 2px 5px',
                                        marginTop: '-15px',
                                        marginLeft: '4px',
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        background: 'white',
                                    }}
                                >
                                    BETA
                                </span>
                            </span>
                        </div>
                    </NavLink>
                )}
                {isPageAllowed('Find and Share') && (
                    <NavLink to="/app/search/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <ManageSearchIcon />
                            </span>
                            <span>{getTrans('Find and Share')}</span>
                        </div>
                    </NavLink>
                )}

                {isPageAllowed('Newsletters') && (
                    <NavLink to="/app/newsletters/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <ForwardToInboxIcon />
                            </span>
                            <span>{getTrans('Newsletters')}</span>
                        </div>
                    </NavLink>
                )}
                {isPageAllowed('My Request') && (
                    <NavLink to="/app/request/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <DashboardOutlinedIcon />
                            </span>
                            <span>{getTrans('My Requests')}</span>
                        </div>
                    </NavLink>
                )}
                {isPageAllowed('My Companies') && (
                    <NavLink to="/app/companies/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <ApartmentIcon />
                            </span>
                            <span>{getTrans('My Companies')}</span>
                        </div>
                    </NavLink>
                )}
                {/* {isPageAllowed('My Preferences') && ( */}
                <NavLink to="/app/preferences/" className="nav-item">
                    <div className="d-flex align-items-center">
                        <span style={{ marginRight: '5px' }}>
                            <AccountCircleIcon />
                        </span>
                        <span>{getTrans('My Preferences')}</span>
                    </div>
                </NavLink>
                {/* )} */}
                {/* {isPageAllowed('Admin') && ( */}
                <NavLink
                    to="/app/admin/enduser/"
                    className={
                        window.location.pathname.includes('/app/admin/enduser/') ||
                        window.location.pathname.includes('app/admin/add-enduser')
                            ? 'nav-item active'
                            : 'nav-item'
                    }
                >
                    <div className="d-flex align-items-center">
                        <span style={{ marginRight: '5px' }}>
                            <SupervisorAccountIcon />
                        </span>
                        <span>{getTrans('Manage Users')}</span>
                    </div>
                </NavLink>
                {/* )}
                {isPageAllowed('Settings') && ( */}
                <NavLink to="/app/admin/firm/" className="nav-item">
                    <div className="d-flex align-items-center">
                        <span style={{ marginRight: '5px' }}>
                            <IoMdSettings />
                        </span>
                        <span>{getTrans('Firm Settings')}</span>
                    </div>
                </NavLink>

                {/* )}*/}
            </>
        )
    }
}

export default EndUserAdminSidebar
