import React from 'react'

import {
    Skeleton,
    Box,
    Typography,
    Divider,
    IconButton,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
} from '@mui/material'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
// Skeleton for conversation starters
const ConversationStartersSkeleton = () => (
    <div
        style={{
            background: 'white',
            paddingBottom: '15px',
            borderRadius: '4px',
        }}
    >
        <div
            style={{
                width: '95%',
                margin: 'auto',
                paddingTop: '10px',
            }}
            className="d-flex align-items-center justify-content-between"
        >
            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Conversation Starters</div>
            <div
                className="d-flex align-items-center justify-content-between"
                style={{ color: 'gray', cursor: 'pointer' }}
            >
                <Skeleton variant="text" width={50} height={24} />
            </div>
        </div>

        {/* Skeleton rows for conversation starters */}
        <Skeleton variant="rectangular" width="90%" height={40} style={{ margin: '8px auto' }} />
        <Skeleton variant="rectangular" width="90%" height={40} style={{ margin: '8px auto' }} />
        <Skeleton variant="rectangular" width="90%" height={40} style={{ margin: '8px auto' }} />
    </div>
)

// Skeleton for strategic priorities
const StrategicPrioritiesSkeleton = () => (
    <div
        style={{
            background: 'white',
            paddingBottom: '15px',
            borderRadius: '4px',
        }}
    >
        <div
            style={{
                width: '95%',
                margin: 'auto',
                paddingTop: '10px',
            }}
            className="d-flex align-items-center justify-content-between"
        >
            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Strategic Priorities</div>
            <div
                className="d-flex align-items-center justify-content-between"
                style={{ color: 'gray', cursor: 'pointer' }}
            >
                <Skeleton variant="text" width={50} height={24} />
            </div>
        </div>

        {/* Skeleton rows for strategic priorities */}
        <Skeleton variant="rectangular" width="90%" height={40} style={{ margin: '8px auto' }} />
        <Skeleton variant="rectangular" width="90%" height={40} style={{ margin: '8px auto' }} />
        <Skeleton variant="rectangular" width="90%" height={40} style={{ margin: '8px auto' }} />
    </div>
)

const SkeletonComponent = () => {
    return (
        <div>
            <ConversationStartersSkeleton />
            <div style={{ marginTop: '3%' }}>
                <StrategicPrioritiesSkeleton />
            </div>
        </div>
    )
}

const CompanyNewsSkeleton = () => {
    return (
        <div
            style={{
                marginTop: '20px',
                width: '95%',
                margin: 'auto',
                background: 'white',
                padding: '10px 0px 0px 0px',
            }}
        >
            <div
                className="companyNews__heading d-flex align-items-center justify-content-between mb-2"
                style={{
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    className="headingGeneric company__overviewHeading"
                    style={{ fontWeight: 'bold', fontSize: '16px' }}
                >
                    News & Key Updates
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'rgb(25, 118, 210)',
                        cursor: 'pointer',
                    }}
                >
                    <Skeleton variant="circular" width={24} height={24} />
                    <Skeleton variant="text" width={60} height={20} style={{ marginLeft: '8px' }} />
                </div>
            </div>

            {/* Skeleton cards for news */}
            <div style={{ marginTop: '10px' }}>
                {[1, 2, 3].map((_, index) => (
                    <div
                        key={index}
                        style={{
                            background: 'white',
                            marginBottom: '15px',
                            padding: '15px',
                            borderRadius: '8px',
                            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Skeleton variant="text" width="80%" height={24} />
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={150}
                            style={{ marginTop: '10px', borderRadius: '4px' }}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
const SignalsTriggersSkeleton = () => {
    return (
        <Box
            sx={{
                p: 2,
                border: '1px solid #ddd',
                borderRadius: '4px',
                backgroundColor: '#fff',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #e0e0e0',
                    pb: 1,
                }}
            >
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        fontWeight: 600,
                        mb: 2,
                        color: '#6A6A6A',
                        fontSize: { xs: '16px', sm: '18px' },
                    }}
                >
                    Signals/Triggers
                </Typography>
            </div>
            <Box
                sx={{
                    height: '881px',
                    overflowY: 'auto',
                    mt: 2,
                    p: 0,
                }}
            >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => (
                    <React.Fragment key={index}>
                        <Box
                            sx={{
                                borderLeft: '4px solid rgb(200, 200, 200)',
                                cursor: 'pointer',
                                mb: 1,
                                p: 1,
                                gap: { xs: 1, sm: 2 },
                                backgroundColor: '#f9f9f9',
                                borderRadius: '4px',
                            }}
                        >
                            <Skeleton variant="text" width="80%" height={20} />
                            <Skeleton variant="text" width="60%" height={16} sx={{ mt: 1 }} />
                        </Box>
                        <Divider />
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    )
}
const TreemapSkeleton = () => {
    return (
        <Box
            sx={{
                p: 2,
                border: '1px solid #ddd',
                borderRadius: '4px',
                backgroundColor: '#fff',
                width: '100%',
            }}
        >
            {/* Header Section */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #e0e0e0',
                    pb: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 1,
                }}
            >
                {/* Title and Info */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            fontWeight: 600,
                            color: '#6A6A6A',
                            fontSize: { xs: '16px', sm: '18px' },
                        }}
                    >
                        Opportunities by sectors
                    </Typography>
                    <IconButton size="small" aria-label="More info">
                        <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                </Box>

                {/* View All Button */}
                <Button
                    variant="contained"
                    size="small"
                    endIcon={<ArrowRightAltIcon />}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 600,
                        backgroundColor: '#f2f2f2',
                        color: '#171717',
                        boxShadow: 'none',
                        fontSize: { xs: '12px', sm: '14px' },
                        px: { xs: 2, sm: 3 },
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        },
                    }}
                >
                    <Skeleton variant="text" width={80} height={24} />
                </Button>
            </Box>

            {/* Treemap Section */}
            <Box
                sx={{
                    width: '100%',
                    height: { xs: 300, md: 400 },
                    mt: 2,
                }}
            >
                <Skeleton variant="rectangular" width="100%" height={400} style={{ borderRadius: '4px' }} />
            </Box>
        </Box>
    )
}

const TargetCompaniesSkeleton = () => {
    return (
        <Box
            sx={{
                p: 2,
                border: '1px solid #ddd',
                borderRadius: '4px',
                backgroundColor: '#fff',
            }}
        >
            {/* Header Section */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #e0e0e0',
                    pb: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 2,
                }}
            >
                {/* Title and Info Icon */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            fontWeight: 600,
                            color: '#6A6A6A',
                            fontSize: { xs: '16px', md: '18px' },
                        }}
                    >
                        Target Companies
                    </Typography>
                    <IconButton>
                        <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                </Box>

                {/* View All Button */}
                <Button
                    variant="contained"
                    size="small"
                    endIcon={<ArrowRightAltIcon />}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 600,
                        backgroundColor: '#f2f2f2',
                        color: '#171717',
                        boxShadow: 'none',
                        fontSize: { xs: '12px', sm: '14px' },
                        px: { xs: 2, sm: 3 },
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        },
                    }}
                >
                    <Skeleton variant="text" width={80} height={24} />
                </Button>
            </Box>

            <TableContainer
                component={Paper}
                sx={{
                    maxHeight: '400px',
                    overflowY: 'auto',
                    overflowX: 'auto',
                }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {['Company', 'Website', 'Opportunity', 'Update'].map((header, index) => (
                                <TableCell
                                    key={index}
                                    sx={{
                                        fontWeight: 'bold',
                                        whiteSpace: { xs: 'nowrap', sm: 'normal' },
                                        minWidth: { xs: '150px', md: '200px' },
                                    }}
                                >
                                    <Skeleton variant="text" width={100} height={20} />
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[1, 2, 3, 4, 5].map((_, index) => (
                            <TableRow key={index} hover>
                                <TableCell>
                                    <Skeleton variant="text" width={150} height={20} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton variant="text" width={150} height={20} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton variant="rectangular" width={50} height={20} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton variant="text" width={100} height={20} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
export {
    CompanyNewsSkeleton,
    TreemapSkeleton,
    SkeletonComponent,
    ConversationStartersSkeleton,
    TargetCompaniesSkeleton,
    StrategicPrioritiesSkeleton,
    SignalsTriggersSkeleton,
}
