import React from 'react'
import { Button, Chip } from '@mui/material'

const SelectedHieararchyChip = ({ hierarchy, selectedItems, bulkChange, bulkChangeKey, labels, isExpanded }) => {
    const getSelectedChips = () => {
        const selectedSet = new Set(selectedItems)
        const result = []
        let flatteReg = []
        let flatteTop = []

        const traverse = (node, type) => {
            if (selectedSet.has(node.name)) {
                result.push({ name: node.name, type })
                return true
            }
            return false
        }
        if (bulkChangeKey === 'country') {
            const flattenRegions = () => {
                const result = hierarchy.flatMap(reg => [
                    { value: reg.id, name: reg.name, type: reg.type },
                    ...reg.countries.flatMap(cty => [
                        { value: cty.id, name: cty.name, type: cty.type },
                        ...cty.provinces.map(pro => ({
                            value: pro.id,
                            name: pro.name,
                            type: pro.type,
                        })),
                    ]),
                ])

                return result
            }
            flatteReg = flattenRegions()
        }
        if (bulkChangeKey === 'sector') {
            const flattenTopics = () => {
                const result = hierarchy.flatMap(ind => [
                    { value: ind.id, name: ind.name, type: 'industries' },
                    ...ind.sectors.flatMap(sec => [
                        { value: sec.id, name: sec.name, type: 'sectors' },
                        ...sec.topics.map(top => ({
                            value: top.id,
                            name: top.name,
                            type: 'topics',
                        })),
                    ]),
                ])

                return result
            }
            flatteTop = flattenTopics()
        }

        hierarchy.forEach(region => {
            if (traverse(region, labels[0])) return
            ;(region.sectors || region.countries || []).forEach(subRegion => {
                if (traverse(subRegion, labels[1])) return
                ;(subRegion.topics || subRegion.provinces || []).forEach(deepNode => {
                    traverse(deepNode, labels[2])
                })
            })
        })

        const findDuplicates = array => {
            const seen = new Set()
            const duplicates = new Set()
            array.forEach(item => {
                if (seen.has(item.name)) {
                    duplicates.add(item)
                } else {
                    seen.add(item.name)
                }
            })
            return Array.from(duplicates)
        }
        const duplicateTopics = findDuplicates(flatteTop)
        const duplicateRegions = findDuplicates(flatteReg)
        const duplicateResult = findDuplicates(result)

        let final = []

        if (bulkChangeKey === 'sector') {
            if (duplicateResult.length > 0) {
                const uniqueArray = Array.from(new Map(result.map(item => [item.name, item])).values())

                final = uniqueArray
            } else {
                final = result.filter(
                    item => !duplicateTopics.some(dupItem => dupItem.name === item.name && dupItem.type === item.type)
                )
            }
        } else {
            if (duplicateResult.length > 0) {
                const uniqueArray = Array.from(new Map(result.map(item => [item.name, item])).values())

                final = uniqueArray
            } else {
                final = result.filter(item => !duplicateRegions.some(dupItem => dupItem.name === item.name))
            }
        }
        // return result
        return final
    }

    const handleDelete = name => {
        const relatedItems = []

        const collectRelatedItems = (node, level) => {
            if (node.name === name) {
                if (level === 0) {
                    relatedItems.push(
                        { name: node.name },
                        ...(node.sectors || node.countries || []).map(c => ({ name: c.name })),
                        ...(node.sectors || node.countries || []).flatMap(c =>
                            (c.topics || c.provinces || []).map(p => ({ name: p.name }))
                        )
                    )
                } else if (level === 1) {
                    relatedItems.push(
                        { name: node.name },
                        ...(node.topics || node.provinces || []).map(p => ({ name: p.name }))
                    )
                } else {
                    relatedItems.push({ name: node.name })
                }
            }
        }

        hierarchy.forEach(region => {
            collectRelatedItems(region, 0)
            ;(region.sectors || region.countries || []).forEach(subRegion => {
                collectRelatedItems(subRegion, 1)
                ;(subRegion.topics || subRegion.provinces || []).forEach(deepNode => {
                    collectRelatedItems(deepNode, 2)
                })
            })
        })

        bulkChange(bulkChangeKey, relatedItems, name)
    }

    const selectedChips = getSelectedChips()

    return (
        <>
            {selectedChips.map(({ name, type }) => (
                <Chip
                    key={name}
                    label={name}
                    onDelete={() => handleDelete(name)}
                    style={{
                        background: 'none',
                        border: '1px solid #3f3f3f',
                        color: '#3f3f3f',
                        marginLeft: '10px',
                        marginTop: isExpanded ? '10px' : '0px',
                    }}
                />
            ))}
        </>
    )
}
export default SelectedHieararchyChip
