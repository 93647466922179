import React, { useState, useEffect, useRef } from 'react'
import { Treemap, Tooltip } from 'recharts'
import { Typography, IconButton, Button, Box } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { TreemapSkeleton } from '../../../components/Skeleton/index'

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const { name, value, score } = payload[0].payload
        return (
            <div
                style={{
                    backgroundColor: 'white',
                    border: '1px solid #ddd',
                    padding: '10px',
                    borderRadius: '5px',
                }}
            >
                <p>{`Sector: ${name}`}</p>
                <p>{`Value: ${value}`}</p>
                <p>{`Score: ${score}`}</p>
            </div>
        )
    }
    return null
}
const TreemapComponent = ({ data, onSectorClick }) => {
    const [width, setWidth] = useState(0)
    const containerRef = useRef(null)
    const [showSkeleton, setShowSkeleton] = useState(true)

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSkeleton(false)
        }, 1000)
        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setWidth(containerRef.current.offsetWidth - 25)
            }
        }

        updateWidth()
        window.addEventListener('resize', updateWidth)

        return () => window.removeEventListener('resize', updateWidth)
    }, [])

    return (
        <Box ref={containerRef}>
            {showSkeleton ? (
                <TreemapSkeleton />
            ) : (
                <Box
                    sx={{
                        p: 2,
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #e0e0e0',
                            pb: 1,
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 1,
                        }}
                    >
                        {/* Title and Info */}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    fontWeight: 600,
                                    color: '#6A6A6A',
                                    fontSize: { xs: '16px', sm: '18px' },
                                }}
                            >
                                Opportunities by sectors
                            </Typography>
                            <IconButton size="small" aria-label="More info">
                                <InfoOutlinedIcon fontSize="small" />
                            </IconButton>
                        </Box>

                        {/* View All Button */}
                        <Button
                            variant="contained"
                            size="small"
                            endIcon={<ArrowRightAltIcon />}
                            sx={{
                                textTransform: 'none',
                                fontWeight: 600,
                                backgroundColor: '#f2f2f2',
                                color: '#171717',
                                boxShadow: 'none',
                                fontSize: { xs: '12px', sm: '14px' },
                                px: { xs: 2, sm: 3 },
                                '&:hover': {
                                    backgroundColor: '#e0e0e0',
                                },
                            }}
                        >
                            View All
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            height: { xs: 300, md: 400 },
                            mt: 2,
                        }}
                    >
                        {width > 0 && (
                            <Treemap
                                width={width}
                                height={400}
                                data={data}
                                dataKey="value"
                                stroke="#fff"
                                fill="#fff"
                                content={props => {
                                    const { x, y, width, height, name, color, score, arrow } = props

                                    return (
                                        <g
                                            onClick={() => onSectorClick && onSectorClick(name)}
                                            style={{ cursor: onSectorClick ? 'pointer' : 'default' }}
                                        >
                                            {/* Rectangle */}
                                            <rect
                                                x={x}
                                                y={y}
                                                width={width}
                                                height={height}
                                                style={{
                                                    fill: color,
                                                    stroke: '#fff',
                                                    strokeWidth: 2,
                                                }}
                                            />
                                            {/* Div Content */}
                                            <foreignObject x={x} y={y} width={width} height={height}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        height: '100%',
                                                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                                        textAlign: 'center',
                                                        color: '#fff',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '12px',
                                                            fontWeight: 400,
                                                            marginBottom: '4px',
                                                        }}
                                                    >
                                                        {name}
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: '14px',
                                                            fontWeight: 'bold',
                                                            marginBottom: '4px',
                                                        }}
                                                    >
                                                        {arrow}
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: '10px',
                                                        }}
                                                    >
                                                        Kaitongo Score: {score}
                                                    </div>
                                                </div>
                                            </foreignObject>
                                        </g>
                                    )
                                }}
                            />
                        )}
                        <Tooltip content={<CustomTooltip />} />
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default TreemapComponent
