import React from 'react'
import { NavLink } from 'react-router-dom'
import { RenderDocumentTableActions } from '../components/RenderDocumentTableActions'

export const addFirmFields = {
    firmName: {
        name: 'firmName',
        placeholder: 'Firm Name',
        type: 'text',
        required: true,
        width: '50%',
    },
    contactName: {
        name: 'contactName',
        placeholder: 'Contact Name',
        type: 'text',
        required: true,
        width: '50%',
    },
    email: {
        name: 'email',
        placeholder: 'Email',
        type: 'email',
        required: true,
        width: '50%',
    },
    website: {
        name: 'website',
        placeholder: 'Firm Website',
        type: 'text',
        required: true,
        width: '50%',
    },
    country: {
        name: 'country',
        placeholder: 'Country',
        type: 'select',
        required: true,
        width: '50%',
    },
    description: {
        name: 'description',
        placeholder: 'Description',
        type: 'text',
        required: true,
        width: '50%',
    },
}

export const addUserFields = {
    firstName: {
        name: 'first_name',
        placeholder: 'First Name',
        type: 'text',
        required: true,
        width: '50%',
    },
    lastName: {
        name: 'last_name',
        placeholder: 'Last Name',
        type: 'text',
        required: true,
        width: '50%',
    },
    email: {
        name: 'email',
        placeholder: 'Email',
        type: 'email',
        required: true,
        width: '50%',
    },
    firm: {
        name: 'firm',
        placeholder: 'Firm',
        type: 'select',
        required: true,
        width: '50%',
    },
    practiceArea: {
        name: 'practiceArea',
        placeholder: 'Practice Area',
        type: 'select',
        required: true,
        width: '50%',
    },
    country: {
        name: 'country',
        placeholder: 'Country',
        type: 'select',
        required: true,
        width: '50%',
    },
    subscriptionType: {
        name: 'subscriptionType',
        placeholder: 'Subscription Type',
        type: 'select',
        required: true,
        width: '50%',
    },
    title: {
        name: 'title',
        placeholder: 'Title',
        type: 'text',
        required: true,
        width: '50%',
    },
    is_active: {
        name: 'is_active',
        placeholder: 'Is Active',
        type: 'select',
        required: true,
        width: '50%',
    },
    role: {
        name: 'role',
        placeholder: 'Role',
        type: 'select',
        required: true,
        width: '50%',
    },
    user_type: {
        name: 'user_type',
        placeholder: 'Category',
        type: 'select',
        required: true,
        width: '50%',
    },
}

export const endUserDataTableColumns = [
    {
        name: <label style={{ color: 'white' }}>Email</label>,
        selector: 'email',
        sortable: true,
    },
    {
        name: <label style={{ color: 'white' }}>Full Name</label>,
        // selector: 'first_name',
        sortable: true,
        cell: row => `${row.first_name} ${row.last_name}`,
    },
    {
        name: <label style={{ color: 'white' }}>User Type</label>,
        selector: 'user_type',
        sortable: true,
    },
    {
        name: <label style={{ color: 'white' }}>Status</label>,
        selector: 'is_active',
        sortable: true,
        cell: row => (
            <span style={{ color: row.is_active ? 'green' : 'red' }}>{row.is_active ? 'Active' : 'Inactive'}</span>
        ),
    },
    {
        name: <label style={{ color: 'white' }}> Scheduled</label>,
        selector: 'newsletter_scheduled',
        sortable: true,
        cell: row => {
            const showNo = !row.is_active && row.status_option === 'testing_true'
            return (
                <span style={{ marginLeft: '5px', color: showNo ? 'red' : row.newsletter_scheduled ? 'green' : 'red' }}>
                    {showNo ? 'No' : row.newsletter_scheduled ? 'Yes' : 'No'}
                </span>
            )
        },
    },

    {
        name: <label style={{ color: 'white' }}>Action</label>,
        sortable: false,
        cell: row => {
            return <NavLink to={`/app/admin/enduser/${row.id}`}>Edit</NavLink>
        },
    },
]

export const firmDataTableColumns = [
    {
        name: <label style={{ color: 'white' }}>Firm</label>,
        selector: 'label',
        sortable: true,
    },
    {
        name: <label style={{ color: 'white' }}>Contact Name</label>,
        selector: 'contact_name',
        sortable: true,
    },
    {
        name: <label style={{ color: 'white' }}>Contact Email</label>,
        selector: 'contact_email',
        sortable: true,
    },
    // {
    //     name: 'Country',
    //     selector: 'country',
    //     sortable: true,
    // },
    {
        name: <label style={{ color: 'white' }}>Action</label>,
        sortable: false,
        cell: row => {
            return <NavLink to={`/app/admin/add-firm/${row.value}`}>Edit</NavLink>
        },
    },
]

export const sectorDataTableColumns = [
    {
        name: <label style={{ color: 'white' }}>Sector Name</label>,
        selector: 'label',
        sortable: true,
        minWidth: '225px',
    },
    {
        name: <label style={{ color: 'white' }}>Nick Name</label>,
        selector: 'nick_name',
        sortable: true,
        minWidth: '200px',
    },
    {
        name: <label style={{ color: 'white' }}>Sector Type</label>,
        selector: 'type',
        sortable: true,
    },
    {
        name: <label style={{ color: 'white' }}>Status Visible</label>,
        selector: 'privacy',
        sortable: true,
    },
    {
        name: <label style={{ color: 'white' }}>Status</label>,
        sortable: true,
        cell: row => (row.status === 'True' ? 'Active' : 'Inactive'),
    },
    {
        name: <label style={{ color: 'white' }}>Checklist</label>,
        sortable: true,
        cell: row => {
            if (row.completion === true) {
                return <div style={{ color: 'green' }}>{'Complete'}</div>
            } else {
                return <div style={{ color: '#c15e5d' }}>{'Incomplete'}</div>
            }
        },
    },
    {
        name: <label style={{ color: 'white' }}>Action</label>,
        sortable: false,
        cell: row => {
            return <NavLink to={`/app/admin/add-sector/${row.type}/${row.value}`}>Edit</NavLink>
        },
    },
]

export const addSectorFields = {
    name: {
        name: 'name',
        placeholder: 'Sector Name',
        type: 'text',
        required: true,
        width: '50%',
    },
    industry: {
        name: 'industry',
        placeholder: 'Industry',
        type: 'select',
        required: true,
        width: '25%',
    },
    privacy: {
        name: 'privacy',
        placeholder: 'Privacy',
        type: 'select',
        required: true,
        width: '25%',
    },
    ipos: {
        name: 'num_of_ipos',
        placeholder: 'IPOs',
        type: 'text',
        required: true,
        width: '25%',
    },
    mas: {
        name: 'num_of_ma',
        placeholder: 'M&As',
        type: 'text',
        required: true,
        width: '25%',
    },
    privateFinancing: {
        name: 'private_financings',
        placeholder: 'Private Financing',
        type: 'text',
        required: true,
        width: '50%',
    },
    overview: {
        name: 'overview',
        placeholder: 'Overview',
        type: 'textarea',
        required: true,
        width: '100%',
    },
    nickname: {
        name: 'nickname',
        placeholder: 'Nickname eg. BLG-Life Sciences',
        type: 'text',
        required: true,
        width: '50%',
    },
    isActive: {
        name: 'isActive',
        placeholder: 'true',
        type: 'select',
        required: true,
        width: '25%',
    },
    taxonomy: {
        name: 'taxonomy',
        placeholder: 'standard',
        type: 'select',
        required: true,
        width: '25%',
    },
}

export const documentsDataTableColumns = [
    {
        name: 'Title',
        selector: 'title',
        sortable: true,
        maxWidth: '300px',
    },
    {
        name: 'Extension',
        selector: 'extension',
        sortable: true,
        maxWidth: '150px',
    },
    {
        name: 'Document',
        sortable: true,
        cell: row => {
            return (
                <a href={row.document} target="_blank" rel="noopener noreferrer">
                    {row.title}
                </a>
            )
        },
    },
    {
        name: 'Actions',
        sortable: false,
        cell: row => {
            return RenderDocumentTableActions(row)
        },
        maxWidth: '150px',
    },
]

export const contactsColumns = [
    {
        name: 'First Name',
        selector: 'first_name',
        sortable: true,
    },
    {
        name: 'Last Name',
        selector: 'last_name',
        sortable: true,
    },
    {
        name: 'Email',
        selector: 'email',
        sortable: true,
    },
    {
        name: 'Position',
        selector: 'position',
        sortable: true,
    },
    {
        name: 'Ready',
        selector: 'contact_ready',
        sortable: true,
        cell: row => {
            return row.contact_ready ? (
                <p style={{ color: 'green', marginTop: '16px' }}>Ready</p>
            ) : (
                <p style={{ color: 'red', marginTop: '16px' }}>Not Ready</p>
            )
        },
    },
    {
        name: 'Action',
        sortable: false,
        cell: row => {
            return <NavLink to={`/app/admin/add-contact/${row.id}`}>Edit</NavLink>
        },
    },
]

export const addContactFields = {
    firstName: {
        name: 'first_name',
        placeholder: 'First Name',
        type: 'text',
        required: true,
        width: '33.3%',
    },
    lastName: {
        name: 'last_name',
        placeholder: 'Last Name',
        type: 'text',
        required: true,
        width: '33.3%',
    },
    email: {
        name: 'email',
        placeholder: 'Email',
        type: 'email',
        required: true,
        width: '46.9%',
    },
    company: {
        name: 'company',
        placeholder: 'Company',
        type: 'text',
        required: true,
        width: '33.3%',
    },
    linkedIn: {
        name: 'linkedIn',
        placeholder: 'LinkedIn',
        type: 'text',
        required: true,
        width: '33.3%',
    },
    position: {
        name: 'position',
        placeholder: 'Position',
        type: 'text',
        required: true,
        width: '33.3%',
    },
    is_active: {
        name: 'is_active',
        placeholder: 'Is Active',
        type: 'select',
        required: true,
        width: '46.9%',
    },
    contactReady: {
        name: 'contact_ready',
        placeholder: 'Is Ready',
        type: 'select',
        required: true,
        width: '33.3%',
    },
}
