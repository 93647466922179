import axios from 'axios'
import React, { useState } from 'react'
import { BiEraser } from 'react-icons/bi'
import { Button } from '@mui/material'
import DataTable from 'react-data-table-component'
import { RiDeleteBin6Line } from 'react-icons/ri'
import CustomLoader from './CustomLoader'

export default function QueryBuilder(props) {
    const { setActiveTab, setBasicSearchInput, setQueryBuilderRun, setSemanticSaveModal } = props
    const { context, setContext, keywords, setKeywords, queryBuilderResposnse, setQueryBuilderResponse } = props
    const [loader, setLoader] = useState(false)

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#00193C', // Change to your desired header background color
                color: '#FFF', // Change to your desired header text color
                fontSize: '16px', // Change to your desired header font size
                height: '20px',
            },
        },
    }

    const queryBuilderSearch = () => {
        setQueryBuilderResponse([])
        setLoader(true)
        axios
            .post('/core/query-builder/', {
                context: context,
                keywords: keywords,
            })
            .then(res => {
                console.log(res)
                if (res.data && res.data.queries) {
                    setQueryBuilderResponse(res.data.queries)
                } else {
                    setQueryBuilderResponse([])
                }
            })
            .catch(err => console.log(err))
            .finally(() => {
                setLoader(false)
            })
    }

    const alertcolumns = [
        {
            name: <label style={{ color: 'white' }}>Search Query</label>,
            cell: row => row,
            width: '70%',
        },

        {
            name: <label style={{ color: 'white' }}>Actions</label>,
            cell: row => (
                <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: '#186ADE',
                            cursor: 'pointer',
                            fontSize: '14px',
                            textDecoration: 'underline',
                        }}
                        onClick={() => {
                            setBasicSearchInput(row)
                            setQueryBuilderRun(true)
                            setActiveTab(0)
                        }}
                    >
                        Run Search
                    </span>
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: '#186ADE',
                            cursor: 'pointer',
                            fontSize: '14px',
                            textDecoration: 'underline',
                            marginLeft: '10px',
                        }}
                        onClick={() => {
                            setBasicSearchInput(row)
                            setSemanticSaveModal(true)
                        }}
                    >
                        Save Search
                    </span>
                    {/* <RiDeleteBin6Line
                        style={{
                            marginLeft: '10px',
                            color: 'lightgray',
                            cursor: 'pointer',
                            fontSize: '16px',
                            marginLeft: '10px',
                        }}
                        title="delete"
                    /> */}
                </div>
            ),
            textAlign: 'center',
        },
    ]

    return (
        <div style={{ background: loader ? '' : 'white' }}>
            <div style={{ padding: '15px 10px 10px 10px', background: 'white' }}>
                <div className="context_field" style={{ marginTop: '20px' }}>
                    <div style={{ fontSize: '17px', fontWeight: 'bold', width: '95%', margin: 'auto' }}>
                        Enter Context
                    </div>
                    <div
                        style={{
                            width: '95%',
                            margin: '10px auto',
                            border: '1px solid #dad8d8',
                            borderRadius: '3px',
                            background: 'white',
                        }}
                    >
                        <textarea
                            className="context_textarea"
                            type="text"
                            value={context}
                            id="textarea_edit_query"
                            style={{
                                height: '70px',
                                width: '97%',
                                outline: 'none',
                                border: 'none',
                                resize: 'none',
                                background: 'white',
                            }}
                            onChange={e => {
                                setContext(e.target.value)
                            }}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()

                                    if (context !== '') {
                                        queryBuilderSearch()
                                    }
                                }
                            }}
                            placeholder="Enter Context for your search"
                        />

                        <span
                            className="remove_btn_query_editor"
                            style={{
                                position: 'absolute',
                                cursor: 'pointer',
                            }}
                        >
                            <BiEraser
                                style={{ fontSize: '20px' }}
                                onClick={() => {
                                    setContext('')
                                }}
                            />
                        </span>
                    </div>
                </div>
                <div className="query_field" style={{ marginTop: '20px' }}>
                    <div style={{ fontSize: '17px', fontWeight: 'bold', width: '95%', margin: 'auto' }}>
                        Enter keywords
                    </div>
                    <div
                        style={{
                            width: '95%',
                            margin: '10px auto',
                            border: '1px solid #dad8d8',
                            borderRadius: '3px',
                            background: 'white',
                        }}
                    >
                        <textarea
                            className="context_textarea"
                            type="text"
                            value={keywords}
                            id="textarea_edit_query"
                            style={{
                                height: '170px',
                                width: '97%',
                                outline: 'none',
                                border: 'none',
                                resize: 'none',
                                background: 'white',
                            }}
                            onChange={e => {
                                setKeywords(e.target.value)
                            }}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()

                                    if (context !== '') {
                                        queryBuilderSearch()
                                    }
                                }
                            }}
                            placeholder="Enter your current keywords or query if you have"
                        />

                        <span
                            className="remove_btn_query_editor"
                            style={{
                                position: 'absolute',
                                cursor: 'pointer',
                            }}
                        >
                            <BiEraser
                                style={{ fontSize: '20px' }}
                                onClick={() => {
                                    setKeywords('')
                                }}
                            />
                        </span>
                    </div>
                </div>
                <div style={{ width: '95%', margin: '15px auto', display: 'flex', justifyContent: 'end' }}>
                    <div>
                        <Button
                            variant="contained"
                            style={{
                                background: 'lightgray',
                                borderRadius: '4px',
                                width: 'max-content',
                                fontSize: '14px',
                                height: '40px',
                                color: 'black',
                                boxShadow: 'none',
                                textTransform: 'none',
                                opacity: 1,
                                padding: '0px 20px',
                                marginRight: '20px',
                            }}
                            onClick={() => {
                                setQueryBuilderResponse([])
                                setContext('')
                                setKeywords('')
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                background: '#186ADE',
                                borderRadius: '4px',
                                width: 'max-content',
                                fontSize: '14px',
                                height: '40px',
                                color: 'white',
                                boxShadow: 'none',
                                textTransform: 'none',
                                opacity: context === '' ? 0.6 : 1,
                                padding: '0px 20px',
                            }}
                            disabled={context === ''}
                            onClick={() => {
                                queryBuilderSearch()
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
            {loader && (
                <div
                    className="query_builder_response d-flex align-items-center justify-content-center"
                    style={{ width: '100%', margin: '20px auto', background: 'none' }}
                >
                    <CustomLoader />
                </div>
            )}
            {queryBuilderResposnse.length > 0 && (
                <div className="query_builder_response" style={{ width: '95%', margin: 'auto', background: 'white' }}>
                    <DataTable
                        columns={alertcolumns}
                        data={queryBuilderResposnse}
                        pagination
                        // paginationPerPage={rowsPerPage}
                        // onChangeRowsPerPage={currentRowsPerPage => setRowsPerPage(currentRowsPerPage)}
                        fixedHeader
                        customStyles={customStyles}
                        noHeader={true}
                    />
                </div>
            )}
        </div>
    )
}
