// src/components/OpportunitiesTemplate.jsx
import React, { useState, useMemo } from 'react'
import BaseScreen from '../BaseScreen/BaseScreen'
import TreemapComponent from './components/TreemapComponent'
import TargetCompanies from './components/TargetCompanies'
import SignalsTriggers from './components/SignalsTriggers'
import ChatbotModal from '../Chatbot/components/ChatbotModal'
import ChatIcon from '@mui/icons-material/Chat'
import { useHistory } from 'react-router-dom'
import {
    Box,
    Typography,
    TextField,
    InputAdornment,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Container,
    Grid,
    Fab,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export default function OpportunitiesTemplate() {
    const [open, setOpen] = useState(false)
    const history = useHistory()
    const [selectedPractice, setSelectedPractice] = useState('All')
    const signals = [
        {
            practiceArea: 'Capital Markets',
            title: 'Bitcoin Reaches Record High Amid Optimistic Projections',
            description:
                'Positive projections for industry growth encouraging businesses to acquire and strengthen positions.',
            sources: [
                'https://nypost.com/2024/12/04/business/bitcoin-tops-100000-for-first-time-on-optimism-over-trump-crypto-plans',
            ],
            date: 'Dec 5, 2024',
            opportunities:
                'Opportunity for financial advisory services in cryptocurrency investments and ETF development.',
            potentialContact: {
                name: 'Not Available',
                position: 'Not Available',
                phone: 'Not Available',
                email: 'Not Available',
            },
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'Nubank Introduces Cryptocurrency Swap Feature',
            description: 'Launch of new products, services, or market entries by established players.',
            sources: [
                'https://ca.marketscreener.com/quote/stock/NU-HOLDINGS-LTD-130481391/news/Nu-Nubank-launches-cryptocurrency-swap-tool-in-its-app-48339126',
            ],
            date: 'Dec 7, 2024',
            opportunities: 'Opportunity to advise on regulatory compliance and technological integration strategies.',
        },
        {
            practiceArea: 'Privacy & Cybersecurity',
            title: 'Insights Drive Crypto Trading Strategies',
            description: 'Reports highlighting widespread adoption of AI, blockchain, or IoT within industries.',
            sources: ['https://blockchain.news/flashnews/zachxbt-engages-with-crypto-community-member'],
            date: 'Dec 11, 2024',
            opportunities:
                'Opportunity to assist clients in leveraging blockchain insights to enhance trading algorithms.',
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'BIGG Digital Shows Revenue Growth in Q3',
            description:
                'Expansion of the overall market size in a particular industry, creating opportunities for acquisitions.',
            sources: [
                'https://uk.marketscreener.com/quote/cryptocurrency/BITCOIN-BTC-EUR-45553925/news/BIGG-Digital-Assets-in-Q3-Has-2nd-Straight-Quarter-of-7-Revenue-Growth-48491635',
            ],
            date: 'Dec 5, 2024',
            opportunities: 'Potential for strategic M&A opportunities or joint ventures in digital asset management.',
        },
        {
            practiceArea: 'Intellectual Property',
            title: 'AI-Driven Blockchain Gains Traction Among Investors',
            description: 'Reports highlighting widespread adoption of AI, blockchain, or IoT within industries.',
            sources: [
                'https://bitcoinist.com/why-traders-and-solana-whales-are-turning-to-this-new-defi-blockchain-in-december',
            ],
            date: 'Dec 7, 2024',
            opportunities: 'Opportunity to advise clients on adopting AI-powered blockchain solutions for scalability.',
        },
        {
            practiceArea: 'Regulatory',
            title: 'Trump Vows Crypto-Friendly Policies',
            description:
                'Policy changes or incentives promoting or restricting M&A activity in specific sectors or regions.',
            sources: [
                'https://www.dewittobserver.com/donald-trump-has-voed-to-make-the-united-states-since-the-november-5-election-of/image_73c7ff51-8aa9-50cc-a064-2c409a386874.html',
            ],
            date: 'Dec 5, 2024',
            opportunities:
                'Opportunity to explore advisory roles in navigating regulatory landscapes for crypto companies.',
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'Coinbase Enhances Institutional Digital Assets',
            description:
                'Companies acquiring tech startups or established firms to enhance digital transformation strategies.',
            sources: [
                'https://thepaypers.com/cryptocurrencies/coinbases-project-diamond-adopts-the-chainlink-standard--1271425',
            ],
            date: 'Dec 12, 2024',
            opportunities:
                'Opportunity to counsel on technology acquisitions and compliance for institutional digital assets.',
        },
        {
            practiceArea: 'Regulatory',
            title: 'India Advocates Phased CBDC Adoption',
            description: 'New regulations, policies, or compliance requirements affecting industry operations.',
            sources: ['https://www.paymentsjournal.com/a-cbdc-could-be-the-foundation-of-indias-future-economy'],
            date: 'Dec 12, 2024',
            opportunities:
                'Opportunity to assist financial institutions in compliance and adoption of CBDCs for cross-border payments.',
        },
        {
            practiceArea: 'Regulatory',
            title: 'Addressing Digital Payment Environmental Impact',
            description: 'Guidance on aligning operations with environmental, social, and governance goals.',
            sources: [
                'https://ffnews.com/newsarticle/sustainability/the-payments-association-shapes-the-future-of-sustainable-digital-payments',
            ],
            date: 'Dec 12, 2024',
            opportunities:
                'Opportunity to advise on frameworks for carbon measurement and sustainability in digital payments.',
        },
        {
            practiceArea: 'Intellectual Property',
            title: 'Blockchain Meets AI in Lightchain Protocol',
            description: 'Reports highlighting widespread adoption of AI, blockchain, or IoT within industries.',
            sources: [
                'https://bitcoinist.com/chainlink-developments-and-the-revolutionary-rise-of-lightchain-ai-in-cryptocurrency',
            ],
            date: 'Dec 10, 2024',
            opportunities:
                'Opportunity to counsel clients on integrating AI and blockchain for efficiency and transparency.',
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'Lightchain AI Disrupts DeFi with Innovation',
            description: 'Reports highlighting widespread adoption of AI, blockchain, or IoT within industries.',
            sources: [
                'https://bitcoinist.com/top-whale-wallets-and-defi-experts-agree-this-blockchain-could-be-the-next-big-thing',
            ],
            date: 'Dec 4, 2024',
            opportunities:
                'Opportunity to support clients entering decentralized finance with innovative blockchain solutions.',
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'Fintech Visionary Leads MDC’s Growth',
            description:
                'Announcements of key executive appointments or departures, which could indicate preparation for restructuring or M&A activity.',
            sources: [
                'https://www.cuinsight.com/press-release/mdc-taps-digital-banking-visionary-ned-tobey-for-ceo-role',
            ],
            date: 'Dec 14, 2024',
            opportunities:
                'Opportunity to assist with corporate strategies, digital banking initiatives, and leadership transitions.',
        },
        {
            practiceArea: 'Litigation & Dispute Resolution',
            title: 'Cryptocurrency Laundering Case Sparks Sentencing',
            description: 'High-profile lawsuits or settlements affecting companies or industries.',
            sources: [
                'https://www.justice.gov/usao-sdtx/pr/couple-sentenced-laundering-millions-drug-proceeds-using-virtual-currency',
            ],
            date: 'Dec 13, 2024',
            opportunities:
                'Opportunity to provide counsel on compliance and regulatory requirements in virtual currency operations.',
        },
        {
            practiceArea: 'Bankruptcy & Insolvency',
            title: 'Bankruptcy Insights in Cryptocurrency Assets',
            description: 'Reports of financial distress or bankruptcies in the sector, signaling market challenges.',
            sources: ['https://www.isba.org/barnews/2024/12/collectingjudgmentsbankruptcyissuesserieswhatyoune'],
            date: 'Dec 18, 2024',
            opportunities:
                'Opportunity to advise clients on cryptocurrency-related bankruptcy claims and forensic asset recovery.',
        },
        {
            practiceArea: 'Capital Markets',
            title: 'Resurgence of DeFi Bluechip Assets',
            description: 'Reports highlighting widespread adoption of AI, blockchain, or IoT within industries.',
            sources: ['https://blockchain.news/flashnews/defi-bluechips-resurgence-analyzed-by-intotheblock'],
            date: 'Dec 14, 2024',
            opportunities: 'Opportunity to assist clients in navigating DeFi investment and development opportunities.',
        },
        {
            practiceArea: 'Privacy & Cybersecurity',
            title: 'Novacrypt Integrates AI to Transform Crypto Trading',
            description: 'Reports highlighting widespread adoption of AI, blockchain, or IoT within industries.',
            sources: [
                'https://www.globenewswire.com/news-release/2024/12/18/2999267/0/en/Novacrypt-NOVA-Transforming-Digital-Asset-Markets.html',
            ],
            date: 'Dec 18, 2024',
            opportunities:
                'Opportunity to advise clients on integrating emerging technologies to enhance trading platforms and user experience.',
        },
        {
            practiceArea: 'Financial Advisory',
            title: 'Positive Performance Signals in the Cryptocurrency Sector',
            description:
                'Positive projections for industry growth encouraging businesses to acquire and strengthen their positions.',
            sources: [
                'https://blockchain.news/flashnews/basket-performance-cryptocurrency-sector-returns-for-the-month',
            ],
            date: 'Dec 14, 2024',
            opportunities:
                'Opportunity to provide market intelligence and investment advisory for strategic positioning in high-return sectors.',
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'KEO World Expands B2B Payment Solutions to Brazil',
            description:
                'A company entering partnerships or alliances, which may indicate preparation for mergers or acquisitions.',
            sources: [
                'https://www.finextra.com/pressarticle/103645/b2b-digital-payments-plaer-keo-world-expands-to-brazil',
            ],
            date: 'Dec 18, 2024',
            opportunities:
                'Opportunity to assist with cross-border compliance and partnership structuring to optimize operations.',
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'Temenos-Taurus Integration Enables Digital Asset Services',
            description:
                'Companies acquiring tech startups or established firms to enhance digital transformation strategies.',
            sources: [
                'https://thepaypers.com/cryptocurrencies/taurus-partners-with-temenos-to-offer-digital-asset-custody-to-banks--1271604',
            ],
            date: 'Dec 20, 2024',
            opportunities:
                'Opportunity to guide clients in implementing integrated platforms and leveraging digital asset management innovations.',
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'Phoenix Group Appoints Munaf Ali as CEO for Expansion',
            description:
                'Announcements of key executive appointments or departures, which could indicate preparation for restructuring or M&A activity.',
            sources: [
                'https://www.globenewswire.com/news-release/2024/12/20/3000410/0/en/Phoenix-Group-Appoints-Munaf-Ali-as-New-CEO-to-Drive-Growth-Amid-Increasing-Institutional-Global-Cryptocurrency-Adoption.html',
            ],
            date: 'Dec 20, 2024',
            opportunities:
                'Opportunity to explore legal and advisory roles in facilitating growth strategies, including dual listings on NASDAQ.',
        },
        {
            practiceArea: 'Intellectual Property',
            title: '$DST Cryptocurrency Prepares for Launch',
            description: 'Developments in cryptocurrency-backed fundraising efforts.',
            sources: [
                'https://bitcoinist.com/blockchain-news-everything-you-need-to-know-about-new-cryptocurrency-the-deep-state-dst',
            ],
            date: 'Dec 19, 2024',
            opportunities:
                'Opportunity to advise on regulatory compliance and market entry strategies for cryptocurrency launches.',
        },
        {
            practiceArea: 'Litigation & Dispute Resolution',
            title: 'Ponzi Scheme Leaders Sentenced for Fraudulent Activities',
            description: 'High-profile lawsuits or settlements affecting companies or industries.',
            sources: [
                'https://www.justice.gov/opa/pr/two-colorado-men-charged-operating-multi-million-dollar-investment-fraud-scheme',
            ],
            date: 'Dec 19, 2024',
            opportunities:
                'Opportunity to support compliance programs and litigation strategies to mitigate risks of fraudulent activities.',
        },
        {
            practiceArea: 'Regulatory',
            title: 'Regulatory Scrutiny on BNPL Industry',
            description: 'New regulations, policies, or compliance requirements affecting industry operations.',
            sources: [
                'https://thepaypers.com/thought-leader-insights/buy-now-pay-later-the-shifting-paradigm--1271538',
            ],
            date: 'Dec 18, 2024',
            opportunities:
                'Opportunity to advise BNPL providers on compliance strategies and adapting to regulatory requirements.',
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'Fintech Revolutionizes Financial Services',
            description: 'Reports highlighting widespread adoption of AI, blockchain, or IoT within industries.',
            sources: [],
            date: 'Dec 24, 2024',
            opportunities:
                'Opportunity to provide advisory services on adopting new technologies to remain competitive.',
        },
        {
            practiceArea: 'Capital Markets',
            title: 'Digital Assets Poised for Continued Growth',
            description:
                'Positive projections for industry growth encouraging businesses to acquire and strengthen their positions.',
            sources: [
                'https://news.bloomberglaw.com/financial-accounting/bitcoin-digital-assets-will-continue-to-develop-putin-says',
            ],
            date: 'Dec 5, 2024',
            opportunities: 'Opportunity to explore investments and strategic expansions in the digital asset space.',
        },
        {
            practiceArea: 'Regulatory',
            title: 'Trump Nominates Crypto Advocate as SEC Chair',
            description:
                'Announcements of key executive appointments or departures, which could indicate preparation for restructuring or M&A activity.',
            sources: [
                'https://www.dallasnews.com/news/politics/2024/12/04/trump-picks-former-commissioner-paul-atkins-to-run-sec',
            ],
            date: 'Dec 10, 2024',
            opportunities:
                'Opportunity to anticipate regulatory shifts favoring cryptocurrency under the new SEC leadership.',
        },
        {
            practiceArea: 'Capital Markets',
            title: 'Solana Tokens Drive Crypto Innovation',
            description: 'Reports highlighting widespread adoption of AI, blockchain, or IoT within industries.',
            sources: [
                'https://www.crypto-news-flash.com/crypto-traders-100m-portfolio-strategy-dogen-and-other-solana-based-tokens-to-surge-1500x',
            ],
            date: 'Dec 6, 2024',
            opportunities:
                'Opportunity to guide clients in capitalizing on emerging crypto trends and innovative token technologies.',
        },
        {
            practiceArea: 'Financial Advisory',
            title: 'WallitIQ Raises $1.5M to Simplify Crypto Investing',
            description:
                'News about startups or companies raising significant funding, often leading to growth and increased hiring.',
            sources: [
                'https://www.crypto-news-flash.com/wallitiq-wltq-redefines-crypto-and-defi-investing-for-regular-investors-with-innovative-wallet-raises-1500000-2',
            ],
            date: 'Dec 6, 2024',
            opportunities: 'Opportunity to support scaling operations and technology deployment for crypto startups.',
        },
        {
            practiceArea: 'Litigation & Dispute Resolution',
            title: 'Bankruptcy Court Approves Service via NFTs',
            description: 'New regulations, policies, or compliance requirements affecting industry operations.',
            sources: ['https://www.jdsupra.com/legalnews/new-york-bankruptcy-court-authorizes-9607199/'],
            date: 'Dec 9, 2024',
            opportunities:
                'Opportunity to provide guidance on leveraging blockchain for legal compliance and dispute resolution.',
        },
        {
            practiceArea: 'Capital Markets',
            title: 'Blue-Chip Cryptos Predicted to Soar',
            description:
                'Positive projections for industry growth encouraging businesses to acquire and strengthen their positions.',
            sources: [],
            date: 'Dec 6, 2024',
            opportunities:
                'Opportunity to advise on strategic investments in blue-chip cryptocurrencies for long-term growth.',
        },
        {
            practiceArea: 'Financial Advisory',
            title: 'Banking Digitization and Workforce Upskilling',
            description:
                'News about companies launching upskilling or reskilling initiatives to support recruitment and workforce growth.',
            sources: [],
            date: 'Dec 6, 2024',
            opportunities:
                'Opportunity to assist banks in workforce transformation strategies and AI-driven digital adoption.',
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'Waaree Collaborates with Jindal for 2-GW Solar Park',
            description: 'Formation of strategic collaborations or joint ventures.',
            sources: ['https://list.solar/news/waaree-secures-1'],
            date: 'Jan 1, 2025',
            opportunities:
                'Opportunity to advise on joint venture agreements and support renewable energy development.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Litigation & Dispute Resolution',
            title: 'Nano Nuclear Faces Class Action Lawsuit',
            description: 'Large-scale lawsuits involving multiple plaintiffs against a company.',
            sources: [
                'https://ca.marketscreener.com/quote/stock/ENLINK-MIDSTREAM-LLC-15982511/news/SHAREHOLDER-INVESTIGATION-Halper-Sadeh-LLC-Investigates-BCOV-ENLC-CCRN-on-Behalf-of-Shareholders-48534606',
            ],
            date: 'Dec 30, 2024',
            opportunities:
                'Opportunity to represent investors or provide legal defense for Nano Nuclear Energy in securities litigation.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Financial Advisory',
            title: 'Brookfield Expands Renewable Energy Portfolio',
            description:
                'Rise in the number of announced mergers and acquisitions, indicating heightened activity and confidence in the market.',
            sources: ['https://list.solar/news/brookfield-completes'],
            date: 'Dec 28, 2024',
            opportunities:
                'Opportunity to provide M&A advisory services and support post-acquisition integration strategies.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'Adani Launches AGE68L Renewable Energy Arm',
            description:
                'Entry of new companies or investors into the market, increasing competition and deal-making activity.',
            sources: [
                'https://www.financialexpress.com/business/industry-adani-green-energy-incorporates-new-subsidiary-for-renewable-energy-3702361',
            ],
            date: 'Dec 29, 2024',
            opportunities: 'Opportunity to assist with regulatory compliance and setup for new energy ventures.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Regulatory',
            title: 'Mexico’s President Aims for Renewable Energy Revolution',
            description:
                'Policy changes or incentives promoting or restricting M&A activity in specific sectors or regions.',
            sources: ['https://energydigital.com/sustainability/the-energy-digital-year-in-stories-june'],
            date: 'Dec 28, 2024',
            opportunities:
                'Opportunity to advise on policy-driven renewable energy projects and investments in Mexico.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Financial Advisory',
            title: 'Green Hydrogen Market Forecast to Skyrocket',
            description:
                'Positive projections for industry growth encouraging businesses to acquire and strengthen their positions.',
            sources: [
                'https://www.einpresswire.com/article/773480967/green-hydrogen-market-the-next-frontier-in-clean-energy',
            ],
            date: 'Jan 2, 2025',
            opportunities: 'Opportunity to advise on investment strategies in green hydrogen to capture market growth.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Privacy & Cybersecurity',
            title: 'Cellnex Drives Sustainability with Renewable Energy Use',
            description: 'Reports highlighting widespread adoption of AI, blockchain, or IoT within industries.',
            sources: ['https://www.rcrwireless.com/20250101/fundamentals/cellnex-renewable-energy'],
            date: 'Jan 2, 2025',
            opportunities:
                'Opportunity to assist with technology adoption and renewable energy strategies for infrastructure networks.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Regulatory',
            title: 'EPA Greenlights Carbon Sequestration in California',
            description:
                'Updates on government or regulatory bodies approving or blocking proposed mergers due to antitrust concerns.',
            sources: [
                'https://pgjonline.com/news/2025/january/epa-issues-first-underground-injection-permits-for-carbon-storage-in-california',
            ],
            date: 'Jan 2, 2025',
            opportunities:
                'Opportunity to support compliance and implementation of carbon capture projects under regulatory frameworks.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Management Consulting',
            title: 'Solar and Wind Projects Expand Across Poland',
            description:
                'Expansion of the overall market size in a particular industry, creating opportunities for strategic acquisitions.',
            sources: ['https://www.greenenergytimes.org/2024/12/december-28-green-energy-news-12'],
            date: 'Dec 29, 2024',
            opportunities:
                'Opportunity to support developers and investors in scaling renewable energy projects in emerging markets.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'Hero Group Secures Solar PPAs in Karnataka',
            description:
                'Announcements of major new contracts or business wins, driving the need for additional workforce capacity.',
            sources: [
                'https://www.thehindu.com/news/national/karnataka/hero-group-commissions-solar-project-in-chitradurga/article69036889.ece',
            ],
            date: 'Dec 29, 2024',
            opportunities:
                'Opportunity to provide legal and regulatory guidance on Power Purchase Agreements and project execution.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Financial Advisory',
            title: 'Lithium-Ion Battery Market Sees Rapid Growth',
            description:
                'Positive projections for industry growth encouraging businesses to acquire and strengthen their positions.',
            sources: [
                'https://www.globenewswire.com/news-release/2024/12/30/3002515/28124/en/India-Lithium-Ion-Battery-Industry-Research-2024-2029-Innovations-in-Fast-Charging-Propel-Growth-Government-Policies-Fuel-Expansion.html',
            ],
            date: 'Dec 30, 2024',
            opportunities:
                "Opportunity to advise on investments and partnerships in India's growing lithium-ion battery market.",
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'Altus Power Expands Community Solar Portfolio',
            description:
                'Rise in the number of announced mergers and acquisitions, indicating heightened activity and confidence in the market.',
            sources: [
                'https://ca.marketscreener.com/quote/stock/ALTUS-POWER-INC-130552220/news/Altus-Power-Strengthens-its-Presence-in-Maine-with-Operationalization-of-Three-New-Community-Solar-P-48531887',
            ],
            date: 'Dec 29, 2024',
            opportunities:
                'Opportunity to support Altus Power in scaling its solar portfolio and managing community energy contracts.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Regulatory',
            title: 'LIPA Approves Battery Storage Projects',
            description:
                'Updates on government or regulatory bodies approving or blocking proposed mergers due to antitrust concerns.',
            sources: ['https://list.solar/news/lipa-greenlights'],
            date: 'Jan 1, 2025',
            opportunities:
                'Opportunity to advise on compliance and implementation of battery storage projects in New York.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Privacy & Cybersecurity',
            title: 'Lincoln Electric Advances Battery Storage Pilot',
            description:
                'News about significant investments in new technologies, such as cloud computing, AI, or IoT, signaling digital transformation efforts.',
            sources: [
                'https://journalstar.com/news/local/lincoln-electric-system-implementing-new-battery-storage-project-in-haymarket/article_9e9c9182-b8cf-11ef-a9e7-7be372bea765.html',
            ],
            date: 'Dec 29, 2024',
            opportunities:
                'Opportunity to support technology adoption and energy management strategies in pilot projects.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Regulatory',
            title: 'Food Waste Management Requires Policy Overhaul',
            description: 'New regulations, policies, or compliance requirements affecting industry operations.',
            sources: ['https://www.bioenergyconsult.com/trends-in-food-waste-management'],
            date: 'Dec 28, 2024',
            opportunities: 'Opportunity to provide guidance on regulatory compliance and waste recycling initiatives.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Management Consulting',
            title: 'Europe Faces Wood Chip Supply Challenges',
            description: 'Investments or strategies to strengthen supply chain logistics.',
            sources: [
                'https://www.argusmedia.com:443/ja/news-and-insights/latest-market-news/2642484-viewpoint-wood-chip-supply-may-tighten-in-2025',
            ],
            date: 'Dec 28, 2024',
            opportunities:
                'Opportunity to assist companies in securing alternative supply chains and managing rising costs.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'OX2 Secures Renewable Energy Contracts in Poland',
            description:
                'Announcements of major new contracts or business wins, driving the need for additional workforce capacity.',
            sources: ['https://list.solar/news/ox2-secures-205mw'],
            date: 'Dec 28, 2024',
            opportunities: 'Opportunity to advise on contract management and renewable energy project execution.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'ARRAY and RP Reach 6GW Solar Milestone',
            description: 'Formation of strategic collaborations or joint ventures.',
            sources: [
                'https://ca.marketscreener.com/quote/stock/ARRAY-TECHNOLOGIES-INC-8433/news/ARRAY-Technologies-and-RP-Surpass-6GW-of-Solar-Power-Deployed-48522528',
            ],
            date: 'Dec 29, 2024',
            opportunities: 'Opportunity to support partnership agreements and renewable energy scaling initiatives.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Capital Markets',
            title: 'Waste Management Increases Dividend Amid Market Leadership',
            description: 'Updates on companies increasing, decreasing, or suspending dividend payouts.',
            sources: [
                'https://uk.marketscreener.com/quote/stock/WASTE-MANAGEMENT-INC-14885/news/Waste-Management-Ups-Quarterly-Dividend-10-to-0-825-a-Share-Payable-in-March-Suspends-Share-Repu-48603168',
            ],
            date: 'Dec 31, 2024',
            opportunities:
                'Opportunity to assist in financial planning and investor relations strategy for market leaders.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Financial Advisory',
            title: 'ContourGlobal Expands with 888-MW Solar-Storage Acquisition',
            description:
                'Rise in the number of announced mergers and acquisitions, indicating heightened activity and confidence in the market.',
            sources: ['https://list.solar/news/contourglobal-1'],
            date: 'Jan 1, 2025',
            opportunities:
                'Opportunity to provide M&A advisory services and integration strategies for hybrid solar-storage projects.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'Hy-Tek and Forvis Mazars Partner to Advance Supply Chain Tech',
            description: 'Formation of strategic collaborations or joint ventures.',
            sources: [
                'https://www.mmh.com/article/hy_tek_intralogistics_partners_with_forvis_mazars_on_integrated_solution_deployments/technology',
            ],
            date: 'Jan 1, 2025',
            opportunities:
                'Opportunity to advise on partnership agreements and explore synergies for technological advancements.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Litigation & Dispute Resolution',
            title: 'Honeywell Faces Environmental Lawsuit in Brunswick',
            description: 'High-profile lawsuits or settlements affecting companies or industries.',
            sources: ['https://dockets.justia.com/docket/georgia/gandce/1:2024cv05524/336990'],
            date: 'Dec 6, 2024',
            opportunities:
                'Opportunity to represent clients in environmental litigation or assist in regulatory compliance.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Intellectual Property',
            title: 'Graphlit Launches RAG-as-a-Service Toolkit',
            description: 'Reports highlighting widespread adoption of AI, blockchain, or IoT within industries.',
            sources: [
                'https://www.chewelahindependent.com/online_features/press_releases/graphlit-unveils-agent-tools-library-to-streamline-unstructured-data-ingestion-and-ai-agent-workflows/article_5adc82ed-daaa-5968-8d72-d44b6695b9b3.html',
            ],
            date: 'Jan 2, 2025',
            opportunities:
                'Opportunity to advise on IP protection, cybersecurity compliance, and licensing agreements.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Financial Advisory',
            title: 'PSI Software Sells PSI Transcom to Altamount',
            description:
                'Rise in the number of announced mergers and acquisitions, indicating heightened activity and confidence in the market.',
            sources: [
                'https://in.marketscreener.com/quote/stock/PSI-SOFTWARE-SE-5357539/news/PSI-Software-Creates-New-Grid-Energy-Management-Unit-48501260',
            ],
            date: 'Jan 1, 2025',
            opportunities:
                'Opportunity to provide M&A advisory services, including deal structuring and due diligence.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Privacy & Cybersecurity',
            title: 'JPMorgan Expands AI Integration',
            description: 'Reports highlighting widespread adoption of AI, blockchain, or IoT within industries.',
            sources: [
                'https://ca.marketscreener.com/quote/stock/S-P-GLOBAL-INC-27377749/news/S-P-Global-Market-Intelligence-expects-2024-election-outcomes-and-multidimensional-risk-factors-to-d-48531693',
            ],
            date: 'Dec 11, 2024',
            opportunities:
                'Opportunity to support technology implementation, compliance, and workforce training initiatives.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'Microsoft Highlights Xbox Player Achievements',
            description: 'Launch of new products, services, or market entries by established players.',
            sources: [
                'https://www.marketscreener.com/quote/stock/MICROSOFT-CORPORATION-4835/news/Microsoft-Unlock-your-personalized-Xbox-stats-from-2024-48524757',
            ],
            date: 'Dec 5, 2024',
            opportunities:
                'Opportunity to assist in marketing strategies and explore partnership opportunities for expansion.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Privacy & Cybersecurity',
            title: 'Oracle Patches Critical Vulnerability',
            description: 'New regulations, policies, or compliance requirements affecting industry operations.',
            sources: [
                'https://securityaffairs.com/171851/hacking/u-s-cisa-adds-microsoft-windows-clfs-driver-flaw-to-its-known-exploited-vulnerabilities-catalog.html',
            ],
            date: 'Dec 11, 2024',
            opportunities: 'Opportunity to advise on cybersecurity compliance and risk mitigation strategies.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Intellectual Property',
            title: 'Generative AI Drives Efficiency in Legal Teams',
            description: 'Reports highlighting widespread adoption of AI, blockchain, or IoT within industries.',
            sources: [
                'https://todaysgeneralcounsel.com/legal-evolution-how-generative-ai-is-shaping-the-future-of-e-discovery',
            ],
            date: 'Dec 14, 2024',
            opportunities:
                'Opportunity to provide legal teams with training, compliance advice, and tech adoption strategies.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Financial Advisory',
            title: 'Salesforce Reports Record Earnings Growth',
            description:
                'Coverage of companies achieving or nearing profitability, a common prerequisite for attracting IPO investors.',
            sources: [
                'https://www.marketscreener.com/quote/stock/SPRINKLR-INC-124007880/news/Sprinklr-Inc-Reports-Earnings-Results-for-the-Third-Quarter-and-Nine-Months-Ended-October-31-2024-48526119',
            ],
            date: 'Dec 5, 2024',
            opportunities:
                'Opportunity to provide strategic financial advisory services to sustain growth and attract investments.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Financial Advisory',
            title: 'Goldman Sachs Maintains Positive Salesforce Outlook',
            description:
                'Positive projections for industry growth encouraging businesses to acquire and strengthen their positions.',
            sources: [
                'https://uk.marketscreener.com/quote/stock/SALESFORCE-COM-INC-12180/news/SALESFORCE-Goldman-Sachs-reiterates-its-Buy-rating-48520275',
            ],
            date: 'Dec 5, 2024',
            opportunities:
                'Opportunity to guide investors and clients on strategic investments based on growth projections.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Litigation & Dispute Resolution',
            title: 'Kidnapping Case Highlights Need for Enhanced Security',
            description: 'High-profile lawsuits or settlements affecting companies or industries.',
            sources: [
                'https://www.dallasnews.com/news/courts/2024/12/06/man-pleads-guilty-to-forcing-lyft-driver-to-travel-to-florida-for-ransom-plot',
            ],
            date: 'Dec 7, 2024',
            opportunities:
                'Opportunity to advise rideshare companies on compliance with security and safety regulations.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Privacy & Cybersecurity',
            title: 'Microsoft Drives AI Innovation with New Resources',
            description: 'Reports highlighting widespread adoption of AI, blockchain, or IoT within industries.',
            sources: [
                'https://uk.marketscreener.com/quote/stock/MICROSOFT-CORPORATION-4835/news/Microsoft-Unveiling-the-future-of-AI-innovation-for-independent-software-vendors-48545984',
            ],
            date: 'Dec 7, 2024',
            opportunities: 'Opportunity to assist software firms in implementing AI solutions and ensuring compliance.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'SAP Partners with AWS for SMB ERP Access',
            description: 'Formation of strategic collaborations or joint ventures.',
            sources: [
                'https://www.techtarget.com/searchsap/news/366616858/Grow-with-SAP-expands-cloud-ERP-access-for-SMBs-on-AWS',
            ],
            date: 'Dec 7, 2024',
            opportunities:
                'Opportunity to advise on partnership agreements and assist SMBs in leveraging cloud-based ERP systems.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Regulatory',
            title: 'SAP Executive Disposes of Shares',
            description: 'News about accusations or evidence of insider trading by executives or employees.',
            sources: [
                'https://ca.marketscreener.com/quote/stock/SAP-SE-436555/news/DD-SAP-SE-Pascal-Demat-sell-48447012',
            ],
            date: 'Dec 6, 2024',
            opportunities:
                'Opportunity to provide corporate governance and regulatory compliance advice for similar transactions.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Corporate & Commercial',
            title: 'Honeywell to Exit PPE Business',
            description:
                'Reports about a company selling off non-core assets or divisions, often as a precursor to an acquisition or merger strategy.',
            sources: [
                'https://uk.marketscreener.com/quote/stock/HONEYWELL-INTERNATIONAL-I-4827/news/Top-Midday-Stories-Intuit-Shares-Fall-After-Q2-Guidance-Falls-Short-of-Expectations-Honeywell-to-S-48440164',
            ],
            date: 'Dec 8, 2024',
            opportunities: 'Opportunity to advise on M&A strategies or restructuring of remaining business lines.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Regulatory',
            title: 'Lyft CEO Advocates for Federal Autonomous Car Regulations',
            description: 'Draft bills or proposed legal amendments, often indicating upcoming regulatory changes.',
            sources: [
                'https://www.marketscreener.com/quote/stock/LYFT-INC-56481539/news/Lyft-CEO-Wants-Federal-Autonomous-Car-Regulation-48297886',
            ],
            date: 'Dec 8, 2024',
            opportunities:
                'Opportunity to advise on regulatory strategies and compliance for autonomous vehicle adoption.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Privacy & Cybersecurity',
            title: 'TISA Modernizes Banking with Oracle Solutions',
            description:
                'News about significant investments in new technologies, such as cloud computing, AI, or IoT, signaling digital transformation efforts.',
            sources: [
                'https://in.marketscreener.com/quote/stock/ORACLE-CORPORATION-13620698/news/Large-Savings-Loan-Society-Modernises-its-Core-Banking-with-Oracle-FLEXCUBE-48531774',
            ],
            date: 'Dec 6, 2024',
            opportunities:
                'Opportunity to assist financial institutions with technology implementation and cybersecurity upgrades.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
        {
            practiceArea: 'Regulatory',
            title: 'Microsoft Participates in Annual TMT Summit',
            description:
                'Coverage of lobbying or advocacy by industry groups to influence or respond to upcoming policy changes.',
            sources: [
                'https://uk.marketscreener.com/quote/stock/MICROSOFT-CORPORATION-4835/news/Transcript-Microsoft-Corporation-Presents-at-Wells-Fargo-8th-Annual-TMT-Summit-Dec-03-2024-12-00-48515818',
            ],
            date: 'Dec 9, 2024',
            opportunities: 'Opportunity to provide insights on industry trends and regulatory changes in TMT sectors.',
            potentialContact: {
                name: '',
                position: '',
                phone: '',
                email: '',
            },
        },
    ]
    const companies = [
        { name: 'Nubank', website: 'nubank.com', opportunity: 'Low', update: 'Dec 7, 2024' },
        { name: 'Temenos AG', website: 'temenos.com', opportunity: 'Medium', update: 'Dec 20, 2024' },
        {
            name: 'Phoenix Group Holdings Plc',
            website: 'thephoenixgroup.com',
            opportunity: 'High',
            update: 'Dec 20, 2024',
        },
        { name: 'Coinbase', website: 'coinbase.com', opportunity: 'High', update: 'Dec 12, 2024' },
        {
            name: 'Adani Green Energy Limited',
            website: 'adanigreenenergy.com',
            opportunity: 'Low',
            update: 'Dec 29, 2024',
        },
        { name: 'Altus Power Inc.', website: 'altuspower.com', opportunity: 'High', update: 'Dec 29, 2024' },
        { name: 'Array Technologies Inc.', website: 'arraytechinc.com', opportunity: 'Medium', update: 'Dec 29, 2024' },
        {
            name: 'Nano Nuclear Energy Inc',
            website: 'nanonuclearenergy.com',
            opportunity: 'High',
            update: 'Dec 30, 2024',
        },
        { name: 'Ox2', website: 'ox2.com', opportunity: 'Medium', update: 'Dec 28, 2024' },
        { name: 'Honeywell International Inc.', website: 'honeywell.com', opportunity: 'High', update: 'Dec 6, 2024' },
        { name: 'Microsoft Corporation', website: 'microsoft.com', opportunity: 'High', update: 'Dec 7, 2024' },
        { name: 'PSI Software', website: 'psi.de', opportunity: 'Medium', update: 'Jan 1, 2025' },
    ]
    const treeMapData = [
        { name: 'Fintech', value: 35, color: '#1ABF63', score: '10/10', arrow: '↑' },
        { name: 'Pharmaceuticals & BioTech', value: 15, color: '#34A853', score: '8/10', arrow: '↔' },
        { name: 'Renewable and Cleantech', value: 15, color: '#FF6634', score: '7/10', arrow: '↔' },
        { name: 'Cloud Technology', value: 20, color: '#DDAD00', score: '6/10', arrow: '↔' },
        { name: 'Software', value: 20, color: '#DC572C', score: '5/10', arrow: '↓' },
        { name: 'Real Estate', value: 20, color: '#FF0000', score: '4/10', arrow: '↓' },
    ]

    const parseDate = dateString => {
        const [month, day, year] = dateString.split(' ')
        return new Date(`${month} ${day.replace(',', '')}, ${year}`)
    }
    const handleSectorRedirect = sectorName => {
        // Define redirection logic
        history.push(`/app/admin/sectors?name=${encodeURIComponent(sectorName)}`)
    }
    // Sort signals by date in descending order
    const sortedSignals = useMemo(() => {
        return signals.sort((a, b) => parseDate(b.date) - parseDate(a.date))
    }, [signals])

    // Filter signals based on the selected practice area
    const filteredSignals = useMemo(() => {
        return selectedPractice === 'All'
            ? sortedSignals
            : sortedSignals.filter(signal => signal.practiceArea === selectedPractice)
    }, [selectedPractice, sortedSignals])

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    // Extract unique practice areas for the dropdown
    const practiceAreas = useMemo(() => {
        const uniqueAreas = new Set(sortedSignals.map(signal => signal.practiceArea))
        return ['All', ...uniqueAreas]
    }, [sortedSignals])
    return (
        <BaseScreen>
            <Box sx={{ background: 'white', pb: 4, marginTop: '-19px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #ddd',
                        padding: '16px',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 2,
                    }}
                >
                    {/* Title */}
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 600,
                            color: '#6A6A6A',
                            fontFamily: 'Roboto, Arial, sans-serif',
                            textAlign: { xs: 'center', md: 'left' },
                        }}
                    >
                        Opportunities
                    </Typography>

                    {/* Search Field */}
                    <TextField
                        variant="outlined"
                        placeholder="Ask a question"
                        size="small"
                        onClick={handleOpen}
                        sx={{
                            width: { xs: '100%', md: '600px' },
                            backgroundColor: '#f9f9f9',
                            borderRadius: '20px',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '20px',
                                padding: '4px 12px',
                                backgroundColor: '#f9f9f9',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e0e0e0',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e0e0e0',
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke="url(#:r1k:)"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        aria-label="Search Icon"
                                    >
                                        <defs>
                                            <linearGradient id=":r1k:" x1="0%" y1="0%" x2="100%" y2="0%">
                                                <stop offset="0%" style={{ stopColor: 'rgb(14, 165, 233)' }} />
                                                <stop offset="50%" style={{ stopColor: 'rgb(99, 102, 241)' }} />
                                                <stop offset="100%" style={{ stopColor: 'rgb(168, 85, 247)' }} />
                                            </linearGradient>
                                        </defs>
                                        <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
                                        <path d="M5 3v4" stroke="#0ea5e9" />
                                        <path d="M19 17v4" stroke="#a855f7" />
                                        <path d="M3 5h4" stroke="#6366f1" />
                                        <path d="M17 19h4" stroke="#6366f1" />
                                    </svg>
                                </InputAdornment>
                            ),
                        }}
                    />

                    {/* Filters */}
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                        }}
                    >
                        <FormControl variant="outlined" size="small" sx={{ minWidth: 120, flexGrow: 1 }}>
                            <InputLabel>Practice Filter</InputLabel>
                            <Select
                                value={selectedPractice}
                                onChange={e => setSelectedPractice(e.target.value)}
                                label="Practice Filter"
                            >
                                {practiceAreas.map((area, index) => (
                                    <MenuItem key={index} value={area}>
                                        {area}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl size="small" variant="outlined" sx={{ minWidth: 120, flexGrow: 1 }}>
                            <InputLabel>Time Period</InputLabel>
                            <Select defaultValue="Last Month" label="Time Period">
                                <MenuItem value="Last Month">Last Month</MenuItem>
                                <MenuItem value="Last 6 Months">Last 6 Months</MenuItem>
                                <MenuItem value="Last Year">Last Year</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                {/* Main Content */}
                <Container maxWidth="xl" sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                        {/* Left Content */}
                        <Grid item xs={12} md={8}>
                            <Box sx={{ mb: 2 }}>
                                <TreemapComponent data={treeMapData} onSectorClick={handleSectorRedirect} />
                            </Box>
                            <TargetCompanies companies={companies} />
                        </Grid>

                        {/* Right Content */}
                        <Grid item xs={12} md={4}>
                            <SignalsTriggers signals={filteredSignals} />
                        </Grid>
                    </Grid>
                </Container>

                {/* Chatbot */}
                <ChatbotModal open={open} handleClose={handleClose} />
            </Box>
        </BaseScreen>
    )
}
