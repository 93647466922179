import React, { useEffect, useRef, useState } from 'react'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Button, Chip } from '@mui/material'
import SelectedHieararchyChip from './SelectedHieararchyChip'

export default function JustShowFilters(props) {
    const { combinedFilters, filterLength, setNewsFilters } = props
    const {
        selectedRegions,
        setSelectedRegions,
        selectedSectors,
        setSelectedSectors,
        regionHierarchy,
        allHierarchy,
    } = props

    const [isExpanded, setIsExpanded] = useState(false)
    const [isOverflowing, setIsOverflowing] = useState(false)
    const textRef = useRef(null)
    const toggleExpanded = () => setIsExpanded(prev => !prev)

    useEffect(() => {
        const element = textRef.current

        if (element) {
            // Check if content overflows
            const isOverflow = element.scrollHeight > element.clientHeight
            setIsOverflowing(isOverflow)
        }
    }, [filterLength])

    const stateMap = {
        sector: [selectedSectors, setSelectedSectors],
        country: [selectedRegions, setSelectedRegions],
    }
    const bulkChange = (type, data, name) => {
        const [selectedItems, setSelectedItems] = stateMap[type]

        let result = selectedItems

        const isSelectedItem = selectedItems.find(item => item === name)
        data.forEach(list => {
            if (isSelectedItem) {
                result = result.filter(item => item !== list.name)
            } else {
                result.push(list.name)
            }
        })

        setSelectedItems([...result])
    }

    const SelectedTopicsChips = ({ selectedSectors, bulkChange, allHierarchy }) => {
        const getSelectedChips = () => {
            const selectedSet = new Set(selectedSectors)
            const result = []
            console.log('allhier', allHierarchy)

            allHierarchy.forEach(region => {
                if (selectedSet.has(region.name)) {
                    result.push({ name: region.name, type: 'industries' })
                } else {
                    ;(region.sectors || []).forEach(country => {
                        if (selectedSet.has(country.name)) {
                            result.push({ name: country.name, type: 'sectors' })
                        } else {
                            ;(country.topics || []).forEach(province => {
                                if (selectedSet.has(province.name)) {
                                    result.push({ name: province.name, type: 'topics' })
                                }
                            })
                        }
                    })
                }
            })

            return result
        }

        const selectedChips = getSelectedChips()

        console.log('tops', selectedChips)

        return (
            <>
                {selectedChips.map(({ name, type }) => (
                    <Chip
                        key={name}
                        label={name}
                        onDelete={() => {
                            const relatedItems = []
                            allHierarchy.forEach(region => {
                                if (region.name === name) {
                                    // Removing region and its descendants
                                    relatedItems.push(
                                        { name: region.name },
                                        ...region.sectors.map(c => ({ name: c.name })),
                                        ...region.sectors.flatMap(c => c.topics.map(p => ({ name: p.name })))
                                    )
                                } else {
                                    const country = region.sectors.find(c => c.name === name)
                                    if (country) {
                                        // Removing country and its provinces
                                        relatedItems.push(
                                            { name: country.name },
                                            ...country.topics.map(p => ({ name: p.name }))
                                        )
                                    } else {
                                        // Removing province
                                        region.sectors.forEach(c => {
                                            c.topics.forEach(p => {
                                                if (p.name === name) {
                                                    relatedItems.push({ name: p.name })
                                                }
                                            })
                                        })
                                    }
                                }
                            })

                            bulkChange('sector', relatedItems, name)
                        }}
                        style={{
                            background: 'none',
                            border: '1px solid #3f3f3f',
                            color: '#3f3f3f',
                            marginLeft: '10px',
                            marginTop: isExpanded ? '10px' : '0px',
                        }}
                    />
                ))}
            </>
        )
    }

    const SelectedRegionChips = ({ selectedRegions, bulkChange, regionHierarchy }) => {
        const getSelectedChips = () => {
            const selectedSet = new Set(selectedRegions)
            const result = []

            regionHierarchy.forEach(region => {
                if (selectedSet.has(region.name)) {
                    result.push({ name: region.name, type: 'region' })
                } else {
                    ;(region.countries || []).forEach(country => {
                        if (selectedSet.has(country.name)) {
                            result.push({ name: country.name, type: 'country' })
                        } else {
                            ;(country.provinces || []).forEach(province => {
                                if (selectedSet.has(province.name)) {
                                    result.push({ name: province.name, type: 'province' })
                                }
                            })
                        }
                    })
                }
            })

            return result
        }

        const selectedChips = getSelectedChips()

        return (
            <>
                {selectedChips.map(({ name, type }) => (
                    <Chip
                        key={name}
                        label={name}
                        onDelete={() => {
                            const relatedItems = []
                            regionHierarchy.forEach(region => {
                                if (region.name === name) {
                                    // Removing region and its descendants
                                    relatedItems.push(
                                        { name: region.name },
                                        ...region.countries.map(c => ({ name: c.name })),
                                        ...region.countries.flatMap(c => c.provinces.map(p => ({ name: p.name })))
                                    )
                                } else {
                                    const country = region.countries.find(c => c.name === name)
                                    if (country) {
                                        // Removing country and its provinces
                                        relatedItems.push(
                                            { name: country.name },
                                            ...country.provinces.map(p => ({ name: p.name }))
                                        )
                                    } else {
                                        // Removing province
                                        region.countries.forEach(c => {
                                            c.provinces.forEach(p => {
                                                if (p.name === name) {
                                                    relatedItems.push({ name: p.name })
                                                }
                                            })
                                        })
                                    }
                                }
                            })

                            bulkChange('country', relatedItems, name)
                        }}
                        style={{
                            background: 'none',
                            border: '1px solid #3f3f3f',
                            color: '#3f3f3f',
                            marginLeft: '10px',
                            marginTop: isExpanded ? '10px' : '0px',
                        }}
                    />
                ))}
            </>
        )
    }

    return (
        <div className="mt-4">
            <div
                onClick={() => {
                    setNewsFilters(true)
                }}
                style={{
                    cursor: 'pointer',
                }}
                className={`d-flex ${!isExpanded ? 'align-items-center' : ''} justify-content-between`}
            >
                <div className={`d-flex ${!isExpanded ? 'align-items-center' : ''}`}>
                    <div
                        className={`font-h d-flex ${!isExpanded ? 'align-items-center' : ''}`}
                        style={{ marginTop: isExpanded ? '10px' : '0px' }}
                    >
                        <span>Filters</span> <span>{filterLength !== 0 && '(' + filterLength + ')'}</span>
                        <span className="d-none d-sm-block">
                            <FilterListIcon className="font-h d-none d-sm-block" />
                        </span>
                    </div>

                    <div
                        ref={textRef}
                        style={{
                            overflow: 'hidden',
                            display: isExpanded ? 'block' : '-webkit-box',
                            WebkitLineClamp: isExpanded ? 'unset' : 1,
                            WebkitBoxOrient: 'vertical',
                            margin: 0,
                        }}
                    >
                        {combinedFilters
                            .filter(val => val.type !== 'region' && val.type !== 'sector')
                            .map(val => (
                                <Chip
                                    key={val.name}
                                    label={val.name}
                                    style={{
                                        background: 'none',
                                        border: '1px solid #3f3f3f',
                                        color: '#3f3f3f',
                                        marginLeft: '10px',
                                        marginTop: isExpanded ? '10px' : '0px',
                                    }}
                                    onDelete={() => {
                                        val.setValue(val.value.filter(currUser => currUser !== val.name))
                                    }}
                                />
                            ))}

                        {/* <SelectedRegionChips
                            selectedRegions={selectedRegions}
                            bulkChange={bulkChange}
                            regionHierarchy={regionHierarchy}
                        />
                        <SelectedTopicsChips
                            selectedSectors={selectedSectors}
                            bulkChange={bulkChange}
                            allHierarchy={allHierarchy}
                        /> */}
                        <SelectedHieararchyChip
                            hierarchy={regionHierarchy}
                            selectedItems={selectedRegions}
                            bulkChange={bulkChange}
                            bulkChangeKey="country"
                            labels={['region', 'country', 'province']}
                            isExpanded={isExpanded}
                        />

                        <SelectedHieararchyChip
                            hierarchy={allHierarchy}
                            selectedItems={selectedSectors}
                            bulkChange={bulkChange}
                            bulkChangeKey="sector"
                            labels={['industries', 'sectors', 'topics']}
                            isExpanded={isExpanded}
                        />
                    </div>
                </div>
                {isOverflowing && (
                    <button
                        onClick={toggleExpanded}
                        style={{
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                            marginTop: isExpanded ? '10px' : '0px',
                        }}
                        className={`d-flex ${!isExpanded ? 'align-items-center' : ''}`}
                    >
                        {isExpanded ? 'See Less' : 'See More'}
                    </button>
                )}
            </div>
        </div>
    )
}
