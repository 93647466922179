import React from 'react'
import { Card, CardContent, Typography, Grid, Button, Avatar } from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
const contactsData = [
    { initials: 'SS', name: 'Sebastian Siemiatkowski', title: 'CEO', color: '#ADD8E6' },
    { initials: 'NN', name: 'Niclas Neglen', title: 'Chief Financial Officer', color: '#ADD8E6' },
    { initials: 'DF', name: 'David Fock', title: 'Chief Product and Design Officer', color: '#ADD8E6' },
    { initials: 'YS', name: 'Yaron Shaer', title: 'Chief Technology Officer', color: '#ADD8E6' },
    { initials: 'DS', name: 'David Sandstrom', title: 'Chief Marketing Officer', color: '#ADD8E6' },
    { initials: 'DS', name: 'David Sykes', title: 'Chief Commercial Officer', color: '#ADD8E6' },
    { initials: 'CG', name: 'Camilla Giesecke', title: 'Chief Operating Officer', color: '#ADD8E6' },
    { initials: 'JR', name: 'Joachim Reuss', title: 'Chief Risk Officer', color: '#ADD8E6' },
    { initials: 'JC', name: 'Joaquín Calderon', title: 'Chief Compliance Officer', color: '#ADD8E6' },
    { initials: 'AV', name: 'Arvind Varadhan', title: 'Chief Credit Risk Officer', color: '#ADD8E6' },
    { initials: 'BM', name: 'Boudien Moerman', title: 'Chief Legal Officer', color: '#ADD8E6' },
    { initials: 'HL', name: 'Harjyot Lidher', title: 'Chief Information Security Officer', color: '#ADD8E6' },
]

const Contacts = () => {
    return (
        <Card
            variant="outlined"
            style={{
                borderRadius: '8px',
                height: '280px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <CardContent style={{ padding: 0, flex: 1 }}>
                {/* Fixed Header */}
                <div
                    style={{
                        position: 'sticky',
                        top: 0,
                        background: '#fff',
                        zIndex: 10,
                        padding: '10px',
                    }}
                >
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography
                            variant="h6"
                            component="div"
                            style={{
                                fontWeight: 500,
                                gap: '8px',
                                color: '#0A0A0A',
                                fontSize: '16px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ContactPhoneIcon style={{ fontSize: '20px', color: '3B82F6', marginRight: '8px' }} />
                            Reachout to these contacts
                        </Typography>
                        <Button
                            variant="contained"
                            size="small"
                            endIcon={<ArrowRightAltIcon />}
                            style={{
                                textTransform: 'none',
                                fontWeight: 600,
                                backgroundColor: '#f2f2f2',
                                color: '#171717',
                                boxShadow: 'none',
                            }}
                        >
                            View All
                        </Button>
                    </Grid>
                </div>

                {/* Scrollable Content */}
                <div
                    style={{
                        overflowY: 'auto',
                        height: 'calc(100% - 60px)', // Adjust based on header height
                        padding: '10px',
                    }}
                >
                    <Grid container spacing={2}>
                        {contactsData.map((contact, index) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                key={index}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '10px',
                                }}
                            >
                                <Avatar
                                    style={{
                                        marginRight: '10px',
                                        backgroundColor: contact.color,
                                    }}
                                >
                                    {contact.initials}
                                </Avatar>
                                <div>
                                    <Typography variant="body2" style={{ fontWeight: 500 }}>
                                        {contact.name}
                                    </Typography>
                                    <Typography variant="body2" style={{ color: '#555' }}>
                                        {contact.title}
                                    </Typography>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </CardContent>
        </Card>
    )
}

export default Contacts
