import React from 'react'
import { Card, CardContent, Typography, Grid, Button } from '@mui/material'
import { PieChart, Pie, Cell, Sector } from 'recharts'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
const data = [
    { name: 'Very Low', value: 1, color: '#D32F2F' },
    { name: 'Low', value: 1, color: '#FFCDD2' },
    { name: 'Neutral', value: 1, color: '#BDBDBD' },
    { name: 'High', value: 1, color: '#4FC3F7' },
    { name: 'Very High', value: 1, color: '#1976D2' },
]

const cx = 150
const cy = 150 // Center y
const iR = 80 // Inner Radius
const oR = 145 // Outer Radius

const GaugeChart = () => {
    return (
        <Card
            variant="outlined"
            style={{
                borderRadius: '8px',
                height: '280px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start', // Adjusting to move content up
                alignItems: 'center',
                position: 'relative',
            }}
        >
            <CardContent style={{ width: '100%', padding: '16px' }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography
                        variant="h6"
                        component="div"
                        style={{
                            fontWeight: 500,
                            color: '#0A0A0A',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <LightbulbIcon style={{ fontSize: '20px', color: '3B82F6', marginRight: '8px' }} />
                        Opportunities Identified
                    </Typography>
                    <Button
                        variant="contained"
                        size="small"
                        endIcon={<ArrowRightAltIcon />}
                        style={{
                            textTransform: 'none',
                            fontWeight: 600,
                            backgroundColor: '#f2f2f2',
                            color: '#171717',
                            boxShadow: 'none',
                        }}
                    >
                        View All
                    </Button>
                </Grid>
                {/* Centered Chart */}
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '200px',
                        marginTop: '-75px', // Moves the chart up
                    }}
                >
                    <PieChart width={350} height={300}>
                        <Pie
                            dataKey="value"
                            startAngle={180}
                            endAngle={0}
                            data={data}
                            cx="50%"
                            cy="100%" // Centers the chart
                            innerRadius={iR}
                            outerRadius={oR}
                            paddingAngle={0}
                            stroke="none"
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                        </Pie>
                    </PieChart>

                    {/* Centered Text */}
                    <div
                        style={{
                            position: 'absolute',
                            top: '115%', // Moves the text higher
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                        }}
                    >
                        <Typography variant="h4" component="div" style={{ fontWeight: 600, color: 'gray' }}>
                            9
                        </Typography>
                        <Typography variant="body1" component="div" style={{ color: '#757575' }}>
                            Attractiveness Score
                        </Typography>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default GaugeChart
