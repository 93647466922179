import React, { useEffect } from 'react'
import { getFullFormattedDate } from '../../../utils/dateHelpers'
import { Grid, Card } from '@mui/material'
import './MailModal.css'
import twitterX from '../../../assets/icons/twitterx-black.svg'
import TemplateFooter from './TemplateFooter'

export default function CompanyPreview(props) {
    const {
        introText,
        selectedStories,
        primaryColor,
        secondaryColor,
        selectedLogo,
        setSize,
        includeSection,
        includeBusiness,
        hyperlinkSource,
        hyperlinkTitle,
        subjectLine,
    } = props
    const logoRef = React.useRef()
    const getSize = () => {
        setSize(logoRef.current.offsetWidth, logoRef.current.offsetHeight, 'primary')
    }

    const sectionBody = data => {
        return (
            <>
                {data
                    .sort((a, b) => (a.date.split('T')[0] > b.date.split('T')[0] ? -1 : 1))
                    .map(story => {
                        return (
                            <Grid item>
                                <Card
                                    variant="outlined"
                                    style={{
                                        borderRadius: '0px',
                                        borderWidth: '2px',
                                        borderColor: 'rgba(151, 151, 151, 0.37)',
                                    }}
                                >
                                    <Grid container direction="row" spacing={2} style={{ padding: '1rem' }}>
                                        <Grid item key="date">
                                            <h4 style={{ fontSize: '13px' }}>{getFullFormattedDate(story.date)}</h4>
                                            {story.source_name && (
                                                <h4
                                                    style={{
                                                        fontFamily: 'Arial, Helvetica, sans-serif',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {story.source_name}
                                                </h4>
                                            )}
                                        </Grid>

                                        <Grid item key="newsinfo">
                                            <Grid item key="newstitle">
                                                <a
                                                    href={story.source}
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '18px',
                                                        color: '#000',
                                                        lineHeight: '1.1',
                                                    }}
                                                    target="_blank"
                                                >
                                                    {story.title}
                                                </a>
                                            </Grid>
                                        </Grid>
                                        <Grid item key="newssnippet">
                                            <p
                                                style={{
                                                    fontSize: '14px',
                                                    lineHeight: '1.2',
                                                }}
                                            >
                                                {story.snippet}
                                            </p>
                                        </Grid>
                                        {includeBusiness && story.subcategories && story.subcategories.length > 0 && (
                                            <Grid item key="subcategories" style={{ width: '70%' }}>
                                                <div>
                                                    {story.subcategories.map(subcategory => {
                                                        return (
                                                            <span
                                                                key={subcategory.id}
                                                                style={{
                                                                    lineHeight: '25px',
                                                                    margin: '2px',
                                                                    fontSize: '12px',
                                                                    backgroundColor: secondaryColor,
                                                                    color: 'white',
                                                                    padding: '0.25rem',
                                                                    width: '-webkit-fit-content',
                                                                }}
                                                            >
                                                                {subcategory.name}
                                                            </span>
                                                        )
                                                    })}
                                                </div>
                                            </Grid>
                                        )}
                                        <div
                                            className="mailModal__ShareLine"
                                            style={{
                                                marginLeft: '75%',
                                                marginTop: '-5%',
                                            }}
                                        >
                                            <p
                                                style={{
                                                    fontSize: '12px',
                                                }}
                                            >
                                                <span>
                                                    Share on: &nbsp;
                                                    <a href="twitter.com">
                                                        <img
                                                            style={{
                                                                width: '13.8px',
                                                                height: '13.8px',
                                                            }}
                                                            src="https://kaitongodev.nyc3.digitaloceanspaces.com/kaitongoinsights/static/x-twitter.png"
                                                            alt="twitter"
                                                        />
                                                    </a>
                                                    &nbsp;
                                                    <a href="https://www.linkedin.com">
                                                        <img
                                                            style={{
                                                                width: '13.8px',
                                                                height: '13.8px',
                                                            }}
                                                            src="https://kaitongoprod.nyc3.digitaloceanspaces.com/kaitongoprod/static/linkedin2.png"
                                                            alt="linkedIn"
                                                        />
                                                    </a>
                                                </span>
                                            </p>
                                        </div>
                                    </Grid>
                                </Card>
                            </Grid>
                        )
                    })}
            </>
        )
    }

    const newSectionBody = data => {
        return (
            <>
                {data
                    .sort((a, b) => (a.date.split('T')[0] > b.date.split('T')[0] ? -1 : 1))
                    .map((story, index) => {
                        return (
                            <>
                                <div style={{ padding: index !== 0 ? '0px 20px 10px 20px' : '10px 20px 0px 20px' }}>
                                    {includeBusiness && story.subcategories && story.subcategories.length > 0 && (
                                        <div style={{ display: 'flex' }}>
                                            {story.subcategories.map(subcategory => {
                                                return (
                                                    <i
                                                        key={subcategory.id}
                                                        style={{
                                                            margin: '2px',
                                                            fontSize: '12px',
                                                            backgroundColor: secondaryColor,
                                                            color: 'white',
                                                            padding: '2px 10px',
                                                            width: '-webkit-fit-content',
                                                            borderRadius: '25px',
                                                        }}
                                                    >
                                                        {subcategory.name}
                                                    </i>
                                                )
                                            })}
                                        </div>
                                    )}
                                    <div>
                                        <div style={{ fontSize: '18px', paddingTop: '10px' }}>{story.title} </div>
                                        <div
                                            style={{
                                                fontSize: '13px',
                                                paddingTop: '10px',
                                                lineHeight: 1.5,
                                            }}
                                        >
                                            {story.snippet}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '14px',
                                            padding: '10px 0px',
                                        }}
                                    >
                                        {story.source_name && (
                                            <span style={{ fontWeight: 700 }}>
                                                <span>{story.source_name}</span>
                                                <span style={{ padding: '0px 2px' }}>·</span>
                                            </span>
                                        )}
                                        <span>{getFullFormattedDate(story.date)}</span>
                                    </div>

                                    {index !== data.length - 1 && (
                                        <div style={{ paddingTop: '0px' }}>
                                            <hr />
                                        </div>
                                    )}
                                </div>
                            </>
                        )
                    })}
            </>
        )
    }
    return (
        <div>
            <div className="mailModal__emailContainer">
                {/* <table cellSpacing="0" cellPadding="0" className="mailModal__headerGraphicAndClickBelow">
                    <tbody>
                        <tr style={{ maxHeight: '150px' }}>
                            <td
                                colSpan="3"
                                className="mailModal__headerGraphic"
                                align="center"
                                style={{ width: '33%', maxHeight: '150px' }}
                            >
                                <img
                                    ref={ref => (logoRef.current = ref)}
                                    onLoad={e => getSize()}
                                    onChange={e => getSize()}
                                    src={selectedLogo}
                                    style={{ position: 'inherit', maxHeight: '150px', maxWidth: '250px' }}
                                />
                            </td>
                            <td style={{ width: '33%', textAlign: 'center' }}>
                                <hr
                                    align="center"
                                    style={{
                                        height: '2px',
                                        backgroundColor: '#110e5d',
                                        width: '80%',
                                        margin: '0 33px',
                                    }}
                                />
                            </td>
                            <td style={{ width: '33%', textAlign: 'center' }}>
                                <i>{getFullFormattedDate(Date.now())}</i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table cellSpacing="0" cellPadding="0" className="mailModal__headerGraphicAndClickBelow">
                    <tr align="center" className="mailModalClickBelowTR" style={{ backgroundColor: primaryColor }}>
                        <td>
                            <a href="https://app.kaitongo.com/" style={{ textDecoration: 'none' }}>
                                Click Here to see more news on our app
                            </a>
                        </td>
                    </tr>
                </table>
                <table style={{ width: '100%', border: `solid 1px ${primaryColor}` }}>
                    <tbody>
                        <tr>
                            <td className="mailModal__openingParagraphData">
                                <p>Hi ,</p>
                                <p style={{ whiteSpace: 'pre-line' }}>{`${introText}`}</p>
                                {hyperlinkSource !== '' && hyperlinkTitle !== '' && (
                                    <div>
                                        <a href={hyperlinkSource} target="_blank" style={{ fontSize: '16px' }}>
                                            {hyperlinkTitle}
                                        </a>
                                    </div>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table> */}
                <div>
                    <div
                        style={{
                            background: primaryColor,
                            marginRight: '0px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '20px 0',
                            width: '100%',
                            boxSizing: 'border-box',
                        }}
                    >
                        <div />
                        <img
                            style={{ width: '200px', height: 'auto' }}
                            width="200"
                            height="auto"
                            src={selectedLogo}
                            alt="Kaitongo logo"
                        />
                    </div>
                    <div
                        style={{
                            background: primaryColor,
                            marginRight: '0px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            boxSizing: 'border-box',
                        }}
                    >
                        <div
                            style={{
                                overflowWrap: 'break-word',
                                fontFamily: "'Helvetica', 'Roboto Condensed'",
                                fontWeight: 700,
                                lineHeight: 1.5,
                                color: '#ffffff',
                            }}
                        >
                            <span>{subjectLine}</span>
                        </div>
                        <div
                            style={{
                                overflowWrap: 'break-word',
                                fontFamily: "'Helvetica', 'Roboto Condensed'",
                                fontWeight: 300,
                                fontSize: '12px',
                                lineHeight: 1.5,
                                color: '#d7e8f8',
                                padding: '0px 0px 10px 0px',
                            }}
                        >
                            <span>{getFullFormattedDate(Date.now())}</span>
                        </div>
                        {selectedStories.flatMap(e => e.news).length > 1 && (
                            <div style={{ padding: '20px', background: 'white', width: '100%' }}>
                                {introText !== '' && <div style={{ fontSize: '14px', fontWeight: 700 }}>Summary</div>}
                                <div style={{ fontSize: '14px', lineHeight: 1.5 }}>
                                    <span>{introText}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {selectedStories.flatMap(e => e.news).length === 0 && (
                    <div style={{ textAlign: 'center', paddingTop: '20px' }}>Choose Sections to Preview News</div>
                )}
                {includeSection
                    ? selectedStories.map(section => {
                          if (
                              section &&
                              section.news &&
                              section.news.length !== 0 &&
                              section.title.trim().length !== 0
                          ) {
                              return (
                                  <div style={{ width: '100%', boxSizing: 'border-box', fontFamily: 'Helvetica' }}>
                                      <div>
                                          <div style={{ marginTop: '2px' }}>
                                              {includeSection && (
                                                  <div
                                                      style={{
                                                          fontSize: '16px',
                                                          background: primaryColor,
                                                          color: '#ffffff',
                                                          padding: '15px 10px',
                                                      }}
                                                  >
                                                      <span>{section.title}</span>
                                                  </div>
                                              )}
                                              {newSectionBody(section.news)}
                                          </div>
                                      </div>
                                  </div>
                              )
                          }
                      })
                    : newSectionBody(selectedStories)}
                <div
                    style={{
                        fontSize: '8px',
                        fontFamily: 'Roboto',
                        margin: 'auto',
                        textAlign: 'center',
                        background: primaryColor,
                        color: '#ffffff',
                    }}
                >
                    <div style={{ width: '40%', margin: 'auto', padding: '25px 0px 15px 0px' }}>
                        <div>Have questions or feedback? Email us at support@kaitongo.com.</div>
                        <div>You're receiving this email because you're subscribed to Kaitongo alerts.</div>
                        <div>You can update your preferences or unsubscribe from this list.</div>
                    </div>
                    <div>
                        <img
                            width="20"
                            height="20"
                            src="https://kaitongo-static.s3.amazonaws.com/dev/static/kaitongo-k-black.png"
                            alt="Image"
                        />
                    </div>
                    <div style={{ width: '25%', margin: 'auto', padding: '20px 0px 15px 0px' }}>
                        Kaitongo: Actionable intelligence for businesses. See more insights at Kaitongo.
                    </div>
                </div>
            </div>
        </div>
    )
}
