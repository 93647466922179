import React from 'react'
import { Box, Tabs, Tab, Card, CardContent, Typography, Grid, Button, IconButton } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import ReportIcon from '@mui/icons-material/Assessment'
import BuildIcon from '@mui/icons-material/Build'
import AssignmentIcon from '@mui/icons-material/Assignment'
import CrownIcon from '@mui/icons-material/EmojiEvents'
import AddIcon from '@mui/icons-material/Add'

import DescriptionIcon from '@mui/icons-material/Description'
const Opportunities = () => {
    const [tabValue, setTabValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setTabValue(newValue)
    }

    const cardsData = [
        {
            title: 'IPO Ambitions signal strategic growth',
            description: "Klarna's Pre-IPO Ambitions Signal Strategic Growth in Payments Market",
        },
        {
            title: 'Open Banking Strategy Drives Transformation',
            description: "Klarna's Open Banking Strategy Drives Lending and Payment Transformation",
        },
        {
            title: 'Revenue diversification plans',
            description: 'Klarna Targets UK’s £7 Billion Gift Card Market for Revenue Diversification',
        },
        {
            title: 'Divestment with $39 Billion Portfolio Sale to Elliott',
            description: 'Klarna Strengthens Capital Base with $39 Billion Portfolio Sale to Elliott',
        },
    ]

    const actionCards = [
        {
            icon: <ReportIcon style={{ fontSize: 40, color: '#1976d2' }} />,
            title: 'Request Detailed Report',
            description: 'Prep for a meeting with a Detailed Report with Financials, Strategy, Legal Considerations',
        },
        {
            icon: <EmailIcon style={{ fontSize: 40, color: '#1976d2' }} />,
            title: 'Reachout to a contact',
            description: 'Write a 1:1 deep-researched email sequence to a specific contact. Take over when they reply.',
        },
        {
            icon: <BuildIcon style={{ fontSize: 40, color: '#1976d2' }} />,
            title: 'Build an Agent',
            description: 'Use our AI to create custom workflows to send alerts, do in-depth research.',
        },
    ]

    return (
        <Box>
            {/* Tabs */}
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginBottom: 2,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {/* Static "Opportunities" Tab */}
                <Typography
                    variant="body1"
                    style={{
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '20px',
                        cursor: 'default',
                    }}
                >
                    <CrownIcon fontSize="small" style={{ fontSize: '20px', color: '3B82F6', marginRight: '8px' }} />
                    Opportunities
                </Typography>

                {/* Clickable Tabs */}
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="Opportunities Tabs"
                    TabIndicatorProps={{ style: { display: 'none' } }}
                >
                    <Tab
                        label="M&A"
                        icon={<DescriptionIcon fontSize="small" />}
                        iconPosition="start"
                        style={{
                            textTransform: 'none',
                            fontSize: '0.9rem',
                            fontWeight: 500,
                            // borderRadius: '0px 60px 0px 0px',
                            backgroundColor: tabValue === 0 ? '#f2f2f2' : 'white',
                            border: '1px solid #f2f2f2',
                            marginRight: '8px',
                        }}
                    />
                    <Tab
                        label="Add Opportunity"
                        icon={<AddIcon fontSize="small" />}
                        iconPosition="start"
                        style={{
                            textTransform: 'none',
                            fontSize: '0.9rem',
                            fontWeight: 500,
                            // borderRadius: '0px 60px 0px 0px',
                            backgroundColor: tabValue === 1 ? '#f2f2f2' : 'white',
                            border: '1px solid #f2f2f2',
                        }}
                    />
                </Tabs>
            </Box>

            {/* Tab Panels */}
            <Box>
                {tabValue === 0 && (
                    <Grid container spacing={1}>
                        {/* Cards for Opportunities */}
                        {cardsData.map((card, index) => (
                            <Grid item xs={6} md={3} key={index}>
                                <Card
                                    variant="outlined"
                                    style={{
                                        borderRadius: '0.75rem',
                                        backgroundColor: '#f2f2f266',
                                        border: '1px solid #d9d9d9',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <CardContent style={{ flexGrow: 1 }}>
                                        <Typography
                                            variant="p"
                                            style={{ fontWeight: 600, marginBottom: 8, color: '#666679' }}
                                        >
                                            {card.title}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            style={{
                                                color: '#0A0A0A',
                                                display: '-webkit-box',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {card.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}

                        {/* Action Cards */}
                        <Grid container spacing={1} style={{ marginTop: '10px' }}>
                            {actionCards.map((action, index) => (
                                <Grid item xs={12} md={4} key={index}>
                                    <Card
                                        variant="outlined"
                                        style={{
                                            borderRadius: '0.75rem',
                                            backgroundColor: '#f2f2f2b2',
                                            border: '1px solid #d9d9d9',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <CardContent
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <IconButton disableRipple style={{ background: 'none' }}>
                                                {action.icon}
                                            </IconButton>
                                            <div style={{ textAlign: 'left' }}>
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        fontWeight: 600,
                                                        margin: '10px 0 8px',
                                                    }}
                                                >
                                                    {action.title}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        color: '#555',
                                                        fontSize: '0.85rem',
                                                        marginTop: 8,
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 2,
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {action.description}
                                                </Typography>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                )}
                {tabValue === 1 && <Typography variant="body1">M&A Content Goes Here</Typography>}
                {tabValue === 2 && <Typography variant="body1">Add Opportunity Content Goes Here</Typography>}
            </Box>
        </Box>
    )
}

export default Opportunities
