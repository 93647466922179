import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Circles } from 'react-loader-spinner'
import NewCompanyNewsCards from '../../../screens/NewsFeedScreen/components/NewCompanyNewsCards'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Card, Typography } from '@mui/material'
import AnnouncementIcon from '@mui/icons-material/Announcement'

export default function CompanyNewsV2() {
    // Static news data
    const newsData = [
        {
            date: '2024-11-25',
            title: 'Klarna and Lenovo partner to expand payments in Europe',
            description:
                'Klarna has partnered with Lenovo to expand flexible payment methods across 15 European countries, enhancing accessibility and convenience for customers.',
            source: 'thepaypers.com',
            link:
                'https://thepaypers.com/payments-general/klarna-and-lenovo-partner-to-expand-payments-in-europe--1271124',
        },
        {
            date: '2024-11-23',
            title: 'Lenovo Adds Klarna’s Flexible Payment Methods in 15 European Countries',
            description:
                'Lenovo integrates Klarna’s "Buy Now, Pay Later" solutions across 15 countries in Europe, aiming to improve the shopping experience for consumers.',
            source: 'pymnts.com',
            link:
                'https://www.pymnts.com/bnpl/2024/lenovo-adds-klarnas-flexible-payment-methods-in-15-european-countries',
        },
        {
            date: '2024-11-22',
            title: 'Klarna Scores Deal with Tech Giant Lenovo',
            description:
                'Klarna secures a strategic partnership with Lenovo, bringing flexible payment options to tech consumers across Europe.',
            source: 'finextra.com',
            link: 'https://www.finextra.com/pressarticle/103322/klarna-scores-deal-with-tech-giant-lenovo',
        },
        {
            date: '2024-11-22',
            title: 'Fintech Sunbit seeks BNPL distinction in co-branded cards',
            description:
                'Sunbit is exploring unique offerings in the BNPL space with co-branded cards, distinguishing itself from competitors in the fintech industry.',
            source: 'americanbanker.com',
            link:
                'https://www.americanbanker.com/payments/news/fintech-sunbit-seeks-bnpl-distinction-in-co-branded-cards',
        },
    ]

    // Mock load more function
    const handleLoadMore = () => {
        console.log('Load more data...')
    }

    return (
        <Card
            variant="outlined"
            style={{
                borderRadius: '8px',
                height: '280px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {/* Sticky Header */}
            <div
                style={{
                    position: 'sticky',
                    top: 0,
                    background: '#fff',
                    zIndex: 10,
                    padding: '10px',
                }}
            >
                <Typography
                    variant="h6"
                    component="div"
                    style={{
                        fontWeight: 500,
                        color: '#0A0A0A',
                        fontSize: '16px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <AnnouncementIcon style={{ fontSize: '20px', color: '3B82F6', marginRight: '8px' }} />
                    News and Key Updates
                </Typography>
            </div>

            {/* Scrollable Content */}
            <div
                style={{
                    overflowY: 'auto',
                    height: 'calc(100% - 60px)',
                }}
            >
                {newsData && (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleLoadMore}
                        hasMore={false} // Disable since static data has no pagination
                        loader={
                            <div style={{ marginTop: '5%', marginLeft: '20px' }}>
                                <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
                            </div>
                        }
                    >
                        {newsData
                            .sort((a, b) => (a.date > b.date ? -1 : 1))
                            .map((story, index) => (
                                <NewCompanyNewsCards key={index} val={story} index={index} isTopicPage={true} />
                            ))}
                    </InfiniteScroll>
                )}
            </div>
        </Card>
    )
}
