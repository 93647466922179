import React, { useState, useEffect } from 'react'
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Typography,
    IconButton,
    Button,
    Box,
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import { NavLink } from 'react-router-dom'
import { TargetCompaniesSkeleton } from '../../../components/Skeleton/index'
const getOpportunityIcon = opportunity => {
    let color
    switch (opportunity) {
        case 'High':
            color = 'green'
            break
        case 'Medium':
            color = 'orange'
            break
        default:
            color = 'red'
    }
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <SignalCellularAltIcon style={{ color, marginRight: 4 }} />
            <Typography variant="body2" style={{ color, fontWeight: 600 }}>
                {opportunity}
            </Typography>
        </div>
    )
}

const TargetCompanies = ({ companies }) => {
    const [showSkeleton, setShowSkeleton] = useState(true)

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSkeleton(false)
        }, 1000)

        return () => clearTimeout(timer)
    }, [])
    return (
        <>
            {showSkeleton ? (
                <TargetCompaniesSkeleton />
            ) : (
                <Box
                    sx={{
                        p: 2,
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                    }}
                >
                    {/* Header Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #e0e0e0',
                            pb: 1,
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 2,
                        }}
                    >
                        {/* Title and Info Icon */}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    fontWeight: 600,
                                    color: '#6A6A6A',
                                    fontSize: { xs: '16px', md: '18px' },
                                }}
                            >
                                Target Companies
                            </Typography>
                            <IconButton>
                                <InfoOutlinedIcon fontSize="small" />
                            </IconButton>
                        </Box>

                        {/* View All Button */}
                        <Button
                            variant="contained"
                            size="small"
                            endIcon={<ArrowRightAltIcon />}
                            sx={{
                                textTransform: 'none',
                                fontWeight: 600,
                                backgroundColor: '#f2f2f2',
                                color: '#171717',
                                boxShadow: 'none',
                                fontSize: { xs: '12px', sm: '14px' },
                                px: { xs: 2, sm: 3 },
                                '&:hover': {
                                    backgroundColor: '#e0e0e0',
                                },
                            }}
                        >
                            View All
                        </Button>
                    </Box>

                    <TableContainer
                        sx={{
                            maxHeight: '400px',
                            overflowY: 'auto', // Vertical scrolling for table rows
                            overflowX: 'auto', // Horizontal scrolling for small screens
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontWeight: 'bold',
                                            whiteSpace: { xs: 'nowrap', sm: 'normal' },
                                            minWidth: { xs: '150px', md: '200px' },
                                        }}
                                    >
                                        Company
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontWeight: 'bold',
                                            whiteSpace: { xs: 'nowrap', sm: 'normal' },
                                            minWidth: { xs: '150px', md: '200px' },
                                        }}
                                    >
                                        Website
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontWeight: 'bold',
                                            whiteSpace: { xs: 'nowrap', sm: 'normal' },
                                            textAlign: 'center',
                                            minWidth: { xs: '100px', md: '150px' },
                                        }}
                                    >
                                        Opportunity
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontWeight: 'bold',
                                            whiteSpace: { xs: 'nowrap', sm: 'normal' },
                                            textAlign: 'center',
                                            minWidth: { xs: '100px', md: '150px' },
                                        }}
                                    >
                                        Update
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {companies.map((company, index) => (
                                    <TableRow key={index} hover>
                                        <TableCell
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                minWidth: { xs: '150px', md: '200px' },
                                            }}
                                        >
                                            <img
                                                src={`https://www.google.com/s2/favicons?sz=24&domain_url=${company.website}`}
                                                alt={company.name}
                                                style={{
                                                    width: '24px',
                                                    height: '24px',
                                                    borderRadius: '50%',
                                                }}
                                            />
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    whiteSpace: { xs: 'nowrap', sm: 'normal' },
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    fontSize: { xs: '12px', md: '14px' },
                                                }}
                                            >
                                                {company.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                whiteSpace: { xs: 'nowrap', sm: 'normal' },
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                minWidth: { xs: '150px', md: '200px' },
                                                fontSize: { xs: '12px', md: '14px' },
                                            }}
                                        >
                                            {company.website}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                textAlign: 'center',
                                                whiteSpace: { xs: 'nowrap', sm: 'normal' },
                                                fontSize: { xs: '12px', md: '14px' },
                                                minWidth: { xs: '100px', md: '150px' },
                                            }}
                                        >
                                            {getOpportunityIcon(company.opportunity)}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                textAlign: 'center',
                                                whiteSpace: { xs: 'nowrap', sm: 'normal' },
                                                fontSize: { xs: '12px', md: '14px' },
                                                minWidth: { xs: '100px', md: '150px' },
                                            }}
                                        >
                                            {company.update}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </>
    )
}
export default TargetCompanies
