import React from 'react'
import { Card, Typography, List, ListItem, ListItemText, Divider } from '@mui/material'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'

const signals = [
    {
        title: 'Expanding Payment Solutions in Europe',
        description:
            "Discuss Klarna's strategic partnership with Lenovo to expand its payment solutions in 15 European countries. Explore how this aligns with Klarna's focus on innovative payment tools and its potential impact on market penetration and customer engagement across Europe.",
    },
    {
        title: 'IPO and Global Market Strategy',
        description:
            "Explore Klarna's plans for an IPO in New York by H1 2025. Discuss how Klarna's positioning as a global player aligns with their regulatory and compliance leadership initiatives, and the potential advantages and challenges of going public in the current market environment.",
    },
    {
        title: 'Sustainable Growth through Partnerships',
        description:
            "Consider Klarna's collaboration with eco-conscious companies like Finnair and how this reflects their commitment to integrating ESG standards. Analyze the potential benefits and challenges associated with expanding their eco-friendly payment solutions and distribution networks.",
    },
]

const ConversationStarters = () => {
    return (
        <Card
            variant="outlined"
            style={{
                borderRadius: '8px',
                height: '280px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {/* Sticky Header */}
            <div
                style={{
                    position: 'sticky',
                    top: 0,
                    background: '#fff',
                    zIndex: 10,
                    padding: '10px',
                }}
            >
                <Typography
                    variant="h6"
                    component="div"
                    style={{
                        fontWeight: 500,
                        color: '#0A0A0A',
                        fontSize: '16px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <ChatBubbleIcon style={{ fontSize: '20px', color: '3B82F6', marginRight: '8px' }} />
                    Conversation Starters
                </Typography>
            </div>

            {/* Scrollable Content */}
            <div
                style={{
                    overflowY: 'auto',
                    height: 'calc(100% - 60px)', // Adjust height based on header
                    padding: '10px',
                }}
            >
                {/* Signals List */}
                <List>
                    {signals.map((signal, index) => (
                        <React.Fragment key={index}>
                            <ListItem
                                sx={{
                                    borderLeft: `4px solid #5C8FBC`,
                                    cursor: 'pointer',
                                    mb: 0.5,
                                    alignItems: 'flex-start',
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                            {signal.title}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                                            {signal.description}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            {index < signals.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </List>
            </div>
        </Card>
    )
}

export default ConversationStarters
