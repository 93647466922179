import React, { useState, useEffect } from 'react'
import { Box, Typography, List, ListItem, ListItemText, Divider, Drawer, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import CloudIcon from '@mui/icons-material/Cloud'
import { SignalsTriggersSkeleton } from '../../../components/Skeleton/index'
const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        padding: '4px 8px',
    },
    iconsContainer: {
        display: 'flex',
        marginRight: '8px',
    },
    icon: {
        width: '20px',
        cursor: 'pointer',
        height: '20px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#fff',
        marginRight: '-4px',
    },
    whiteCircle: {
        backgroundColor: '#ffffff',
        color: '#000',
        border: '1.5px solid #000',
    },
    coloredCircle: {
        backgroundColor: '#00aaff',
        border: '1px solid #ddd',
    },
    text: {
        color: '#555',
        cursor: 'pointer',
    },
    sourceListContainer: {
        maxHeight: '300px',
        overflowY: 'auto',
        border: '1px solid gray',
        borderRadius: '8px',
        padding: '10px',
        backgroundColor: '#fff',
    },
    sourceItem: {
        marginBottom: '8px',
        padding: '5px 0',
    },
}

const SignalsTriggers = ({ signals }) => {
    const [selectedSignal, setSelectedSignal] = useState(null)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [showSources, setShowSources] = useState(false)
    const handleListItemClick = signal => {
        setSelectedSignal(signal)
        setDrawerOpen(true)
    }
    const [showSkeleton, setShowSkeleton] = useState(true)

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSkeleton(false)
        }, 1000)

        return () => clearTimeout(timer)
    }, [])
    const handleCloseDrawer = () => {
        setDrawerOpen(false)
    }
    const toggleSources = () => {
        setShowSources(!showSources)
    }

    return (
        <>
            {showSkeleton ? (
                <SignalsTriggersSkeleton />
            ) : (
                <>
                    <Box
                        sx={{
                            p: 2,
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            backgroundColor: '#fff',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid #e0e0e0',
                                pb: 1,
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{
                                        fontWeight: 600,
                                        mb: 2,
                                        color: '#6A6A6A',
                                        fontSize: { xs: '16px', sm: '18px' },
                                    }}
                                >
                                    Signals/Triggers
                                </Typography>
                            </div>
                        </div>
                        <List
                            sx={{
                                height: '881px',
                                overflowY: 'auto',
                                mt: 2,
                                p: 0,
                            }}
                        >
                            {signals.map((signal, index) => (
                                <React.Fragment key={index}>
                                    <ListItem
                                        sx={{
                                            borderLeft: '4px solid rgb(25, 118, 210)',
                                            cursor: 'pointer',
                                            mb: 1,
                                            flexDirection: { xs: 'column', sm: 'row' },
                                            alignItems: { xs: 'flex-start', sm: 'center' },
                                            p: 1,
                                            gap: { xs: 1, sm: 2 },
                                        }}
                                        onClick={() => handleListItemClick(signal)}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: { xs: '14px', sm: '16px' },
                                                    }}
                                                >
                                                    {signal.title}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    sx={{
                                                        fontSize: { xs: '12px', sm: '14px' },
                                                    }}
                                                >
                                                    {signal.practiceArea} - {signal.date}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </List>
                        <Drawer
                            anchor="right"
                            open={drawerOpen}
                            onClose={handleCloseDrawer}
                            sx={{
                                '& .MuiDrawer-paper': {
                                    width: { xs: '100%', sm: '75%', md: '600px' },
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    p: 2,
                                    backgroundColor: '#ffff',
                                }}
                            >
                                <Box
                                    sx={{
                                        borderBottom: '1px solid #e0e0e0',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        p: { xs: 1, sm: 1 },
                                    }}
                                >
                                    <IconButton
                                        onClick={handleCloseDrawer}
                                        sx={{
                                            color: '#555',
                                        }}
                                    >
                                        <ArrowForwardIosIcon />
                                    </IconButton>

                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{
                                            fontWeight: 600,
                                            color: '#6A6A6A',

                                            flex: 1,
                                            textAlign: 'center',
                                        }}
                                    >
                                        Signals/Triggers
                                    </Typography>
                                </Box>

                                {selectedSignal && (
                                    <>
                                        <Typography
                                            variant="h5"
                                            sx={{ mb: 1, color: '#333', fontWeight: 'bold', marginTop: '10px' }}
                                        >
                                            {selectedSignal.title}
                                        </Typography>
                                        <Typography variant="body1" sx={{ mb: 1, color: '#555' }}>
                                            <b>Practice Area:</b> {selectedSignal.practiceArea}
                                        </Typography>
                                        <Typography variant="body1" sx={{ mb: 1, color: '#555' }}>
                                            <b>Date:</b> {selectedSignal.date}
                                        </Typography>
                                        <Typography variant="body1" sx={{ mb: 2, color: '#555' }}>
                                            <b>Description:</b> {selectedSignal.description}
                                        </Typography>
                                        <Typography variant="body1" sx={{ mb: 2, color: '#555' }}>
                                            <b>Opportunities:</b> {selectedSignal.opportunities}
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Typography variant="body1" sx={{ mb: 1, color: '#555' }}>
                                                <b>Sources:</b>
                                            </Typography>
                                            <div style={styles.container} onClick={toggleSources}>
                                                <div style={styles.iconsContainer}>
                                                    {selectedSignal.sources.slice(0, 3).map((source, index) => (
                                                        <img
                                                            key={index}
                                                            src={`https://www.google.com/s2/favicons?sz=64&domain_url=${source}`}
                                                            alt="favicon"
                                                            style={{
                                                                ...styles.icon,
                                                                ...styles.whiteCircle,
                                                                width: '24px',
                                                                height: '24px',
                                                            }}
                                                        />
                                                    ))}
                                                    {selectedSignal.sources.length > 3 && (
                                                        <div style={{ ...styles.icon, ...styles.moreSources }}>
                                                            +{selectedSignal.sources.length - 3}
                                                        </div>
                                                    )}
                                                </div>
                                                <span variant="body1" style={styles.text}>
                                                    {selectedSignal.sources.length} Sources
                                                </span>
                                            </div>
                                        </Box>
                                        {showSources && (
                                            <Box style={styles.sourceListContainer}>
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        borderBottom: '1px solid lightgray',
                                                        marginBottom: '8px',
                                                        padding: '5px 0',
                                                        color: '#555',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '8px',
                                                    }}
                                                >
                                                    <b>
                                                        <InsertLinkIcon /> All Sources
                                                    </b>
                                                </Typography>
                                                <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                                                    {selectedSignal.sources.map((source, index) => (
                                                        <li key={index} style={styles.sourceItem}>
                                                            <a
                                                                href={`https://${source}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{
                                                                    textDecoration: 'none',
                                                                    color: '#1976d2',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: '10px',
                                                                }}
                                                            >
                                                                <img
                                                                    src={`https://www.google.com/s2/favicons?sz=64&domain_url=${source}`}
                                                                    alt="favicon"
                                                                    style={{ width: '16px', height: '16px' }}
                                                                />
                                                                <span
                                                                    style={{
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                    }}
                                                                >
                                                                    {source}
                                                                </span>
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Box>
                                        )}
                                        {selectedSignal.potentialContact && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: { xs: 'column', sm: 'row' },
                                                    alignItems: { xs: 'flex-start', sm: 'center' },
                                                    mb: 2,
                                                    gap: '16px',
                                                }}
                                            >
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#555' }}>
                                                    Potential Contact:
                                                </Typography>
                                                <Typography variant="body1" sx={{ color: '#333' }}>
                                                    {selectedSignal.potentialContact.name}
                                                </Typography>
                                                <a
                                                    href="https://www.linkedin.com"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ color: '#0A66C2' }}
                                                >
                                                    <LinkedInIcon fontSize="large" />
                                                </a>
                                                <a
                                                    href="https://www.salesforce.com"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ color: '#00A1E0' }}
                                                >
                                                    <CloudIcon fontSize="large" />
                                                </a>
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Box>
                        </Drawer>
                    </Box>
                </>
            )}
        </>
    )
}

export default SignalsTriggers
