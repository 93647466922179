import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import './BaseScreen.css'
import Sidebar from '../../components/Sidebar/Sidebar'
import Header from '../../components/Header/Header'
import { Circles } from 'react-loader-spinner'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

class BaseScreen extends PureComponent {
    state = { isVisible: false, currectPath: '' }
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    handleScroll = () => {
        if (window.scrollY > 100) {
            this.setState({ isVisible: true })
        } else {
            this.setState({ isVisible: false })
        }
    }

    componentDidUpdate(pre) {
        window.addEventListener('scroll', this.handleScroll)
        return () => {
            window.removeEventListener('scroll', this.handleScroll)
        }
    }

    componentDidMount() {
        this.setState({ currectPath: window.location.pathname })

        if (process.env.NODE_ENV === 'production') {
            // Call this whenever information about your visitors becomes available
            // Please use Strings, Numbers, or Bools for value types.
            const pendo = window.pendo
            pendo.initialize({
                visitor: {
                    id: this.props.user.id, // Required if user is logged in
                    email: this.props.user.email, // Recommended if using Pendo Feedback, or NPS Email
                    full_name: this.props.user.first_name + ' ' + this.props.user.last_name, // Recommended if using Pendo Feedback
                    role: this.props.user.type,
                    // role:         // Optional
                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                },

                account: {
                    id: 'ACCOUNT-UNIQUE-ID', // Highly recommended
                    // name:         // Optional
                    // is_paying:    // Recommended if using Pendo Feedback
                    // monthly_value:// Recommended if using Pendo Feedback
                    // planLevel:    // Optional
                    // planPrice:    // Optional
                    // creationDate: // Optional

                    // You can add any additional account level key-values here,
                    // as long as it's not one of the above reserved names.
                },
            })
        }
    }

    render() {
        const { isLoading, user } = this.props

        return (
            <div
                style={{
                    marginTop:
                        this.state.currectPath &&
                        (this.state.currectPath.includes('assistant') ||
                            this.state.currectPath.includes('chat') ||
                            this.state.currectPath.includes('opportunities') ||
                            this.state.currectPath.includes('sectors') ||
                            this.state.currectPath.includes('/admin/companies'))
                            ? '-20px'
                            : '55px',
                }}
            >
                {/* {this.state.currectPath !== '/app/assistant/' && */}
                <div className="">
                    <Header {...this.props} currectPath={this.state.currectPath} />
                </div>

                {(user.user_type === 'analyst' ||
                    user.user_type === 'client_analyst' ||
                    (user.user_type === 'Power User' ||
                        (user.user_type === 'Regular User' && user.subscription_type)) !== 'Direct') && (
                    <div className="d-flex">
                        <div className="d-none d-lg-flex d-xl-flex">
                            <Sidebar />
                        </div>
                        <div
                            className={
                                user.user_type === 'Regular User' ||
                                user.user_type === 'analyst' ||
                                user.user_type === 'client_analyst' ||
                                user.user_type === 'Power User'
                                    ? 'content-wrap-enduser'
                                    : 'content-wrap'
                            }
                        >
                            {isLoading ? (
                                <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
                            ) : (
                                <div>{this.props.children}</div>
                            )}
                        </div>
                    </div>
                )}

                {(user.user_type === 'Regular User' && user.subscription_type) === 'Direct' && (
                    <div>{this.props.children}</div>
                )}
                {this.state.isVisible && (
                    <ExpandMoreIcon
                        style={{
                            width: '37px',
                            height: '35px',
                            color: 'white',
                            transform: 'rotate(180deg)',
                            borderRadius: '50%',
                            border: '1px solid white',
                            zIndex: '1',
                        }}
                        className="scroll-to-top-button"
                        onClick={this.scrollToTop}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    }
}

export default connect(mapStateToProps)(BaseScreen)
