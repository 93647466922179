import React, { useState, useEffect, useRef } from 'react'
import { Switch, Button, Typography, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/ArrowForward'
import BusinessIcon from '@mui/icons-material/Business'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import SendIcon from '../../../assets/icons/send.svg'
export default function AskQuestion({ onSearch, isWebSearchEnabled, setIsWebSearchEnabled }) {
    const [searchQuery, setSearchQuery] = useState('')
    const inputRef = useRef(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    const handleSearch = () => {
        if (searchQuery.trim()) {
            onSearch({
                query: searchQuery,
                search: isWebSearchEnabled,
            })
        } else {
            console.warn('Search query is empty')
        }
    }

    const handleKeyDown = e => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            handleSearch()
        }
    }

    // Handle clicking on suggestions
    const handleSuggestionClick = query => {
        setSearchQuery(query)
        handleSearch()
    }

    const suggestions = [
        {
            label: 'What companies in the cleantech sector are likely to face bankruptcy challenges?',
            icon: <BusinessIcon />,
            query: 'What companies in the cleantech sector are likely to face bankruptcy challenges?',
            padding: '0.5rem 1rem',
        },
        {
            label: 'What are key labor issues faced by Amazon in the last 6 months?',
            icon: <TrendingUpIcon />,
            query: 'What are key labor issues faced by Amazon in the last 6 months?',
            padding: '0.75rem 1.25rem',
        },
    ]

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#fff',
                padding: '1rem',
                marginTop: '-20px',
            }}
        >
            <Typography variant="h3" gutterBottom style={{ fontWeight: 750, textAlign: 'center', fontSize: '2rem' }}>
                Build Your Business With AI
            </Typography>
            <Typography
                variant="div"
                style={{
                    color: '#555',
                    fontWeight: 600,
                    fontSize: '1.2rem',
                    marginBottom: '0.5rem',
                    textAlign: 'center',
                    maxWidth: '90%',
                }}
            >
                Ask questions about your customers, sectors or competitors
            </Typography>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    maxWidth: '700px',
                    borderRadius: '5px',
                    backgroundColor: '#fff',
                    border: '1px solid #ddd',
                    padding: '1rem',
                    boxSizing: 'border-box',
                }}
            >
                <TextField
                    inputRef={inputRef}
                    placeholder="Ask anything..."
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                    multiline
                    rows={4}
                    variant="standard"
                    fullWidth
                    InputProps={{
                        disableUnderline: true,
                        style: {
                            fontSize: '1rem',
                            color: '#333',
                            padding: '0.5rem',
                            backgroundColor: 'transparent',
                        },
                    }}
                />

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                        marginLeft: '1rem',
                        flexShrink: 0,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                        }}
                    >
                        <Switch
                            checked={isWebSearchEnabled}
                            onChange={e => setIsWebSearchEnabled(e.target.checked)}
                            size="small"
                        />
                        <span style={{ fontSize: '0.875rem', color: '#555' }}>Web search</span>
                    </div>
                    <Button
                        onClick={handleSearch}
                        style={{
                            minWidth: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            backgroundColor: '#007aff',
                            color: '#ffffff',
                            padding: '0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <SearchIcon />
                    </Button>
                </div>
            </div>

            <div
                style={{
                    marginTop: '1.25rem',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '1rem',
                    justifyContent: 'center',
                    width: '100%',
                    maxWidth: '700px',
                }}
            >
                {suggestions.map((suggestion, index) => (
                    <div
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion.query)}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            padding: suggestion.padding,
                            backgroundColor: '#fff',
                            borderRadius: '25px',
                            border: '1px solid #ddd',
                            gap: '0.5rem',
                            flex: '0 1 auto',
                            maxWidth: '100%',
                        }}
                    >
                        {suggestion.icon}
                        <Typography
                            variant="body1"
                            style={{ fontSize: '0.9rem', lineHeight: '1.2', textAlign: 'left' }}
                        >
                            {suggestion.label}
                        </Typography>
                    </div>
                ))}
            </div>
        </div>
    )
}
