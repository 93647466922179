import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import {
    Container,
    Box,
    Typography,
    Grid,
    Card,
    Avatar,
    Skeleton,
    List,
    ListItem,
    Button,
    IconButton,
    Paper,
    TextField,
    Switch,
    CircularProgress,
} from '@mui/material'
import './HtmlContent.css'
import styles from './Chatbotanimations.module.css'
import AskQuestion from './AskQuestion'
import AnimatedStepper from './AnimatedStepper'
import { Circles } from 'react-loader-spinner'
import Navbar from './Navbar'
import QueueIcon from '@mui/icons-material/Queue'
import { styled } from '@mui/material/styles'
import store from '../../../Store'
import BarChartIcon from '@mui/icons-material/BarChart'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import AddIcon from '@mui/icons-material/Add'
import answerIcon from '../../../assets/icons/answer.svg'
import sourcesIcon from '../../../assets/icons/sources.svg'
import relatedqueicon from '../../../assets/icons/relatedqueicon.svg'
import researchStatusIcon from '../../../assets/icons/researchStatusIcon.svg'
import SendIcon from '../../../assets/icons/send.svg'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import DynamicFormIcon from '@mui/icons-material/DynamicForm'
import Share from './Share'

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 15,
        },
    })
)

export default function Search() {
    const location = useLocation()
    const history = useHistory()
    const [streamingSummary, setStreamingSummary] = useState('')
    const [connectionStatus, setConnectionStatus] = useState('Disconnected')
    const [researchSteps, setResearchSteps] = useState({})

    const [isResearchInProgress, setIsResearchInProgress] = useState(false)
    const mainContentRef = useRef(null)
    const [socket, setSocket] = useState(null)
    const [messages, setMessages] = useState([])
    const [input, setInput] = useState('')
    const [isWebSearchEnabled, setIsWebSearchEnabled] = useState(true)
    const [researchStatus, setResearchStatus] = useState('')
    const [relatedQuestions, setRelatedQuestions] = useState([])
    const [historyData, setHistoryData] = useState([])
    const [sources, setSources] = useState([])
    const [showAskQuestion, setShowAskQuestion] = useState(true)
    const [showAllSources, setShowAllSources] = useState(false)
    const [pendingQuery, setPendingQuery] = useState('')
    const [isNewQuestion, setIsNewQuestion] = useState(false)
    const [hasStartedStreaming, setHasStartedStreaming] = useState(false)

    const [userId, setUserId] = useState()
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const searchQuery = queryParams.get('query') || ''
        const webSearch = queryParams.get('isWebSearchEnabled') === 'true'

        if (searchQuery) {
            setInput(searchQuery)
            setShowAskQuestion(false)
            setIsWebSearchEnabled(webSearch)
            setPendingQuery(searchQuery)
            setIsNewQuestion(true)
        } else {
            setInput('')
            setShowAskQuestion(true)
            setStreamingSummary('')
            setMessages([])
            setSources([])
            setRelatedQuestions([])
            setResearchSteps([])
            setHistoryData([])
            setResearchSteps({})
            setIsResearchInProgress(false)
            history.replace('/app/chat/')
        }
    }, [location.search, history])
    useEffect(() => {
        const id = store.getState().auth.user.id
        setUserId(id)
    }, [store.getState().auth.user.id])
    useEffect(() => {
        const currentDomain = window.location.hostname
        let wsUrl

        if (currentDomain === 'insights.kaitongo.com') {
            wsUrl = 'wss://insights.kaitongo.com/ws/chat/'
        } else if (currentDomain === 'dev.kaitongo.com') {
            wsUrl = 'wss://dev.kaitongo.com/ws/chat/'
        } else if (currentDomain === '5.161.69.136') {
            wsUrl = 'ws://5.161.69.136:30053/ws/chat/'
        } else if (currentDomain === 'localhost') {
            wsUrl = 'wss://dev.kaitongo.com/ws/chat/'
            // wsUrl = 'ws://localhost:8001/ws/chat/'
        } else if (currentDomain === 'hetzner-frontend.kaitongo.com') {
            wsUrl = 'wss://hetzner-frontend.kaitongo.com/ws/chat/'
        } else {
            console.error('Unrecognized domain:', currentDomain)
            return
        }

        // if (currentDomain === 'insights.kaitongo.com') {
        //     wsUrl = 'wss://insights.kaitongo.com/ws/chat/'
        // } else if (
        //     currentDomain === 'dev.kaitongo.com' ||
        //     currentDomain === 'localhost' ||
        //     currentDomain === 'hetzner-frontend.kaitongo.com'
        // ) {
        //     wsUrl = 'wss://hetzner-frontend.kaitongo.com/ws/chat/'
        // } else {
        //     console.error('Unrecognized domain:', currentDomain)
        //     return
        // }
        console.log('wsUrl', wsUrl)
        // Initialize WebSocket connection
        const socketConnection = new WebSocket(wsUrl)
        setConnectionStatus('Connecting')
        socketConnection.onopen = () => {
            setConnectionStatus('Connected')
            console.log('WebSocket connected')
        }

        socketConnection.onclose = () => {
            setConnectionStatus('Disconnected')
            console.log('WebSocket disconnected')
        }

        socketConnection.onerror = error => {
            console.error('WebSocket error:', error)
        }

        socketConnection.onmessage = e => {
            let data
            try {
                data = JSON.parse(e.data)
            } catch (err) {
                console.error('Invalid JSON from server:', e.data)
                return
            }

            const { event, ...payload } = data
            console.log('data', data)
            console.log('payload', payload)
            switch (event) {
                case 'connection':
                    console.log('Server connection event received')
                    break
                case 'streaming_response':
                    setHistoryData(prevHistory => {
                        if (prevHistory.length === 0) {
                            console.warn('No history available to update streamingSummary')
                            return prevHistory
                        }
                        const newHistory = [...prevHistory]
                        const lastChat = { ...newHistory[newHistory.length - 1] }

                        const wasEmpty = lastChat.streamingSummary.length === 0
                        // Extract text from payload.data.data
                        const text = payload.data && payload.data.data ? payload.data.data : ''
                        lastChat.streamingSummary += text
                        newHistory[newHistory.length - 1] = lastChat

                        if (wasEmpty && text.trim() !== '') {
                            setHasStartedStreaming(true)
                        }
                        return newHistory
                    })
                    break
                case 'research_steps':
                    console.log('Research Steps:', payload.data)
                    setResearchSteps(prevSteps => {
                        const updatedSteps = { ...prevSteps }
                        for (const category in payload.data) {
                            if (updatedSteps[category]) {
                                updatedSteps[category] = [...updatedSteps[category], ...payload.data[category]]
                            } else {
                                updatedSteps[category] = [...payload.data[category]]
                            }
                        }
                        return updatedSteps
                    })
                    break

                case 'research_status':
                    setHistoryData(prevHistory => {
                        if (prevHistory.length === 0) return prevHistory
                        const newHistory = [...prevHistory]
                        const lastChat = { ...newHistory[newHistory.length - 1] }

                        const status = payload.data && payload.data.status ? payload.data.status : ''

                        // Only set the researchStatus if it's not "Start" or "Completed"
                        if (status !== 'Start') {
                            lastChat.researchStatus = status
                            newHistory[newHistory.length - 1] = lastChat
                        }

                        return newHistory
                    })

                    const status = payload.data && payload.data.status ? payload.data.status : ''
                    if (status === 'Start') {
                        setIsResearchInProgress(true)
                    } else if (status === 'Completed') {
                        setIsResearchInProgress(false)
                        setInput('')
                    }
                    break

                case 'research_sources':
                    setHistoryData(prevHistory => {
                        if (prevHistory.length === 0) return prevHistory
                        const newHistory = [...prevHistory]
                        const lastChat = { ...newHistory[newHistory.length - 1] }

                        // sources should come from payload.data.sources
                        const sources = payload.data && Array.isArray(payload.data.sources) ? payload.data.sources : []

                        lastChat.sources = [...lastChat.sources, ...sources]
                        newHistory[newHistory.length - 1] = lastChat
                        return newHistory
                    })
                    break

                case 'relevant_questions':
                    console.log('Relevant Questions:', payload)
                    const questions = payload.data && Array.isArray(payload.data.data) ? payload.data.data : []
                    setRelatedQuestions(questions)
                    break

                default:
                    console.log('Unknown event:', event, payload)
            }
        }

        setSocket(socketConnection)

        return () => {
            if (socketConnection && socketConnection.readyState === WebSocket.OPEN) {
                socketConnection.close()
            }
        }
    }, [])

    useEffect(() => {
        if (connectionStatus === 'Connected' && pendingQuery && socket && socket.readyState === WebSocket.OPEN) {
            const message = {
                event: 'conduct_research',
                query: pendingQuery,
                description: 'Detailed search',
                search: isWebSearchEnabled,
                user_id: userId,
            }
            console.log('message', message)
            socket.send(JSON.stringify(message))
            console.log('conduct_research:', pendingQuery, 'isWebSearchEnabled', isWebSearchEnabled)

            setHistoryData(prevHistory => [
                ...prevHistory,
                {
                    question: pendingQuery,
                    sources: [],
                    streamingSummary: '',
                    researchStatus: '',
                },
            ])

            setMessages(prevMessages => [
                ...prevMessages,
                {
                    id: prevMessages.length + 1,
                    text: pendingQuery,
                    sender: 'user',
                    timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                },
            ])

            setPendingQuery('')
        }
    }, [connectionStatus, pendingQuery, socket, isWebSearchEnabled])

    const handleQuestionClick = question => {
        history.replace(`/app/chat/?query=${encodeURIComponent(question)}`)
        setStreamingSummary('')
        setSources([])
        setMessages([])
        setRelatedQuestions([])
        setResearchSteps({})
        setIsNewQuestion(true)
        setHasStartedStreaming(false)
    }

    const handleUserQuery = (query, search = isWebSearchEnabled) => {
        if (!query.trim()) {
            console.error('Invalid query')
            return
        }
        const queryParams = new URLSearchParams()
        queryParams.set('query', query)
        queryParams.set('isWebSearchEnabled', search)
        history.replace(`/app/chat/?${queryParams.toString()}`)
        setShowAskQuestion(false)
        setStreamingSummary('')
        setSources([])
        setMessages([])
        setRelatedQuestions([])
        setResearchSteps({})
        setIsResearchInProgress(false)
        setHasStartedStreaming(false)
        setIsNewQuestion(true)

        setPendingQuery(query)
        setInput('')
    }

    useLayoutEffect(() => {
        if (mainContentRef.current) {
            const container = mainContentRef.current
            container.scrollTop = container.scrollHeight
        }
    }, [historyData])
    if (connectionStatus !== 'Connected') {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
            </Box>
        )
    }
    return (
        <div className={styles.fadeInBody}>
            {/* <div > */}
            {showAskQuestion ? (
                <AskQuestion
                    onSearch={({ query, search }) => handleUserQuery(query, search)}
                    isWebSearchEnabled={isWebSearchEnabled}
                    setIsWebSearchEnabled={setIsWebSearchEnabled}
                />
            ) : (
                <div
                    style={{
                        background: '#fff',
                        fontFamily: 'Inter, sans-serif',
                    }}
                >
                    <Navbar />
                    <div>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%' }}>
                            <Box
                                flex={1}
                                ml={2}
                                mr={1}
                                ref={mainContentRef}
                                sx={{
                                    overflowY: 'auto',
                                    maxHeight: {
                                        xs: '100%',
                                        md: 'calc(100vh - 120px)',
                                        sm: '100%',
                                    },
                                    marginBottom: '10px',
                                    paddingRight: {
                                        xs: 0,
                                        md: '16px',
                                        sm: 0,
                                    },
                                    position: 'relative',
                                    borderRight: {
                                        xs: 'none',
                                        md: '1px solid lightgray',
                                        sm: 'none',
                                    },
                                }}
                            >
                                <Container maxWidth="md">
                                    <Box pt={0} pb={2}>
                                        {historyData.map((chat, index) => (
                                            <Box key={index} mb={0}>
                                                <Typography
                                                    variant="h4"
                                                    gutterBottom
                                                    className="message-animation"
                                                    sx={{
                                                        paddingTop: '10px',
                                                        fontFamily: 'Inter, sans-serif',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 3,
                                                        WebkitBoxOrient: 'vertical',
                                                    }}
                                                >
                                                    {chat.question}
                                                </Typography>

                                                <Box mb={4}>
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        className="message-animation"
                                                    >
                                                        <img
                                                            src={sourcesIcon}
                                                            alt="thought"
                                                            style={{ fontSize: '24px' }}
                                                        />
                                                        <Typography
                                                            variant="h6"
                                                            component="div"
                                                            style={{
                                                                fontWeight: 500,
                                                                color: '#0A0A0A',
                                                                fontSize: '16px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                            ml={1}
                                                        >
                                                            Sources
                                                        </Typography>
                                                    </Box>
                                                    <Grid container spacing={2} mt={1}>
                                                        {chat.sources.length > 0
                                                            ? chat.sources
                                                                  .slice(0, showAllSources ? chat.sources.length : 4)
                                                                  .map((source, idx) => {
                                                                      const domain = new URL(
                                                                          source.url
                                                                      ).hostname.replace('www.', '')
                                                                      return (
                                                                          <Grid item xs={6} sm={3} md={3} key={idx}>
                                                                              <Card
                                                                                  variant="outlined"
                                                                                  className="source-card-animation"
                                                                                  sx={{
                                                                                      height: '100%',
                                                                                      cursor: 'pointer',
                                                                                      display: 'flex',
                                                                                      flexDirection: 'column',
                                                                                      justifyContent: 'space-between',
                                                                                      padding: '8px',
                                                                                      borderRadius: '8px',
                                                                                      backgroundColor: '#f9f9f9',
                                                                                  }}
                                                                                  onClick={() =>
                                                                                      window.open(source.url, '_blank')
                                                                                  }
                                                                              >
                                                                                  <Typography
                                                                                      variant="body2"
                                                                                      sx={{
                                                                                          fontSize: '13px',
                                                                                          fontWeight: '500',
                                                                                          fontFamily: 'sans-serif',
                                                                                          display: '-webkit-box',
                                                                                          WebkitBoxOrient: 'vertical',
                                                                                          WebkitLineClamp: 2,
                                                                                          overflow: 'hidden',
                                                                                      }}
                                                                                  >
                                                                                      {source.title}
                                                                                  </Typography>
                                                                                  <Box
                                                                                      display="flex"
                                                                                      alignItems="center"
                                                                                      sx={{ marginTop: '3px' }}
                                                                                  >
                                                                                      <Avatar
                                                                                          src={`https://www.google.com/s2/favicons?sz=64&domain_url=${domain}`}
                                                                                          alt="favicon"
                                                                                          sx={{
                                                                                              width: 22,
                                                                                              height: 22,
                                                                                              marginRight: 1,
                                                                                          }}
                                                                                      />
                                                                                      <Typography
                                                                                          variant="body2"
                                                                                          sx={{
                                                                                              fontWeight: 'bold',
                                                                                              fontSize: '11px',
                                                                                              textOverflow: 'ellipsis',
                                                                                              overflow: 'hidden',
                                                                                              whiteSpace: 'nowrap',
                                                                                          }}
                                                                                      >
                                                                                          {domain}
                                                                                      </Typography>
                                                                                  </Box>
                                                                              </Card>
                                                                          </Grid>
                                                                      )
                                                                  })
                                                            : [...Array(4)].map((_, idx) => (
                                                                  <Grid item xs={6} sm={3} md={3} key={idx}>
                                                                      <Skeleton
                                                                          variant="rectangular"
                                                                          width="100%"
                                                                          height={118}
                                                                      />
                                                                  </Grid>
                                                              ))}
                                                    </Grid>
                                                    {chat.sources.length > 4 && !showAllSources && (
                                                        <Box mt={2}>
                                                            <Button
                                                                variant="outlined"
                                                                onClick={() => setShowAllSources(true)}
                                                                fullWidth
                                                                className="button-hover"
                                                            >
                                                                Show All
                                                            </Button>
                                                        </Box>
                                                    )}
                                                </Box>

                                                <Box mb={4}>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        className="message-animation"
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <img
                                                                src={answerIcon}
                                                                alt="thought"
                                                                style={{ fontSize: '24px' }}
                                                            />
                                                            <Typography
                                                                variant="h6"
                                                                component="div"
                                                                style={{
                                                                    fontWeight: 500,
                                                                    color: '#0A0A0A',
                                                                    fontSize: '16px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                                ml={1}
                                                            >
                                                                Answer
                                                            </Typography>
                                                        </div>

                                                        <Share />
                                                    </Box>
                                                    <Box mt={2} className="answer-animation">
                                                        {chat.streamingSummary ? (
                                                            <Typography variant="body1">
                                                                <div
                                                                    className="html-content"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: chat.streamingSummary,
                                                                    }}
                                                                />
                                                            </Typography>
                                                        ) : (
                                                            <Skeleton variant="rectangular" width="100%" height={80} />
                                                        )}
                                                    </Box>
                                                </Box>
                                                {chat.researchStatus !== 'Completed' && (
                                                    <Box mb={0}>
                                                        <Box
                                                            display="flex"
                                                            alignItems="center"
                                                            className="message-animation"
                                                        >
                                                            <img
                                                                src={researchStatusIcon}
                                                                alt="thought"
                                                                style={{ fontSize: '24px' }}
                                                            />
                                                            <Typography
                                                                variant="h6"
                                                                component="div"
                                                                style={{
                                                                    fontWeight: 500,
                                                                    color: '#0A0A0A',
                                                                    fontSize: '16px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                                ml={1}
                                                            >
                                                                Research Status
                                                            </Typography>
                                                        </Box>
                                                        <Box mt={2} className="answer-animation">
                                                            {chat.researchStatus ? (
                                                                <Typography
                                                                    variant="body1"
                                                                    sx={{
                                                                        fontFamily: 'Inter, sans-serif',
                                                                    }}
                                                                >
                                                                    {chat.researchStatus}
                                                                </Typography>
                                                            ) : (
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    width="100%"
                                                                    height={10}
                                                                />
                                                            )}
                                                        </Box>
                                                    </Box>
                                                )}

                                                {chat.researchStatus === 'Completed' && (
                                                    <Box mt={4} mb={2}>
                                                        <Box
                                                            display="flex"
                                                            alignItems="center"
                                                            className="message-animation"
                                                        >
                                                            <Share />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                </Container>
                            </Box>

                            <Box
                                mb={4}
                                sx={{
                                    width: {
                                        xs: 'auto',
                                        md: 350,
                                        sm: 'auto',
                                    },
                                }}
                                ml={2}
                                mr={1}
                            >
                                <Container maxWidth="md">
                                    {relatedQuestions.length > 0 && (
                                        <>
                                            {/* “Related Questions” Header */}
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                className="message-animation"
                                                style={{
                                                    paddingTop: '10px',
                                                    paddingRight: '16px',

                                                    position: 'relative',
                                                }}
                                            >
                                                <img src={relatedqueicon} alt="thought" style={{ fontSize: '24px' }} />
                                                <Typography
                                                    variant="h6"
                                                    component="div"
                                                    style={{
                                                        fontWeight: 500,
                                                        color: '#0A0A0A',
                                                        fontSize: '16px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                    ml={1}
                                                >
                                                    Related Questions
                                                </Typography>
                                            </Box>

                                            {/* “Related Questions” List */}
                                            <Box mt={0} sx={{ height: '300px', overflowY: 'auto' }}>
                                                <List>
                                                    {relatedQuestions.map((question, index) => (
                                                        <ListItem
                                                            key={index}
                                                            button
                                                            onClick={() => handleQuestionClick(question)}
                                                            className="question-animation"
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                fontFamily: 'Inter, sans-serif',
                                                                padding: '5px 0',
                                                                maxWidth: '100%',
                                                                borderBottom: '0.5px solid #e8e8e8',
                                                            }}
                                                        >
                                                            <IconButton size="small" sx={{ marginRight: 1 }}>
                                                                <AddIcon />
                                                            </IconButton>
                                                            <LightTooltip title={question} placement="left-start">
                                                                <Typography
                                                                    variant="body2"
                                                                    sx={{
                                                                        fontWeight: 500,
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        display: '-webkit-box',
                                                                        WebkitLineClamp: 2,
                                                                        fontFamily: 'Inter, sans-serif',
                                                                        WebkitBoxOrient: 'vertical',
                                                                        maxWidth: 'calc(100% - 40px)',
                                                                    }}
                                                                >
                                                                    {question}
                                                                </Typography>
                                                            </LightTooltip>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Box>
                                        </>
                                    )}

                                    <Box
                                        mt={1}
                                        sx={{
                                            height: '200px',
                                            overflowY: 'auto',
                                            marginTop: '10px',
                                            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
                                        }}
                                    >
                                        <AnimatedStepper researchSteps={researchSteps} />
                                    </Box>
                                </Container>
                            </Box>
                        </Box>
                        <Paper
                            elevation={1}
                            sx={{
                                position: 'fixed',
                                bottom: '16px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                width: {
                                    xs: '90%',
                                    sm: '80%',
                                    md: '70%',
                                    lg: '60%',
                                },
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: {
                                    xs: '6px 10px',
                                    sm: '8px 12px',
                                },
                                borderRadius: '24px',
                                border: '1px solid #e0e0e0',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            }}
                            className="message-animation"
                        >
                            <TextField
                                variant="standard"
                                fullWidth
                                placeholder="What do you want to know?"
                                value={input}
                                onChange={e => setInput(e.target.value)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        handleUserQuery(input)
                                    }
                                }}
                                disabled={isResearchInProgress}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                sx={{
                                    mx: 2,
                                    '& .MuiInputBase-input': {
                                        padding: 0,
                                        fontSize: {
                                            xs: '14px',
                                            sm: '16px',
                                        },
                                        color: '#333',
                                    },
                                }}
                            />

                            {isResearchInProgress ? (
                                <CircularProgress size={24} />
                            ) : (
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Switch
                                        size="small"
                                        checked={isWebSearchEnabled}
                                        onChange={() => setIsWebSearchEnabled(!isWebSearchEnabled)}
                                    />
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: 500,
                                            color: '#555',
                                            fontSize: {
                                                xs: '12px',
                                                sm: '14px',
                                            },
                                        }}
                                    >
                                        Web search
                                    </Typography>
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleUserQuery(input)}
                                        className="button-hover"
                                    >
                                        <img src={SendIcon} alt="thought" style={{ fontSize: '24px' }} />
                                    </IconButton>
                                </Box>
                            )}
                        </Paper>
                    </div>
                </div>
            )}
        </div>
    )
}
