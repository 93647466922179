import React, { useState } from 'react'
import {
    Grid,
    Container,
    Box,
    Typography,
    TextField,
    InputAdornment,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material'
import About from './components/About'
import Opportunities from './components/Opportunities'
import Guagechart from './components/GaugeChart'
import Contacts from './components/Contacts'
import BaseScreen from '../BaseScreen/BaseScreen'
import NewsKeyUpdates from './components/NewsKeyUpdates'
import ChatbotModal from '../Chatbot/components/ChatbotModal'
import SearchIcon from '@mui/icons-material/Search'
import ConversationStarters from './components/ConversationStarters'

const companiesTemplate = () => {
    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <BaseScreen>
            <Box sx={{ background: 'white', pb: 4, marginTop: '-19px' }}>
                {/* Header Section */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: { xs: 'center', md: 'space-between' },
                        alignItems: 'center',
                        flexDirection: { xs: 'column', md: 'row' },
                        borderBottom: '1px solid #ddd',
                        padding: '16px',
                        gap: { xs: 2, md: 0 },
                    }}
                >
                    {/* Title and Subtitle */}
                    <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                        <Typography variant="h5" sx={{ fontWeight: 600, color: '#6A6A6A' }}>
                            Company Dashboard
                        </Typography>
                    </Box>

                    {/* Search Bar */}
                    <Box sx={{ width: { xs: '100%', md: '600px' } }}>
                        <TextField
                            variant="outlined"
                            placeholder="Ask a question"
                            size="small"
                            onClick={handleOpen}
                            sx={{
                                width: '100%',
                                backgroundColor: '#f9f9f9',
                                borderRadius: '20px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '20px',
                                    padding: '4px 12px',
                                    backgroundColor: '#f9f9f9',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#e0e0e0',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#e0e0e0',
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                        {/* Embed the SVG directly */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            stroke="url(#:r1k:)"
                                            stroke-width="2"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="flex size-4 shrink-0"
                                        >
                                            <defs>
                                                <linearGradient id=":r1k:" x1="0%" y1="0%" x2="100%" y2="0%">
                                                    <stop offset="0%" style={{ stopColor: 'rgb(14, 165, 233)' }} />
                                                    <stop offset="50%" style={{ stopColor: 'rgb(99, 102, 241)' }} />
                                                    <stop offset="100%" style={{ stopColor: 'rgb(168, 85, 247)' }} />
                                                </linearGradient>
                                            </defs>
                                            <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
                                            <path d="M5 3v4" stroke="#0ea5e9" />
                                            <path d="M19 17v4" stroke="#a855f7" />
                                            <path d="M3 5h4" stroke="#6366f1" />
                                            <path d="M17 19h4" stroke="#6366f1" />
                                        </svg>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    {/* Dropdown Filters */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: { xs: 'wrap', md: 'nowrap' },
                            gap: 2,
                            justifyContent: { xs: 'center', md: 'flex-start' },
                        }}
                    >
                        <FormControl size="small" variant="outlined" sx={{ minWidth: 150 }}>
                            <InputLabel>Practice Filter</InputLabel>
                            <Select defaultValue="Corporate/M&A" label="Practice Filter">
                                <MenuItem value="Corporate/M&A">Corporate/M&A</MenuItem>
                                <MenuItem value="Litigation">Litigation</MenuItem>
                                <MenuItem value="Intellectual Property">Intellectual Property</MenuItem>
                                <MenuItem value="Antitrust/Competition">Antitrust/Competition</MenuItem>
                                <MenuItem value="Tax">Tax</MenuItem>
                                <MenuItem value="Real Estate">Real Estate</MenuItem>
                                <MenuItem value="Finance">Finance</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl size="small" variant="outlined" sx={{ minWidth: 150 }}>
                            <InputLabel>Time Period</InputLabel>
                            <Select defaultValue="Last Month" label="Time Period">
                                <MenuItem value="Last Month">Last Month</MenuItem>
                                <MenuItem value="Last 6 Months">Last 6 Months</MenuItem>
                                <MenuItem value="Last Year">Last Year</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                <Box sx={{ mt: 2, px: { xs: 2, md: 4 } }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <About />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Guagechart />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Contacts />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <Opportunities />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={6}>
                            <NewsKeyUpdates />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ConversationStarters />
                        </Grid>
                    </Grid>
                </Box>

                <ChatbotModal open={open} handleClose={handleClose} />
            </Box>
        </BaseScreen>
    )
}

export default companiesTemplate
