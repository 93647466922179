import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import { Box, Typography } from '@mui/material'
// import Share from './Share' // Adjust the path as needed

function Navbar() {
    const [mobileOpen, setMobileOpen] = React.useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(prevState => !prevState)
    }

    return (
        <AppBar
            component="nav"
            position="relative"
            elevation={0}
            sx={{
                width: '100%',
                marginTop: '-19px',
                backgroundColor: '#fff',
                borderBottom: '1px solid lightgray',
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ color: '#0A0A0A', flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                />
                {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Share />
                </Box> */}
            </Toolbar>
        </AppBar>
    )
}

export default Navbar
