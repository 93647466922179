import React, { useState, useEffect } from 'react'
import { Box, Stepper, Step, StepLabel, Paper, Fade, Typography } from '@mui/material'
import './AnimatedStepper.css'

const AnimatedStepper = ({ researchSteps }) => {
    // researchSteps is an object where keys are categories and values are arrays of steps
    const categories = Object.keys(researchSteps)

    return (
        <Box sx={{ width: '100%', fontFamily: 'Inter, sans-serif' }}>
            {categories.length === 0 ? (
                <Typography variant="body2"></Typography>
            ) : (
                categories.map((category, idx) => (
                    <CategoryStepper key={idx} category={category} steps={researchSteps[category]} />
                ))
            )}
        </Box>
    )
}

const CategoryStepper = ({ category, steps }) => {
    const [activeStep, setActiveStep] = useState(0)

    useEffect(() => {
        setActiveStep(0) // Reset when steps change
    }, [steps])

    useEffect(() => {
        if (!steps || activeStep >= steps.flatMap(group => group.data).length) return

        const stepTimeout = setTimeout(() => {
            setActiveStep(prevStep => prevStep + 1)
        }, 2000) // Adjust delay for each step

        return () => clearTimeout(stepTimeout)
    }, [activeStep, steps.length])

    // Format category name to be more readable (e.g., topic_search => Topic Search)
    const formattedCategory = category
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

    return (
        <Box sx={{ marginBottom: 4 }}>
            <Typography
                variant="h6"
                component="div"
                style={{
                    fontWeight: 500,
                    color: '#0A0A0A',
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                }}
                ml={1}
                gutterBottom
            >
                {formattedCategory}
            </Typography>
            <div sx={{ padding: 1 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <Fade in={index <= activeStep} timeout={500}>
                                <StepLabel>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontWeight: 500,

                                            fontFamily: 'Inter, sans-serif',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: label }}
                                    />
                                </StepLabel>
                            </Fade>
                        </Step>
                    ))}
                </Stepper>
            </div>
        </Box>
    )
}

export default AnimatedStepper
