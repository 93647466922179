import React, { useState, useMemo } from 'react'
import BaseScreen from '../BaseScreen/BaseScreen'

import SignalsTriggers from '../Opportunites/components/SignalsTriggers'
import TreemapComponent from '../Opportunites/components/TreemapComponent'
import TargetCompanies from '../Opportunites/components/TargetCompanies'

import ChatbotModal from '../Chatbot/components/ChatbotModal'
import { useLocation } from 'react-router-dom'
import sectorData from './components/data/sectorsData.json'
import moment from 'moment'

import {
    Box,
    Typography,
    TextField,
    InputAdornment,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Container,
    Grid,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export default function OpportunitiesTemplate() {
    const [open, setOpen] = useState(false)
    const [selectedPractice, setSelectedPractice] = useState('All')
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('Last Month')
    const location = useLocation()

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const queryParams = new URLSearchParams(location.search)
    const name = queryParams.get('name')

    const datasets = sectorData
    const signals = (datasets[name] && datasets[name].signals) || []
    const companies = (datasets[name] && datasets[name].companies) || []
    const sectorsData = (datasets[name] && datasets[name].sectorsData) || []

    const practiceAreas = useMemo(() => {
        const areas = new Set(signals.map(signal => signal.practiceArea))
        return ['All', ...Array.from(areas)]
    }, [signals])

    const timeFilteredSignals = useMemo(() => {
        const now = moment()
        return signals.filter(signal => {
            const signalDate = moment(signal.date, 'MMM D, YYYY')
            switch (selectedTimePeriod) {
                case 'Last Month':
                    return now.diff(signalDate, 'months') <= 1
                case 'Last 6 Months':
                    return now.diff(signalDate, 'months') <= 6
                case 'Last Year':
                    return now.diff(signalDate, 'years') <= 1
                default:
                    return true
            }
        })
    }, [signals, selectedTimePeriod])

    const filteredSignals = useMemo(() => {
        return selectedPractice === 'All'
            ? timeFilteredSignals
            : timeFilteredSignals.filter(signal => signal.practiceArea === selectedPractice)
    }, [timeFilteredSignals, selectedPractice])

    return (
        <BaseScreen>
            <Box sx={{ background: 'white', pb: 4, marginTop: '-19px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #ddd',
                        padding: '16px',
                    }}
                >
                    {/* Title and Subtitle */}
                    <Box>
                        <Typography variant="h5" sx={{ fontWeight: 600, color: '#6A6A6A' }}>
                            {`${name} Dashboard`}
                        </Typography>
                    </Box>

                    {/* Search Bar */}
                    <Box>
                        <TextField
                            variant="outlined"
                            placeholder="Ask a question"
                            size="small"
                            onClick={handleOpen}
                            sx={{
                                width: '400px',
                                backgroundColor: '#f9f9f9',
                                borderRadius: '20px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '20px',
                                    padding: '4px 12px',
                                    backgroundColor: '#f9f9f9',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#e0e0e0',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#e0e0e0',
                                },
                                '& .MuiInputAdornment-root': {
                                    marginRight: '8px',
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'center' }}>
                        <FormControl size="small" variant="outlined" sx={{ minWidth: 120, flexGrow: 1 }}>
                            <InputLabel>Practice Filter</InputLabel>
                            <Select
                                value={selectedPractice}
                                label="Practice Filter"
                                onChange={e => setSelectedPractice(e.target.value)}
                            >
                                {practiceAreas.map(area => (
                                    <MenuItem key={area} value={area}>
                                        {area}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl size="small" variant="outlined" sx={{ minWidth: 120, flexGrow: 1 }}>
                            <InputLabel>Time Period</InputLabel>
                            <Select
                                value={selectedTimePeriod}
                                label="Time Period"
                                onChange={e => setSelectedTimePeriod(e.target.value)}
                            >
                                <MenuItem value="Last Month">Last Month</MenuItem>
                                <MenuItem value="Last 6 Months">Last 6 Months</MenuItem>
                                <MenuItem value="Last Year">Last Year</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                {/* Main Content Section */}
                <Container maxWidth="xl" sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Box sx={{ mb: 2 }}>
                                <TreemapComponent data={sectorsData} />
                            </Box>
                            <TargetCompanies companies={companies} />
                        </Grid>
                        <Grid item xs={4}>
                            <SignalsTriggers signals={filteredSignals} />
                        </Grid>
                    </Grid>
                </Container>
                <div>
                    <ChatbotModal open={open} handleClose={handleClose} />
                </div>
            </Box>
        </BaseScreen>
    )
}
